import React from "react";
import Timeline from "../../../../../components/Timeline/Timeline";
import CompanyLogo from "../../../../../Full.logo.white.png";
import { CardContainer, ScrollWindow } from "../../../../../StyledComponents";

export const s = {
  main: {
    padding: '0.5rem',
  },
  reportHead: {
    backgroundColor: "#F4F4F4",
    padding: '5px 15px',
  },

  headerContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    position: 'relative',
  },

  companyLogo: {
    height: 100,
    top: 20,
    position: 'absolute',
    zIndex: 1,
    // left: '-15rem',
    opacity: '0.6',
  },

  reportId: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Courier",
    marginLeft: "auto",
  },

  closeDate: {
    textAlign: "right",
    fontSize: 12,
    fontFamily: "Courier",
    marginTop: 2,
  },

  reportTypeContainer: {
    margin: 0,
    zIndex: 10,
  },

  reportType: {
    fontFamily: "Courier",
    fontWeight: 700,
    fontSize: 18,
    color: "#8eaeb3",
    textTransform: "uppercase",
    margin: 0,
  },

  addressContainer: {
    marginTop: 20,
    maxWidth: 200,
    zIndex: 10,
  },

  addressContent: {
    fontSize: 12,
    fontFamily: "Courier",
    margin: 0,
    position: 'relative',
    zIndex: 10,
  },

  borrowerContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    fontFamily: "Courier",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },

  borrowerCardCaption: {
    fontSize: 14,
    fontFamily: "Courier",
    marginBottom: 5,
    zIndex: 10,
    position: 'relative',
  },

  borrowerName: {
    fontWeight: 700,
    fontFamily: "Courier",
    fontSize: 20,
    color: "#637984",
    margin: 0,
    zIndex: 10,
    position: 'relative',
  },

  reportBody: {
    backgroundColor: "#FFFFFF",
    paddingLeft: 35,
    paddingRight: 35,
    paddingTop: 10,
    paddingBottom: 35,
    fontFamily: "Courier",
  },

  reportSection: {
    borderBottom: "1px solid #F4F4F4",
    paddingTop: 20,
    paddingBottom: 20,
  },

  reportSectionTitle: {
    fontSize: 16,
    fontFamily: "Courier",
    fontWeight: 700,
    color: "#14424C",
    marginBottom: 10,
  },

  reportSectionContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 5,
    gap: 25,
  },

  reportSectionColumn: {
    flexGrow: 1,
  },

  reportRowTitle: {
    fontSize: 12,
    fontFamily: "Courier",
    fontStyle: "oblique",
  },

  reportRowValue: {
    fontSize: 12,
    fontFamily: "Courier",
    fontWeight: 700,
  },

  timeLineContainer: {
    borderLeft: "1px solid #e4e4e4",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 20,
    paddingBottom: 25,
    fontFamily: "Courier",
    position: "relative",
  },

  timeLineTitle: {
    fontSize: 14,
    color: "#3A724F",
    fontFamily: "Courier",
    fontWeight: 700,
    marginBottom: 5,
  },

  timeLineDetail: {
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },

  timeLineDetailValue: {
    fontFamily: "Courier",
    fontWeight: 700,
  },
}

export default function ReportView({ report }) {
  return (
    <CardContainer className="report">
      <ScrollWindow className="verticalOnly">
      <div style={s.main}>
        <div style={s.reportHead}>
          <div style={s.headerContainer}>
            <div style={s.reportTypeContainer}>
              <p style={s.reportType}>{report.outcome}</p>
            </div>
            
            <img
              alt="lancaster report"
              src={CompanyLogo}
              style={s.companyLogo}
            />

            <div>
              <p style={s.closeDate}>
                Closed: {report.dateGenerated}
              </p>
            </div>
          </div>


          <div style={s.addressContainer}>
            <p style={s.addressContent}>
              Address: {report.streetAddress}
            </p>
            <p style={s.addressContent}>{report.City}, {report.StateOrProvince} {report.PostalCode}</p>
          </div>

          <div style={s.borrowerContainer}>
            <div>
              <p style={s.borrowerCardCaption}>Primary Owner</p>
              <p style={s.borrowerName}>{report.Owner1FullName}</p>
            </div>
            {report.Owner2FullName ?
              <div>
                <p style={s.borrowerCardCaption}>Co-Owner</p>
                <p style={s.borrowerName}>{report.Owner2FullName}</p>
              </div>
            :
              <></>
            }
          </div>
        </div>

        <div style={s.reportBody}>
          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>ORIGINAL MORTGAGE</p>
            <div style={s.reportSectionContentContainer}>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Start Date</p>
                  <p style={s.reportRowValue}>{report.originalOriginationDate}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Loan Amount</p>
                  <p style={s.reportRowValue}>{report.originalLoanAmount}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Interest Rate</p>
                  <p style={s.reportRowValue}>{report.originalInterestRate}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Monthly Payments</p>
                  <p style={s.reportRowValue}>{report.originalMonthlyPayment}</p>
                </div>
              </div>

              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>End Date</p>
                  <p style={s.reportRowValue}>{report.originalEndDate}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Interest Owed</p>
                  <p style={s.reportRowValue}>{report.originalInterestDue}</p>
                </div>
              </div>
            </div>
          </div>

          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>AT CLOSING</p>
            <div style={s.reportSectionContentContainer}>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Term Remaining</p>
                  <p style={s.reportRowValue}>{report.remainingTermAtClosing}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Principal Paid</p>
                  <p style={s.reportRowValue}>{report.principalPaidAtClosing}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Principal Remaining</p>
                  <p style={s.reportRowValue}>{report.principalRemainingAtClosing}</p>
                </div>
              </div>

              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}> </p>
                  <p style={s.reportRowValue}> </p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Interest Paid</p>
                  <p style={s.reportRowValue}>{report.interestPaidAtClosing}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Interest Remaining</p>
                  <p style={s.reportRowValue}>{report.interestRemainingAtClosing}</p>
                </div>
              </div>
            </div>
          </div>

          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>NEW MORTGAGE</p>
            <div style={s.reportSectionContentContainer}>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Start Date</p>
                  <p style={s.reportRowValue}>{report.newStartDate}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Loan Amount</p>
                  <p style={s.reportRowValue}>{report.newLoanAmount}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Interest Rate</p>
                  <p style={s.reportRowValue}>{report.newInterestRate}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Monthly Payments</p>
                  <p style={s.reportRowValue}>{report.newMonthlyPayments}</p>
                </div>
              </div>

              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>End Date</p>
                  <p style={s.reportRowValue}>{report.newEndDate}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Interest Owed</p>
                  <p style={s.reportRowValue}>{report.newInterestOwed}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              ...s.reportSection,
            }}
          >
            <p style={s.reportSectionTitle}>FINANCIALS</p>
            <div style={s.reportSectionContentContainer}>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Profit:</p>
                  <p style={s.reportRowValue}>{report.profitAmount}</p>
                </div>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Team Total Profit:</p>
                  <p style={s.reportRowValue}>${report.teamTotalProfit}</p>
                </div>
              </div>

              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Difference</p>
                  <p style={s.reportRowValue}>{report.profitPercent}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div size={"A4"} style={{ ...s.main, padding: 35, paddingTop: 0 }}>
        {/* DISCREPANCIES START */}
        <div style={{ marginBottom: 10 }}>
          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>DISCREPANCIES PRESENT AT CLOSING</p>

            <div style={{ marginTop: 5 }}>
              <p
                style={{
                  ...s.reportSectionTitle,
                  fontSize: 12,
                  marginBottom: 5,
                  textDecoration: "underline",
                }}
              >
                Last appraisal date
              </p>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Original</p>
                  <p style={s.reportRowValue}>Jan 1st 1995</p>
                </div>
              </div>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>New Record</p>
                  <p style={s.reportRowValue}>Jun 31st 2020</p>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 15 }}>
              <p
                style={{
                  ...s.reportSectionTitle,
                  fontSize: 12,
                  marginBottom: 5,
                  textDecoration: "underline",
                }}
              >
                Last appraisal value
              </p>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Original</p>
                  <p style={s.reportRowValue}>$200,000</p>
                </div>
              </div>

              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>New Record</p>
                  <p style={s.reportRowValue}>$220,000</p>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 15 }}>
              <p
                style={{
                  ...s.reportSectionTitle,
                  fontSize: 12,
                  marginBottom: 5,
                  textDecoration: "underline",
                }}
              >
                Year Built
              </p>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Original</p>
                  <p style={s.reportRowValue}>Jan 1st 2020</p>
                </div>
              </div>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>New Record</p>
                  <p style={s.reportRowValue}>Mar 1st 2020</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* DISCREPANCIES END */}

        {/* RESOLVED DISCREPANCIES START */}
        <div style={{ marginBottom: 30 }}>
          <div style={s.reportSection}>
            <p style={s.reportSectionTitle}>RESOLVED DISCREPANCIES </p>

            <div style={{ marginTop: 5 }}>
              <p
                style={{
                  ...s.reportSectionTitle,
                  fontSize: 12,
                  marginBottom: 5,
                  textDecoration: "underline",
                }}
              >
                Number of buildings
              </p>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Original</p>
                  <p style={s.reportRowValue}>1</p>
                </div>
              </div>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>New Record</p>
                  <p style={s.reportRowValue}>2</p>
                </div>
              </div>
              <div style={s.reportSectionColumn}>
                <div style={s.reportSectionContentContainer}>
                  <p style={s.reportRowTitle}>Resolved To</p>
                  <p style={s.reportRowValue}>2</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* DISCREPANCIES END */}

        {/* TIMELINE START */}
        <div>
          <p style={{ ...s.reportSectionTitle, marginBottom: 20 }}>TIMELINE</p>
          <Timeline timeline={report.timeline} />
          {/* {report.timeline.map((timeline, i) => (
            <div
              key={i}
              style={{
                ...s.timeLineContainer,
                ...(i === report.timeline.length - 1
                  ? { paddingBottom: 0, borderLeft: "0" }
                  : {}),
              }}
            >
              <div
                style={{
                  width: 13,
                  height: 13,
                  backgroundColor: "#DC8E43",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 100,
                  position: "absolute",
                  left: -6.5,
                  top: 2,
                }}
              >
                {i === 0 ? (
                  <img
                    src="https://img.icons8.com/FFFFFF/material-rounded/24/flag.png"
                    alt=""
                    style={{ width: 8, height: 8 }}
                  />
                ) : (
                  <div
                    style={{
                      width: 7,
                      height: 7,
                      backgroundColor: "#FFFFFF",
                      borderRadius: 100,
                    }}
                  />
                )}
              </div>
              <p style={s.timeLineTitle}>{timeline.category}</p>

              {timeline.details.map((detail, i) => (
                <div key={i} style={s.timeLineDetail}>
                  <p> {detail.title} </p>
                  <p style={s.timeLineDetailValue}> {detail.value} </p>
                </div>
              ))}
            </div>
          ))} */}
        </div>
        {/* TIMELINE END */}
      </div>
      </ScrollWindow>
    </CardContainer>
  )
}
