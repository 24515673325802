import React, { useReducer, useRef, useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { selectViewingLeadTimeline } from "../../../../../slices/viewingLeadSlice";
import { useUpdateMortgageDetailsMutation } from "../../../../../slices/api/apiSlice";
import { selectEditMortgageRecordDetailsPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { AccentInfo, CompareDetail, CompareLink, ListRow, DetailWrapper, Button, ButtonWrapper, ErrorWrapper, Label, StyledInputElement, FlexWrapper, GridWrapper, FadeWrapper, Typography } from "../../../../../StyledComponents";
import RecordRowDate from "./RecordRowDate";

const RecordRow = ({ recordFieldRoot, recordField, leadId, userNotifications, dashPerspective, valueType, handleWorkFlow, userId, userFullName, record, mortgageId, setLeadUserNotifications, workflowStage, fieldEditing, activeDiscrepancies, noChanges, setNoChanges, resolvedDiscrepancies, rejectedDiscrepancies, activeLead, location }) => {
  const theme = useTheme()

  const timeline = useSelector(selectViewingLeadTimeline)
  const editMortgageRecordDetailsPerm = useSelector(selectEditMortgageRecordDetailsPerm)

  const [updateMortgageDetails, {isFetching}] = useUpdateMortgageDetailsMutation()

  const [rowStatus, setRowStatus] = useState(null)
  const [editIsFetching, setEditIsFetching] = useState(false)
  const [statusCustomEdit, setStatusCustomEdit] = useState(false)
  const [customEditValue, setCustomEditValue] = useState('')
  const [customEditValueStr, setCustomEditValueStr] = useState('')
  const customEditInput = useRef();

  const handleSubmitEdit = async (selection, boolean, stringBoolean) => {
    setEditIsFetching(true)
    if (stringBoolean) {
      if (stringBoolean === 'TRUE') {
        boolean = true
      } else if (stringBoolean === 'FALSE') {
        boolean = false
      }
    }
    let submit = true
    if (selection === 'custom') {
      if (valueType === 'string') {
        if (customEditValue.length === 0 || customEditValue.length > 50 || customEditValue.includes('<') || customEditValue.includes('>')) {
          if (customEditValue.length === 0) {
            setStatusCustomEdit('blank')
          }
          if (customEditValue.length > 50) {
            setStatusCustomEdit('tooLong')
          }
          if (customEditValue.includes('<')) {
            setStatusCustomEdit('character')
          }
          if (customEditValue.includes('>')) {
            setStatusCustomEdit('character')
          }
          submit = false
        }
      } else {
        let negativeAmount = Math.sign(customEditValue)
        let zeroAmount = Math.sign(customEditValue)
        if (negativeAmount === -1 || zeroAmount === 0 || isNaN(customEditValue) || customEditValue > 100000000) {
          if (negativeAmount === -1) {
            setStatusCustomEdit('negative')
          }
          if (zeroAmount === 0) {
            setStatusCustomEdit('zero')
          }
          if (isNaN(customEditValue)) {
            setStatusCustomEdit('nonNumber')
          }
          if (customEditValue > 100000000) {
            setStatusCustomEdit('large')
          }
          submit = false
        }
      }
    }
    if (submit) {
      if (selection === 'custom' && customEditValue.trim() === record.currentValue) {
        setNoChanges(true)
      } else {
        let resp = await updateMortgageDetails({
          selection: selection,
          userId: userId,
          userFullName: userFullName,
          userNotifications: userNotifications,
          dashPerspective: dashPerspective,
          mortgageId: mortgageId,
          leadId: leadId,
          recordField: recordField,
          customValue: customEditValue,
          activeDiscrepancies: activeDiscrepancies,
          resolvedDiscrepancies: resolvedDiscrepancies,
          rejectedDiscrepancies: rejectedDiscrepancies,
          oldBoolean: boolean,
          oldDiscrepancy: record.discrepancy,
          timeline: timeline,
          recordFieldRoot: recordFieldRoot,
        })
        if (resp.error) {
          setRowStatus('error')
          setCustomEditValueStr()
          setCustomEditValue()
          handleWorkFlow('', '')
        } else {
          setRowStatus('success')
          setCustomEditValueStr()
          setCustomEditValue()
          handleWorkFlow('', '')
          if (location === 'activeLead') {
            setLeadUserNotifications(0)
          }
        }
      }
    }
    setEditIsFetching(false)
  }

  useReducer(() => {
    if (valueType === 'string' || valueType === 'number') {
      setCustomEditValue(`${record.currentValue}`)
    } else {
      setCustomEditValue(`${record.currentValue.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}`)
    }
  }, [record.currentValue, valueType])

  return (
    <ListRow className={fieldEditing === recordField ? 'compareRecordsOpen' : rowStatus === 'error' ? 'error compareRecordsClosed' : rowStatus === 'success' ? 'success compareRecordsClosed' : 'compareRecordsClosed'}>
      <CompareDetail className='recordRow' style={{gridColumn: '2', justifyContent: 'flex-end'}}>
        <DetailWrapper className={record.status === 'initial' ? 'initial' : record.discrepancy === 'active' ? `${record.assignedTier}` : ''}>
          {record.label}
        </DetailWrapper>
      </CompareDetail>
      {record.publicRecordValue ?
        <CompareDetail className="recordRow" style={{gridColumn: '3', justifyContent: 'center'}}>
          {record.label === 'Assessed Value' || record.label === 'Land Value' || record.label === 'Improvements Value' || record.label === 'Close Price' || record.label === 'Last Sale Price' || record.label === 'Assessed Land Value' || record.label === 'Assessed Improvement Value' ?
            <>
              ${parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
            </>
          : record.label === 'Tax Annual Amount' ?
            <>
              ${parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
            </>
          : record.label === 'Latitude' || record.label === 'Longitude' || record.label === 'Rooms' || record.label === 'Bedrooms' || record.label === 'Bathrooms Full' || record.label === 'Bathrooms Total' ?
              <>
              {parseFloat(record.publicRecordValue)}
            </>
          : record.label === 'Living Area' || record.label === 'Garage Area' || record.label === 'Building Area Total' || record.label === 'Gross Area' || record.label === 'Lot Size Square Feet' ?
            <>
              {parseFloat(record.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
            </>
          :
            record.publicRecordValue
          }
        </CompareDetail>
      :
        <CompareDetail className="recordRow" style={{gridColumn: '3', justifyContent: 'center'}}>
          <AccentInfo>-empty-</AccentInfo>
        </CompareDetail>
      }
      <CompareDetail className="recordRow" style={{gridColumn: '4', justifyContent: 'center'}}>
        {record.currentValue ?
          <DetailWrapper className={record.discrepancy === 'active' ? "active" : record.discrepancy === 'rejected' ? "rejected" : record.discrepancy === 'resolved' ? 'resolved' : record.discrepancy === 'inactive' ? 'inactive' : ''}>
            {record.label === 'Assessed Value' || record.label === 'Land Value' || record.label === 'Improvements Value' || record.label === 'Close Price' || record.label === 'Last Sale Price' || record.label === 'Tax Annual Amount' || record.label === 'Assessed Land Value' || record.label === 'Assessed Improvement Value' ?
              <>
                ${parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
              </>
            : record.label === 'Tax Annual Amount' ?
              <>
                ${parseFloat(record.currentValue.substring(1)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
              </>
            : record.label === 'Latitude' || record.label === 'Longitude' || record.label === 'Rooms' || record.label === 'Bedrooms' || record.label === 'Bathrooms Full' || record.label === 'Bathrooms Total' ?
              <>
                {parseFloat(record.currentValue)}
              </>
            : record.label === 'Living Area' || record.label === 'Garage Area' || record.label === 'Building Area Total' || record.label === 'Gross Area' || record.label === 'Lot Size Square Feet' ?
              <>
                {parseFloat(record.currentValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
              </>
            :
              record.currentValue
            }
          </DetailWrapper>
        :
          <DetailWrapper className={record.discrepancy === 'active' ? "active fine empty" : record.discrepancy === 'rejected' ? "rejected fine empty" : record.discrepancy === 'resolved' ? 'resolved fine empty' : record.discrepancy === 'inactive' ? 'gray fine empty' : ''} style={{fontWeight: '400'}}>
            -empty-
          </DetailWrapper>
        }
      </CompareDetail>
      {editIsFetching || isFetching ?
        <CompareDetail style={{gridColumn: '4', gridRow: '2', justifyContent: 'center', height: '10rem'}}>
          <CircularProgress 
            sx={{ color: `${theme.palette.primary.main}`, margin: 'auto' }}
            size={24}
          /> 
        </CompareDetail>
      : fieldEditing === '' && (record.status === 'edited' || record.status === 'inactive' || record.status === 'initial') ?
        <CompareDetail className='recordRow' style={{gridColumn: '5', justifyContent: 'center', paddingRight: '0.5rem'}}>
          {record.status === 'edited' &&
            <CompareLink className="gray small" onClick={() => handleWorkFlow('undo', recordField)}>
              <UndoIcon style={{height: '1.2rem', width: '1.2rem'}} />
            </CompareLink>
          }
          {valueType === 'boolean' ?
            <CompareLink className={editMortgageRecordDetailsPerm ? "gray small" : "small gray disabled"} onClick={editMortgageRecordDetailsPerm ? () => handleWorkFlow('custom', recordField) : null}>
              <SwapHorizIcon style={{height: '1.2rem', width: '1.2rem'}} />
            </CompareLink>
          :
            <CompareLink className={editMortgageRecordDetailsPerm ? "gray small" : "small gray disabled"} onClick={editMortgageRecordDetailsPerm ? () => handleWorkFlow('custom', recordField) : null}>
              <EditIcon style={{height: '1.2rem', width: '1.2rem'}} />
            </CompareLink>
          }
        </CompareDetail>
      : fieldEditing === '' && record.status === 'discrepancy' && activeLead ?
        <CompareDetail className='recordRow' style={{gridColumn: '5', justifyContent: 'center', paddingRight: '0.5rem'}}>
          <CompareLink className="gray" style={{height: '1.3rem', fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleWorkFlow('discrepancy', recordField)}>
            resolve
          </CompareLink>
        </CompareDetail>
      : fieldEditing === '' && record.status === 'discrepancy' ?
        <CompareDetail className='recordRow' style={{gridColumn: '5', justifyContent: 'center', paddingRight: '0.5rem'}}>
          <CompareLink className="gray disabled" style={{height: '1.3rem', fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}}>
            resolve
          </CompareLink>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'discrepancy' ?
        <CompareDetail className="resolve" style={{height: '100%', justifyContent: 'center'}}>
          <FadeWrapper style={{display: 'flex'}}>
            <FlexWrapper className="alignCenter column" style={{marginLeft: '6.8rem'}}>
              <CompareLink className='gray' style={{fontSize: '1rem', margin: '0.2rem 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleSubmitEdit('keep')}>
                keep current value
              </CompareLink>
              <AccentInfo style={{margin: '0', width: '100%', maxWidth: '13rem'}}>- or -</AccentInfo>
              <CompareLink className='gray' style={{fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleSubmitEdit('match')}>
                match public record
              </CompareLink>
              {valueType !== 'boolean' &&
                <>
                  <AccentInfo style={{margin: '0', width: '100%', maxWidth: '13rem'}}>- or -</AccentInfo>
                  <CompareLink className='gray' style={{fontSize: '1rem', margin: '0 0 0 0', width: '100%', maxWidth: '13rem'}} onClick={() => handleWorkFlow('custom', recordField)}>
                    enter other value
                  </CompareLink>
                </>
              }
            </FlexWrapper>
            <Button className="neutral input" onClick={() => handleWorkFlow('', '')} style={{margin: '0 2rem'}}>
              <ClearIcon />
            </Button>
          </FadeWrapper>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'undo' ?
        <CompareDetail style={{gridColumn: '4', gridRow: '2', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
          <Typography>
            {record.lastValue ?
              record.lastValue === record.currentValue || (record.discrepancy === 'resolved' && record.lastValue === record.originalValue) ?
                `reactivate the discrepancy?`
              : record.discrepancy === 'resolved' && record.lastValue !== record.originalValue ?
                `reject the discrepancy?`
              : record.discrepancy === 'rejected' && record.lastValue === record.publicRecordValue && record.originalDiscrepancy ?
                `resolve the discrepancy?`
              : record.discrepancy === 'rejected' && record.lastValue === record.publicRecordValue && !record.originalDiscrepancy ?
                `return to match?`
              :
                <>
                  revert to <Typography className="italic" style={{marginLeft: '0.5rem'}}>{record.lastValue}</Typography> ?
                </>
            :
              <>
                revert to <Typography className="accent" style={{marginLeft: '0.5rem'}}>-empty-</Typography> ?
              </>
            }
          </Typography>
          <FlexWrapper className="fitContentHeight justifyCenter">
            <CompareLink className="small green" onClick={() => handleSubmitEdit('undo')} style={{fontSize: '1rem', margin: '0'}}>
              <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
            </CompareLink>
            <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{fontSize: '1rem', margin: '0'}}>
              <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
            </CompareLink>
          </FlexWrapper>
        </CompareDetail>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType !== 'boolean' && valueType !== 'date' ?
        <GridWrapper style={{gridColumn: '2/5', gridRow: '2', gridTemplateRows: '3.5rem 5.5rem'}}>
          <FadeWrapper>
            <FlexWrapper className="justifyCenter alignEnd" style={{gridRow: '1'}}>
              {statusCustomEdit === "blank" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Can't be blank
                </ErrorWrapper>
              )}
              {statusCustomEdit === "character" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  {`Can't contain the characters '<' or '>'`}
                </ErrorWrapper>
              )}
              {statusCustomEdit === "tooLong" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Can't contain more than 50 characters
                </ErrorWrapper>
              )}
              {statusCustomEdit === "nonNumber" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Must be a number
                </ErrorWrapper>
              )}
              {statusCustomEdit === "negative" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Can't be negative
                </ErrorWrapper>
              )}
              {statusCustomEdit === "zero" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  Can't be zero
                </ErrorWrapper>
              )}
              {statusCustomEdit === "large" && (
                <ErrorWrapper>
                  <ErrorIcon />
                  {`Must be < 99`}
                </ErrorWrapper>
              )}
            </FlexWrapper>
            <FlexWrapper className="alignCenter justifyCenter" style={{gridRow: '2', paddingBottom: '1rem'}}>
              <Typography>{record.label}</Typography>
              <StyledInputElement style={{minWidth: '20rem', marginLeft: '0.5rem'}}
                value={customEditValueStr}
                type="text"
                name="customEdit"
                id="customEdit"
                ref={customEditInput}
                autoFocus
                onChange={() => {
                  if (statusCustomEdit === 'blank' || statusCustomEdit === 'character' || statusCustomEdit === 'tooLong' || statusCustomEdit === "nonNumber" || statusCustomEdit === "negative" || statusCustomEdit === "zero" || statusCustomEdit === "large") {
                    setStatusCustomEdit(null)
                  }
                  if (valueType === 'number') {
                    setCustomEditValueStr(customEditInput.current.value)
                    setCustomEditValue(customEditInput.current.value.replace(/[^0-9-.]/g, ''))
                  } else {
                    setCustomEditValue(customEditInput.current.value)
                  }
                }}
              />
              <ButtonWrapper style={{margin: '0 0 0 2rem'}}>
                <Button className="primary notes" style={{marginRight: '0.5rem'}} onClick={() => handleSubmitEdit('custom')}>
                  <SaveIcon />
                </Button>
                <Button className="gray notes" onClick={() => handleWorkFlow('', '')}>
                  <ClearIcon />
                </Button>
              </ButtonWrapper>
            </FlexWrapper>
          </FadeWrapper>
        </GridWrapper>
      : fieldEditing === recordField && workflowStage === 'custom' && noChanges && valueType !== 'boolean' ?
        <FlexWrapper className="column alignCenter justifyCenter" style={{gridColumn: '4', gridRow: '2'}}>
          <FadeWrapper>
            <Typography>
              no changes made
            </Typography>
            <FlexWrapper className="fitContentHeight justifyCenter">
              <CompareLink className="small gray" onClick={() => handleSubmitEdit('custom', recordField)}>
                <KeyboardBackspaceIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
              </CompareLink>
              <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')}>
                <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
              </CompareLink>
            </FlexWrapper>
          </FadeWrapper>
        </FlexWrapper>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType === 'boolean' && (record.discrepancy === 'rejected' || record.discrepancy === 'resolved') ?
        <FlexWrapper className="column alignCenter justifyCenter" style={{gridColumn: '4', gridRow: '2'}}>
          <FadeWrapper>
            <Typography>
              {record.discrepancy === 'rejected' ?
                `resolve the discrepancy?`
              :
                `reactivate the discrepancy?`
              }
            </Typography>
            <FlexWrapper className="fitContentHeight justifyCenter">
              <CompareLink className="small gray" onClick={() => handleSubmitEdit('switch', null, record.currentValue)}>
                <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
              </CompareLink>
              <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')}>
                <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
              </CompareLink>
            </FlexWrapper>
          </FadeWrapper>
        </FlexWrapper>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType === 'boolean' && (record.currentValue === 'TRUE' || record.currentValue === 'FALSE' || record.currentValue === 'Y' || record.currentValue === 'N') ?
        <FlexWrapper className="column alignCenter justifyCenter" style={{gridColumn: '4', gridRow: '2'}}>
          <FadeWrapper>
            <Typography>
              switch to
              {record.currentValue === 'TRUE' ?
                <Typography className="bold" style={{marginLeft: '0.5rem'}}>FALSE</Typography>
              : record.currentValue === 'FALSE' ?
                <Typography className="bold" style={{marginLeft: '0.5rem'}}>TRUE</Typography> 
              : record.currentValue === 'Y' ?
                <Typography className="bold" style={{marginLeft: '0.5rem'}}>N</Typography> 
              :
                <Typography className="bold" style={{marginLeft: '0.5rem'}}>Y</Typography> 
              }
              ?
            </Typography>
            <FlexWrapper className="fitContentHeight justifyCenter">
              <CompareLink className="small gray" onClick={record.currentValue === 'TRUE' ? () => handleSubmitEdit('switch', true) : () => handleSubmitEdit('switch', false) }>
                <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
              </CompareLink>
              <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')}>
                <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
              </CompareLink>
            </FlexWrapper>
          </FadeWrapper>
        </FlexWrapper>
      : fieldEditing === recordField && workflowStage === 'custom' && !noChanges && valueType === 'boolean' && record.currentValue === '' ?
        <FlexWrapper className="column alignCenter justifyCenter" style={{gridColumn: '4', gridRow: '2'}}>
          <FadeWrapper>
            <FlexWrapper className="fitContentHeight alignCenter justifyCenter">
              <CompareLink className="small gray" onClick={() => handleSubmitEdit('switch', false)} style={{padding: '0.4rem 0.8rem', marginLeft: '7rem'}}>
                TRUE
              </CompareLink>
              <Typography className="accent" style={{padding: '0 0.5rem'}}>or</Typography>
              <CompareLink className="small gray" onClick={() => handleWorkFlow('switch', true)} style={{padding: '0.4rem 0.8rem'}}>
                FALSE
              </CompareLink>
              <Button className="neutral input" onClick={() => handleWorkFlow('', '')} style={{margin: '0 2rem'}}>
                <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
              </Button>
            </FlexWrapper>
          </FadeWrapper>
        </FlexWrapper>
      : fieldEditing === recordField && workflowStage === 'custom' && valueType === 'date' ?
        <RecordRowDate handleWorkFlow={handleWorkFlow} handleSubmitEdit={handleSubmitEdit} />
      :
        <></>
      }
    </ListRow>
  )
}

export default RecordRow;