import React, { useState, useRef, useEffect, forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'react-csv-importer/dist/index.css';
import "../../../../styles.css";
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import DatePicker from 'react-datepicker';
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {addDays, subMonths} from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import { selectTeamDefaultInterestRate } from "../../../../slices/teamAndUserSettingsSlice";
import { useProvideMortgageFinancialInformationMutation } from "../../../../slices/api/apiSlice";
import { selectSaveFinancialInformationEnabled, setSaveFinancialInformationEnabled } from "../../../../slices/functionAvailabilitySlice";
import { ErrorWrapper, InputWrapper, StatusAndLabelWrapper, StyledInputElement, Button, ButtonWrapper, ModalContentContainer, FlexWrapper, Typography, Divider, ComponentHeader } from "../../../../StyledComponents";
import { setLeadActionNavMenuSelection } from "../../../../slices/sessionDataSlice";

const ProvideFinancials = ({ mortgageData, userFullName, leadData, navSelection, handleCloseModal, dashPerspective, userId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [provideMortgageFinancialInformation, {}] = useProvideMortgageFinancialInformationMutation()

  const saveFinancialInformationEnabled = useSelector(selectSaveFinancialInformationEnabled)
  const teamDefaultInterestRate = useSelector(selectTeamDefaultInterestRate)

  const [invalidInputs, setInvalidInputs] = useState(false)
  const [mortgageTermRequired, setMortgageTermRequired] = useState(false)
  const [loanAmountRequired, setLoanAmountRequired] = useState(false)
  const [interestRateRequired, setInterestRateRequired] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [saveIsFetching, setSaveIsFetching] = useState(false)
  const [saveIsSuccess, setSaveIsSuccess] = useState(false)
  const [saveIsError, setSaveIsError] = useState(false)
  const [statusMortgageTerm, setstatusMortgageTerm] = useState(null)
  const [mortgageTerm, setMortgageTerm] = useState('')
  const mortgageTermRef = useRef()
  const [statusOriginalLoanAmount, setStatusOriginalLoanAmount] = useState(null)
  const [originalLoanAmountStr, setOriginalLoanAmountStr] = useState('')
  const [originalLoanAmount, setOriginalLoanAmount] = useState('')
  const originalLoanAmountRef = useRef()
  const [statusOriginalInterestRate, setStatusOriginalInterestRate] = useState(null)
  const [originalInterestRate, setOriginalInterestRate] = useState('')
  const originalInterestRateRef = useRef()

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="styled-calendar-input-icon-left"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ))

  const validateMortgageTerm = async () => {
    let validated = true
    if (mortgageTermRequired) {
      validated = false
      setMortgageTerm(mortgageTermRef.current.value)
      let negativeTerm = Math.sign(parseFloat(mortgageTermRef.current.value))
      let zeroTerm = Math.sign(parseFloat(mortgageTermRef.current.value))
      if (negativeTerm === -1 || zeroTerm === 0 || isNaN(parseFloat(mortgageTermRef.current.value)) || parseFloat(mortgageTermRef.current.value) > 99 || parseFloat(mortgageTermRef.current.value) % 1 != 0 || !parseInt(mortgageTermRef.current.value) > 0) {
        if (!parseInt(mortgageTermRef.current.value) > 0) {
          setstatusMortgageTerm('required')
        }
        if (parseFloat(mortgageTermRef.current.value) % 1 != 0) {
          setstatusMortgageTerm('decimal')
        }
        if (negativeTerm === -1) {
          setstatusMortgageTerm('negative')
        }
        if (zeroTerm === 0) {
          setstatusMortgageTerm('zero')
        }
        if (isNaN(parseFloat(mortgageTermRef.current.value))) {
          setstatusMortgageTerm('nonNumber')
        }
        if (parseFloat(mortgageTermRef.current.value) > 99) {
          setstatusMortgageTerm('large')
        }
        dispatch( setSaveFinancialInformationEnabled(false) )
      } else {
        setInvalidInputs(false)
        setstatusMortgageTerm(null)
        if (saveIsError) {
          setSaveIsError(false)
        }
        if (((interestRateRequired && !statusOriginalInterestRate) || !interestRateRequired) && ((loanAmountRequired && !statusOriginalLoanAmount) || !loanAmountRequired)) {
          dispatch( setSaveFinancialInformationEnabled(true) )
          validated = true
        }
      }
    }
    return validated
  }
  const validateLoanAmount = async () => {
    let validated = true
    if (loanAmountRequired) {
      validated = false
      setOriginalLoanAmountStr(originalLoanAmountRef.current.value.toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2}))
      setOriginalLoanAmount(parseFloat(originalLoanAmountRef.current.value.replace(/[^0-9-.]/g, '')))
      let negativeAmount = Math.sign(parseFloat(originalLoanAmountRef.current.value))
      let zeroAmount = Math.sign(parseFloat(originalLoanAmountRef.current.value))
      if (negativeAmount === -1 || zeroAmount === 0 || isNaN(parseFloat(originalLoanAmountRef.current.value)) || parseFloat(originalLoanAmountRef.current.value) > 100000000 || parseFloat(originalLoanAmountRef.current.value) % 1 != 0 || !parseFloat(originalLoanAmountRef.current.value) > 0) {
        if (!parseFloat(originalLoanAmountRef.current.value) > 0) {
          setStatusOriginalLoanAmount('required')
        }
        if (parseFloat(originalLoanAmountRef.current.value) % 1 != 0) {
          setStatusOriginalLoanAmount('decimal')
        }
        if (negativeAmount === -1) {
          setStatusOriginalLoanAmount('negative')
        }
        if (zeroAmount === 0) {
          setStatusOriginalLoanAmount('zero')
        }
        if (isNaN(parseFloat(originalLoanAmountRef.current.value))) {
          setStatusOriginalLoanAmount('nonNumber')
        }
        if (parseFloat(originalLoanAmountRef.current.value) > 100000000) {
          setStatusOriginalLoanAmount('large')
        }
        dispatch( setSaveFinancialInformationEnabled(false) )
      } else {
        setInvalidInputs(false)
        setStatusOriginalLoanAmount(null)
        if (saveIsError) {
          setSaveIsError(false)
        }
        if (((interestRateRequired && !statusOriginalInterestRate) || !interestRateRequired) && ((mortgageTermRequired && !statusMortgageTerm) || !mortgageTermRequired)) {
          dispatch( setSaveFinancialInformationEnabled(true) )
          validated = true
        }
      }
    }
    return validated
  }
  const validateInterestRate = async () => {
    let validated = true
    if (interestRateRequired) {
      validated = false
      setOriginalInterestRate(originalInterestRateRef.current.value)
      let negativeInterest = Math.sign(parseFloat(originalInterestRateRef.current.value))
      let zeroInterest = Math.sign(parseFloat(originalInterestRateRef.current.value))
      if (negativeInterest === -1 || zeroInterest === 0 || isNaN(parseFloat(originalInterestRateRef.current.value)) || parseFloat(originalInterestRateRef.current.value) > 99 || !parseFloat(originalInterestRateRef.current.value) > 0) {
        if (!parseFloat(originalInterestRateRef.current.value) > 0) {
          setStatusOriginalInterestRate('required')
        }
        if (negativeInterest === -1) {
          setStatusOriginalInterestRate('negative')
        }
        if (zeroInterest === 0) {
          setStatusOriginalInterestRate('zero')
        }
        if (isNaN(parseFloat(originalInterestRateRef.current.value))) {
          setStatusOriginalInterestRate('nonNumber')
        }
        if (parseFloat(originalInterestRateRef.current.value) > 99) {
          setStatusOriginalInterestRate('large')
        }
        dispatch( setSaveFinancialInformationEnabled(false) )
      } else {
        setInvalidInputs(false)
        setStatusOriginalInterestRate(null)
        if (saveIsError) {
          setSaveIsError(false)
        }
        if (((loanAmountRequired && !statusOriginalLoanAmount) || !loanAmountRequired) && ((mortgageTermRequired && !statusMortgageTerm) || !mortgageTermRequired)) {
          dispatch( setSaveFinancialInformationEnabled(true) )
          validated = true
        }
      }
    }
    return validated
  }

  const handleSave = async () => {
    setSaveIsFetching(true)
    let validateTerm = await validateMortgageTerm()
    let validateAmount = await validateLoanAmount()
    let validateInterest = await validateInterestRate()
    if (validateTerm && validateAmount && validateInterest) {
      let newOriginationDate = ''
      let newMortgageTerm = 0
      let newOriginalLoanAmount = 0
      let newOriginalInterestRate = 0
      if (mortgageData.originationDateLabel.length > 0) {
        newOriginationDate = mortgageData.originationDate
      } else {
        newOriginationDate = startDate
      }
      if (mortgageData.mortgageTerm > 0) {
        newMortgageTerm = mortgageData.mortgageTerm 
      } else {
        newMortgageTerm = mortgageTerm
      }
      if (mortgageData.originalLoanAmount > 0) {
        newOriginalLoanAmount = mortgageData.originalLoanAmount 
      } else {
        newOriginalLoanAmount = parseFloat(originalLoanAmount)
      }
      if (mortgageData.originalInterestRate > 0) {
        newOriginalInterestRate = mortgageData.originalInterestRate 
      } else {
        newOriginalInterestRate = parseFloat(originalInterestRate)
      }
      let resp = await provideMortgageFinancialInformation({
        mortgageId: mortgageData._id,
        activeLeadId: mortgageData.activeLead,
        userFullName: userFullName,
        newOriginationDate: newOriginationDate,
        newMortgageTerm: parseInt(newMortgageTerm),
        newOriginalLoanAmount: newOriginalLoanAmount,
        newOriginalInterestRate: newOriginalInterestRate,
        targetInterestRate: parseFloat(teamDefaultInterestRate),
        userNotifications: leadData.userNotifications,
        dashPerspective: dashPerspective,
        userId: userId,
      })
      if (resp.error) {
        setSaveIsError(true)
      } else {
        setSaveIsSuccess(true)
      }
    } else {
      setInvalidInputs(true)
      dispatch( setSaveFinancialInformationEnabled(false) )
    }
    setSaveIsFetching(false)
  }

  useEffect(() => {
    if (!mortgageData.mortgageTerm > 0) {
      setMortgageTermRequired(true)
    }
    if (!mortgageData.originalLoanAmount > 0) {
      setLoanAmountRequired(true)
    }
    if (!mortgageData.originalInterestRate > 0) {
      setInterestRateRequired(true)
    }
  }, [mortgageData])

  return (
    <>
      {saveIsSuccess ?
        <ComponentHeader className="modal green">
          <CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />
          Success
        </ComponentHeader>
      : saveIsError ?
        <ComponentHeader className="modal red">
          <ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />
          failed... try again or contact support@lancastersweep.com
        </ComponentHeader>
      :
        <ComponentHeader className="modal">
          <Typography className="xLarge">
            Refinance Targets
          </Typography>
        </ComponentHeader>
      }
      <ModalContentContainer className="topColumn leadActions" style={{padding: '1rem 0'}}>
        {saveIsSuccess || (!mortgageTermRequired && !loanAmountRequired && !interestRateRequired) ?
          <FlexWrapper className="fitContentHeight" style={{padding: '2rem 0 0 10rem', height: '10rem'}}>
          </FlexWrapper>
        : saveIsError ?
          <FlexWrapper className="fitContentHeight column" style={{padding: '2rem 0 0 10rem', height: '10rem'}}>
            <Typography className="subHeader xxLarge xxxLargeHeight exoItalic">
              Please provide the following financial information
            </Typography>
            <FlexWrapper className="alignCenter fitContentHeight">
              <ErrorIcon style={{height: '2rem', width: '2rem', color: `${theme.accent.error.primary}`, marginRight: '0.5rem'}} />
              <Typography className="error alignCenter small">Something has gone wrong. Please contact your app admin if the problem persists.</Typography>
            </FlexWrapper>
          </FlexWrapper>
        : invalidInputs ?
          <FlexWrapper className="fitContentHeight column" style={{padding: '2rem 0 0 10rem', height: '10rem'}}>
            <Typography className="subHeader xxLarge xxxLargeHeight exoItalic">
              Please provide the following financial information
            </Typography>
            <FlexWrapper className="alignCenter fitContentHeight">
              <ErrorIcon style={{height: '2rem', width: '2rem', color: `${theme.accent.error.primary}`, marginRight: '0.5rem'}} />
              <Typography className="error alignCenter small">Invalid inputs. Please try again.</Typography>
            </FlexWrapper>
          </FlexWrapper>
        :
          <FlexWrapper className="alignStart" style={{padding: '2rem 0 0 10rem', height: '10rem'}}>
            <Typography className="subHeader xxLarge xxxLargeHeight exoItalic">
              Please provide the following financial information
            </Typography>
            <Typography className="required xxxLarge">*</Typography>
          </FlexWrapper>
        }
        <FlexWrapper className="column fitContentHeight">
          {!mortgageData.originationDateLabel.length > 0 && 
            <InputWrapper className="alignStart" style={{width: '40rem', margin: '0 auto 1rem 33rem'}}>
              <StatusAndLabelWrapper className='column'>
                <FlexWrapper>
                  <Typography htmlFor="root-portal">Mortgage Start Date</Typography>
                  <Typography className="required">*</Typography>
                </FlexWrapper>
              </StatusAndLabelWrapper>
              <FlexWrapper className="alignCenter fitContentHeight">
                <DatePicker 
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  maxDate={addDays(new Date(), 0)}
                  minDate={subMonths(new Date(), 360)}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={10}
                  withPortal
                  portalId="root-portal"
                  customInput={<ExampleCustomInput />}
                  dayClassName={() => "example-datepicker-day-class"}
                  calendarClassName="example-datepicker-class"
                  todayButton="CLICK FOR TODAY'S DATE"
                />
              </FlexWrapper>
            </InputWrapper>
          }
          {!mortgageData.mortgageTerm > 0 &&
            <InputWrapper className="alignStart" style={{width: '40rem', margin: '0 auto 1rem 33rem'}}>
              <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                <FlexWrapper className="fitContentWidth">
                  <Typography htmlFor="mortgageTerm">Mortgage Term (years)</Typography>
                  <Typography className="required">*</Typography>
                </FlexWrapper>
              </StatusAndLabelWrapper>
              <FlexWrapper className="alignCenter fitContentHeight">
                <StyledInputElement
                  style={{width: '10rem', marginRight: '1rem'}}
                  value={mortgageTerm}
                  type="text"
                  name="mortgageTerm"
                  id="mortgageTerm"
                  ref={mortgageTermRef}
                  className={(statusMortgageTerm) ? 'error' : (mortgageTermRef.current && parseInt(mortgageTermRef.current.value) > 0) ? 'populated' : mortgageTermRequired ? 'required' :  ''}
                  onChange={() => validateMortgageTerm()}
                />
                {statusMortgageTerm === "nonNumber" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Must be a number
                  </ErrorWrapper>
                )}
                {statusMortgageTerm === "decimal" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Must be a whole number
                  </ErrorWrapper>
                )}
                {statusMortgageTerm === "negative" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Can't be negative
                  </ErrorWrapper>
                )}
                {statusMortgageTerm === "zero" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Can't be zero
                  </ErrorWrapper>
                )}
                {statusMortgageTerm === "large" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Must be < 99`}
                  </ErrorWrapper>
                )}
              </FlexWrapper>
            </InputWrapper>
          }
          {!mortgageData.originalLoanAmount > 0 &&
            <InputWrapper className="alignStart" style={{width: '40rem', margin: '0 auto 1rem 33rem'}}>
              <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                <FlexWrapper>
                  <Typography htmlFor="originalLoanAmount">Original Loan Amount ($)</Typography>
                  <Typography className="required">*</Typography>
                </FlexWrapper>
              </StatusAndLabelWrapper>
              <FlexWrapper className="alignCenter fitContentHeight">
                <StyledInputElement
                  style={{width: '10rem', marginRight: '1rem'}}
                  value={originalLoanAmountStr}
                  type="text"
                  name="originalLoanAmount"
                  id="originalLoanAmount"
                  ref={originalLoanAmountRef}
                  className={(statusOriginalLoanAmount && statusOriginalLoanAmount !== 'required') ? 'error' : (originalLoanAmountRef.current && parseFloat(originalLoanAmountRef.current.value) > 0) ? 'populated' : loanAmountRequired ? 'required' : ''}
                  onChange={() => validateLoanAmount()}
                />
                {statusOriginalLoanAmount === "nonNumber" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Must be a number
                  </ErrorWrapper>
                )}
                {statusOriginalLoanAmount === "negative" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Can't be negative
                  </ErrorWrapper>
                )}
                {statusOriginalLoanAmount === "zero" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Can't be zero
                  </ErrorWrapper>
                )}
                {statusOriginalLoanAmount === "large" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Must be < 100m`}
                  </ErrorWrapper>
                )}
              </FlexWrapper>
            </InputWrapper>
          }
          {!mortgageData.originalInterestRate > 0 &&
            <InputWrapper className="alignStart" style={{width: '40rem', margin: '0 auto 1rem 33rem'}}>
              <StatusAndLabelWrapper style={{height: '2.4rem'}}>
                <FlexWrapper>
                  <Typography htmlFor="originalInterestRate">Original Interest Rate (%)</Typography>
                  <Typography className="required">*</Typography>
                </FlexWrapper>
              </StatusAndLabelWrapper>
              <FlexWrapper className="alignCenter fitContentHeight">
                <StyledInputElement
                  style={{width: '10rem', marginRight: '1rem'}}
                  value={originalInterestRate}
                  type="text"
                  name="originalInterestRate"
                  id="originalInterestRate"
                  ref={originalInterestRateRef}
                  className={(statusOriginalInterestRate && statusOriginalInterestRate !== 'required') ? 'error' : (originalInterestRateRef.current && parseFloat(originalInterestRateRef.current.value) > 0) ? 'populated' : interestRateRequired ? 'required' : ''}
                  onChange={() => validateInterestRate()}
                />
                {statusOriginalInterestRate === "nonNumber" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Must be a number
                  </ErrorWrapper>
                )}
                {statusOriginalInterestRate === "negative" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Can't be negative
                  </ErrorWrapper>
                )}
                {statusOriginalInterestRate === "zero" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    Can't be zero
                  </ErrorWrapper>
                )}
                {statusOriginalInterestRate === "large" && (
                  <ErrorWrapper>
                    <ErrorIcon />
                    {`Must be < 99`}
                  </ErrorWrapper>
                )}
              </FlexWrapper>
            </InputWrapper>
          }
          {!saveIsSuccess &&
            <Divider className="gray" style={{width: '60%', margin: '2rem 0'}} />
          }
          <FlexWrapper className="column">
            {mortgageData.originationDateLabel.length > 0 &&
              <FlexWrapper className="fitContentHeight">
                <FlexWrapper className="justifyEnd">
                  <Typography className="xLargeHeight">
                    origination date:
                  </Typography>
                </FlexWrapper>
                <FlexWrapper style={{paddingLeft: '0.5rem'}}>
                  <Typography className="xLarge bold">
                    {mortgageData.originationDateLabel}
                  </Typography>
                </FlexWrapper>
              </FlexWrapper>
            }
            {mortgageData.mortgageTerm > 0 &&
              <FlexWrapper className="fitContentHeight">
                <FlexWrapper className="justifyEnd">
                  <Typography className="xLargeHeight">
                    term:
                  </Typography>
                </FlexWrapper>
                <FlexWrapper style={{paddingLeft: '0.5rem'}}>
                  <Typography className="xLarge bold">
                    {mortgageData.mortgageTerm} years
                  </Typography>
                </FlexWrapper>
              </FlexWrapper>
            }
            {mortgageData.originalLoanAmount > 0 &&
              <FlexWrapper className="fitContentHeight">
                <FlexWrapper className="justifyEnd">
                  <Typography className="xLargeHeight">
                    original loan amount:
                  </Typography>
                </FlexWrapper>
                <FlexWrapper style={{paddingLeft: '0.5rem'}}>
                  <Typography className="xLarge bold">
                    ${mortgageData.originalLoanAmount.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                </FlexWrapper>
              </FlexWrapper>
            }
            {mortgageData.originalInterestRate > 0 &&
              <FlexWrapper className="fitContentHeight">
                <FlexWrapper className="justifyEnd">
                  <Typography className="xLargeHeight">
                    original interest rate:
                  </Typography>
                </FlexWrapper>
                <FlexWrapper style={{paddingLeft: '0.5rem'}}>
                  <Typography className="xLarge bold">
                    {mortgageData.originalInterestRate}%
                  </Typography>
                </FlexWrapper>
              </FlexWrapper>
            }
          </FlexWrapper>
          {(mortgageData.originationDateLabel.length > 0 || mortgageData.mortgageTerm > 0 || mortgageData.originalLoanAmount > 0 || mortgageData.originalInterestRate > 0) &&
            <Typography className="info alignStart justifyCenter" style={{margin: '1rem 1.5rem 0 0'}}>
              <InfoIcon style={{margin: '0 0.2rem 0 0'}} />
              These values are pulled from internal records.<br/>
              If you'd like to provide a different loan amount,<br/>
              do so in the "Compare and Update Records" tab
            </Typography>
          }
        </FlexWrapper>
        <ButtonWrapper className="marginAuto">
          {(saveIsSuccess && navSelection === 'provideFinancialsModal') ?
            <Button style={{width: '23rem'}} className="primary" onClick={() => dispatch( setLeadActionNavMenuSelection('editTargets') )}>
              Back to Refinance Targets
            </Button>
          : (saveIsSuccess && navSelection === 'provideFinancialsCard') ?
            <Button style={{width: '21rem'}} className="primary" onClick={() => handleCloseModal()}>
              Back to Active Leads
            </Button>
          : (!mortgageTermRequired && !loanAmountRequired && !interestRateRequired) ?
            <Button 
              className="neutral"
              onClick={navSelection === 'provideFinancialsModal' ? () => dispatch( setLeadActionNavMenuSelection('editTargets') ) : () => handleCloseModal()}>
              Go Back
            </Button>
          :
            <>
              <Button 
                className={saveIsFetching ? 'neutralLoading' : 'neutral'}
                disabled={saveIsFetching}
                onClick={saveIsFetching ? null 
                  : navSelection === 'provideFinancialsModal' ? () => dispatch( setLeadActionNavMenuSelection('editTargets') ) 
                  : () => handleCloseModal()
                }
              >
                {navSelection === 'provideFinancialsModal' ?
                  `Go Back`
                :
                  `Close`
                }
              </Button>
              <Button 
                className={saveIsFetching ? 'primaryLoading' : (!saveIsError && saveFinancialInformationEnabled) ? 'primary' : 'primaryDisabled'}
                disabled={saveIsError || saveIsFetching || !saveFinancialInformationEnabled}
                onClick={(!saveIsError && !saveIsFetching && saveFinancialInformationEnabled) ? () => handleSave() : null}
              >
                {saveIsFetching ?
                  <CircularProgress
                    sx={{ color: `${theme.palette.common.white}` }}
                    size={18}
                  />
                :
                  `Save`
                }
              </Button>
            </>
          }
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default ProvideFinancials;