import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'

const initialState = {
  data: {
    leadGenerations: {
      totalSuccessfulQueries: 0,
      totalLeadsGenerated: 0,
      closureRatio: 0,
      totalHits: 0,
      totalHitsAvgPercent: 0,
      totalTier1Leads: 0,
      totalTier2Leads: 0,
    },
    dismissedLeads: 0,
    renegotiations: {
      closedRenegotiations: 0,
      lastRenegotiation: '',
      renegotiationFrequency: 0,
    },
    refinances: {
      closedRefinances: 0,
      grossProfitNumber: 0,
      grossProfitPercent: 0,
      averageProfitNumber: 0,
      averageProfitPercent: 0,
      lastRefinance: '',
      refinanceFrequency: 0,
    },
    pieCharts: {
      closureType: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      actionType: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      tier1: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      tier2: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      renegotiations: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
      refinances: [
        {name: '', size: 0},
        {name: '', size: 0},
      ],
    },
    query: {
      lastQuery: ''
    }
  },
  status: 'idle',
  error: null
}

export const teamStatsSlice = createSlice({
  name: 'teamStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(apiSlice.endpoints.initialLoad.matchFulfilled, (state, {payload}) => {
      if (payload.data) {
        state.data = payload.data.teamStats
      }
    })
    .addMatcher(apiSlice.endpoints.recordRecordSweep.matchFulfilled, (state, {payload}) => {
      if (payload.data.querySettings.updateTeamStats === 'true') {
        state.data.leadGenerations = payload.data.newTeamStats
        if (payload.data.querySettings.updateLastQuery === 'true') {
          state.data.query.lastQuery = payload.data.lastQuery
        }
      }
    })
    .addMatcher(apiSlice.endpoints.dismissLead.matchFulfilled, (state, {payload}) => {
      state.data.dismissedLeads = payload.data.totalTeamDismissedLeads
    })
    .addMatcher(apiSlice.endpoints.setLeadStatusFinalized.matchFulfilled, (state, {payload}) => {
      if (payload.data.teamStats.outcome === 'renegotiation') {
        state.data.renegotiations.closedRenegotiations = payload.data.teamStats.closedRenegotiations;
        state.data.renegotiations.tier1Renegotiations = payload.data.teamStats.tier1Renegotiations;
        state.data.renegotiations.tier2Renegotiations = payload.data.teamStats.tier2Renegotiations;
        state.data.renegotiations.lastRenegotiation = payload.data.teamStats.lastRenegotiation;
        state.data.renegotiations.renegotiationFrequency = payload.data.teamStats.renegotiationFrequency;
        state.data.pieCharts.closureType[0] = {name: `Renegotiations: ${payload.data.teamStats.closedRenegotiations}`, size: parseInt(`${payload.data.teamStats.closedRenegotiations}`)}
        state.data.pieCharts.actionType[0] = {name: `${payload.data.teamStats.closedRenegotiations}`, size: parseInt(`${payload.data.teamStats.closedRenegotiations}`)}
      } else {
        state.data.refinances.closedRefinances = payload.data.teamStats.closedRefinances;
        state.data.refinances.lastRefinance = payload.data.teamStats.lastRefinance;
        state.data.refinances.refinanceFrequency = payload.data.teamStats.refinanceFrequency;
        state.data.refinances.grossProfitNumber = payload.data.teamStats.grossProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0});
        state.data.refinances.grossProfitPercent = payload.data.teamStats.grossProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0});
        state.data.refinances.averageProfitNumber = payload.data.teamStats.averageProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0});
        state.data.refinances.averageProfitPercent = payload.data.teamStats.averageProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0});
        state.data.pieCharts.closureType[1] = {name: `Refinances: ${payload.data.teamStats.closedRefinances}`, size: parseInt(`${payload.data.teamStats.closedRefinances}`)}
        state.data.pieCharts.actionType[1] = {name: `${payload.data.teamStats.closedRefinances}`, size: parseInt(`${payload.data.teamStats.closedRefinances}`)}
      }
      if (payload.data.leadTier === 1) {
        state.data.pieCharts.tier1[0] = {name: `${payload.data.newTierClosures}`, size: parseInt(`${payload.data.newTierClosures}`)}
      } else if (payload.data.leadTier === 2) {
        state.data.pieCharts.tier2[0] = {name: `${payload.data.newTierClosures}`, size: parseInt(`${payload.data.newTierClosures}`)}
      }
    })
    .addMatcher(apiSlice.endpoints.reloadRenegotiationQuarterlyBreakdown.matchFulfilled, (state, {payload}) => {
      state.teamStats.data.pieCharts.actionType[0] = payload.data.pieChartTwo
      if (payload.data.actionTaken === 'closed') {
        state.teamStats.data.pieCharts.closureType[0] = payload.data.pieChartOne
        if (payload.data.leadTier === 1) {
          state.teamStats.data.pieCharts.tier1[0] = payload.data.newPieChartThree
        } else if (payload.data.leadTier === 2) {
          state.teamStats.data.pieCharts.tier2[0] = payload.data.newPieChartThree
        }
      } else if (payload.data.actionTaken === 'dismissed') {
        if (payload.data.leadTier === 1) {
          state.teamStats.data.pieCharts.tier1[1] = payload.data.newPieChartThree
        } else if (payload.data.leadTier === 2) {
          state.teamStats.data.pieCharts.tier2[1] = payload.data.newPieChartThree
        }
      }
    })
    .addMatcher(apiSlice.endpoints.reloadRefinanceQuarterlyBreakdown.matchFulfilled, (state, {payload}) => {
      state.teamStats.data.pieCharts.actionType[1] = payload.data.pieChartTwo
      if (payload.data.actionTaken === 'closed') {
        state.teamStats.data.pieCharts.closureType[1] = payload.data.pieChartOne
        if (payload.data.leadTier === 1) {
          state.teamStats.data.pieCharts.tier1[0] = payload.data.newPieChartThree
        } else if (payload.data.leadTier === 2) {
          state.teamStats.data.pieCharts.tier2[0] = payload.data.newPieChartThree
        }
      } else if (payload.data.actionTaken === 'dismissed') {
        if (payload.data.leadTier === 1) {
          state.teamStats.data.pieCharts.tier1[1] = payload.data.newPieChartThree
        } else if (payload.data.leadTier === 2) {
          state.teamStats.data.pieCharts.tier2[1] = payload.data.newPieChartThree
        }
      }
    })
  }
})

export default teamStatsSlice.reducer

export const selectTotalSuccessfulQueries = state => state.teamStats.data.leadGenerations.totalSuccessfulQueries
export const selectTeamLeadGenerationStats = state => state.teamStats.data.leadGenerations
export const selectTeamClosedRenegotiations = state => state.teamStats.data.renegotiations.closedRenegotiations
export const selectTeamRenegotiations = state => state.teamStats.data.renegotiations
export const selectTeamClosedRefinances = state => state.teamStats.data.refinances.closedRefinances
export const selectTeamRefinances = state => state.teamStats.data.refinances
export const selectTeamQueryStats = state => state.teamStats.data.query
export const selectClosureTypePieChartData = state => state.teamStats.data.pieCharts.closureType
export const selectActionTypePieChartData = state => state.teamStats.data.pieCharts.actionType
export const selectTier1PieChartData = state => state.teamStats.data.pieCharts.tier1
export const selectTier2PieChartData = state => state.teamStats.data.pieCharts.tier2
export const selectRenegotiationsPieChartData = state => state.teamStats.data.pieCharts.renegotiations
export const selectRefinancesPieChartData = state => state.teamStats.data.pieCharts.refinances