import React, { forwardRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DatePicker from 'react-datepicker';
import { useTheme } from "@mui/styles";
import {addDays, subMonths} from 'date-fns';
import { CompareLink, FlexWrapper } from "../../../../../StyledComponents";

const RecordRowDate = ({ handleWorkFlow, handleSubmitEdit }) => {
  const theme = useTheme()

  const [startDate, setStartDate] = useState(new Date())

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="styled-calendar-input-icon-left"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ))

  return (
    <FlexWrapper className="alignCenter fitContentHeight" style={{gridColumn: '4', gridRow: '2'}} >
      <DatePicker 
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        maxDate={addDays(new Date(), 0)}
        minDate={subMonths(new Date(), 360)}
        showMonthDropdown
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={10}
        withPortal
        portalId="root-portal"
        customInput={<ExampleCustomInput />}
        dayClassName={() => "example-datepicker-day-class"}
        calendarClassName="example-datepicker-class"
        todayButton="CLICK FOR TODAY'S DATE"
      />
      <FlexWrapper className="fitContentHeight justifyCenter">
        <CompareLink className="small green" onClick={() => handleSubmitEdit('undo')} style={{fontSize: '1rem', margin: '0'}}>
          <CheckIcon style={{height: '1.5rem', width: '1.5rem', color: `${theme.palette.primary.main}`}} />
        </CompareLink>
        <CompareLink className="small gray" onClick={() => handleWorkFlow('', '')} style={{fontSize: '1rem', margin: '0'}}>
          <ClearIcon style={{height: '1.5rem', width: '1.5rem'}} />
        </CompareLink>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default RecordRowDate;