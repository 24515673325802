import React from "react";
import Timeline from "../../Timeline/Timeline";
import CancelIcon from '@mui/icons-material/Cancel';
import NotesModal from "../LeadActions/Components/Notes.js";
import ReportsModal from "../LeadActions/Components/ReportsModal.js";
import AddAssignees from "../LeadActions/Components/AddAssignees.js";
import { useSelector } from "react-redux";
import InvestigateLead from "../LeadActions/Components/LeadStatus/InvestigateLead.js";
import PropertyDetails from "../LeadActions/Components/PropertyDetails.js";
import MortgageActionsNav from "./MortgageActionsNav";
import LeadCompareRecords from "../LeadActions/Components/CompareRecords.js";
import AdminCompareRecords from "../AdminMortgageActions/components/CompareRecords/CompareRecords.js";
import { selectViewingMortgage } from "../../../slices/viewingMortgageSlice";
import { ActionIconWrapper, ModalContainer, ComponentBorder, TimelineContainer, ComponentHeader, Typography } from "../../../StyledComponents";
import { selectTeamMembersByName } from "../../../slices/teamMembersSlice.js";
import { selectMortgageActionsNavSelection } from "../../../slices/sessionDataSlice.js";
import EditTags from "../LeadActions/Components/EditTags/EditTags.js";

const MortgageActionsModal = ({ handleCloseModal, userId, teamId, userFullName, dashPerspective, reports, leadData }) => {

  const mortgageData = useSelector(selectViewingMortgage)
  const navSelection = useSelector(selectMortgageActionsNavSelection)
  const teamMembersByName = useSelector(selectTeamMembersByName)

  return (
    <ModalContainer className="threePanel">
      <ComponentBorder className="top" style={{gridColumn: '1/6'}}>
        <ComponentHeader className="areaTitle">
          <Typography className="medium" style={{marginRight: '0.5rem'}}>mortgage for:</Typography>
          {mortgageData.streetAddress}
        </ComponentHeader>
        <ActionIconWrapper className="medium grayText circle hoverBorder hover" style={{margin: 'auto 2rem auto 0'}}>
          <CancelIcon onClick={() => handleCloseModal()} style={{height: '100%', width: '100%'}} />  
        </ActionIconWrapper>
      </ComponentBorder>
      <MortgageActionsNav mortgageData={mortgageData} reportsNumber={mortgageData.reports.length} handleCloseModal={handleCloseModal} />
      {navSelection === 'reports' ?
        <ReportsModal reports={reports} />
      : navSelection === 'notes' ?
        <NotesModal handleCloseModal={handleCloseModal} userId={userId} userFullName={userFullName} leadData={leadData} dashPerspective={dashPerspective} mortgageData={mortgageData} />
      : navSelection === 'compareRecords' && leadData ?
        <LeadCompareRecords mortgageData={mortgageData} dashPerspective={dashPerspective} userFullName={userFullName} leadData={leadData} teamId={teamId} location={'repository'} leadUserNotifications={0} />
      : navSelection === 'compareRecords' ?
        <AdminCompareRecords mortgageData={mortgageData} />
      : navSelection === 'propertyDetails' ?
        <PropertyDetails handleCloseModal={handleCloseModal} mortgageData={mortgageData} />
      : navSelection === 'investigateLead' ?
        <InvestigateLead handleCloseModal={handleCloseModal} userId={userId} teamId={teamId} userFullName={userFullName} teamMembersByName={teamMembersByName} mortgageId={mortgageData._id} leadData={{assigneeIds: []}} dashPerspective={dashPerspective} requestOrigin={'repository'} />
      : navSelection === 'editTags' ?
        <EditTags leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={mortgageData.activeLead} mortgageData={mortgageData} />
      : navSelection === 'addAssignees' ?
        <AddAssignees teamMembersByName={teamMembersByName} handleCloseModal={handleCloseModal} activeLead={mortgageData.activeLead} leadData={leadData} mortgageData={mortgageData} origin={'mortgage'} userFullName={userFullName} />
      :
        <></>
      }
      <ComponentHeader className="modal timeline">Timeline</ComponentHeader>
      <TimelineContainer className="actionsModal">
        <Timeline timeline={mortgageData.timeline} />
      </TimelineContainer>
      <ComponentBorder className="left" style={{gridRow:'2/5', gridColumn: '1'}}></ComponentBorder>
      <ComponentBorder className="right"  style={{gridRow:'2/5', gridColumn: '5'}}></ComponentBorder>
      <ComponentBorder className="bottom" style={{gridRow:'5', gridColumn: '1/6'}}></ComponentBorder>
    </ModalContainer>
  )
}

export default MortgageActionsModal;