import React, { useEffect, useState } from "react";
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@emotion/react";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from '@mui/material/FormControlLabel';
import { selectTeamSecuritySettings } from "../../../../../slices/teamAndUserSettingsSlice";
import { selectEditSecuritySettingsPerm } from "../../../../../slices/userActionsPermissionsSlice";
import { useUpdateSecuritySettingMutation } from "../../../../../slices/api/apiSlice";
import { Button, ComponentHeader, Detail, DetailContainer, DetailInfoContainer, DetailListContainer, FlexWrapper, Typography } from "../../../../../StyledComponents";

const SecuritySettings = ({ userFullName, teamId }) => {
  const theme = useTheme()

  //TODO: Send Emails/in-app notoifications upon certain changes?

  const [isError, setIsError] = useState(false)
  const [noChanges, setNoChanges] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [require2FA, setRequire2FA] = useState(false)
  const [enforceIPWhitelist, setEnforceIPWhitelist] = useState(false)

  const securitySettings = useSelector(selectTeamSecuritySettings)
  const editSecuritySettingsPerm = useSelector(selectEditSecuritySettingsPerm)

  const [updateSecuritySetting] = useUpdateSecuritySettingMutation()

  const handleChange = async (event) => {
    setIsSuccess(false)
    setIsError(false)
    if (event.target.name === '2fa') {
      if (!require2FA === securitySettings.require2FA && enforceIPWhitelist === securitySettings.enforceIPWhitelist) {
        setNoChanges(true)
      } else {
        setNoChanges(false)
      }
      setRequire2FA(!require2FA)
    } else {
      if (require2FA === securitySettings.require2FA && !enforceIPWhitelist === securitySettings.enforceIPWhitelist) {
        setNoChanges(true)
      } else {
        setNoChanges(false)
      }
      setEnforceIPWhitelist(!enforceIPWhitelist)
    }
  }

  const handleSaveChanges = async () => {
    setIsFetching(true)
    let resp = await updateSecuritySetting({
      userFullName: userFullName,
      teamId: teamId,
      require2FA: require2FA,
      enforceIPWhitelist: enforceIPWhitelist,
    })
    if (resp.error) {
      setIsError(true)
    } else if (resp.data.message.includes("success")) {
      setIsSuccess(true)
    } else {
      setIsError(true)
    }
    setIsFetching(false)
  }

  useEffect(() => {
    setRequire2FA(securitySettings.require2FA)
    setEnforceIPWhitelist(securitySettings.enforceIPWhitelist)
    setNoChanges(true)
  }, [securitySettings])

  return (
    <FlexWrapper className="column">
      {isSuccess ?
        <ComponentHeader className="green">
          <CheckIcon style={{height: '1.9rem', width: '1.9rem', margin: '0 0.5rem 0.2rem 0'}} />
          Security Settings Updated
        </ComponentHeader>
      : isError ?
        <ComponentHeader className="red">
          <ErrorIcon style={{height: '1.9rem', width: '1.9rem', margin: '0 0.5rem 0.2rem 0'}} />
          Security Settings Update Failed
        </ComponentHeader>
      :
        <ComponentHeader>
          Security Settings
        </ComponentHeader>
      }
      <FlexWrapper className="justifyCenter" style={{padding: '2rem 0 4rem 0'}}>
        <DetailContainer style={{width: 'fit-content'}}>
          <DetailListContainer>
            <DetailInfoContainer className="item">
              <Detail className="thin" style={{height: '4rem', alignItems: 'center'}}>
                <Tooltip
                  componentsProps={{tooltip: {sx: {
                    color: `${theme.tooltip.info.color}`,
                    backgroundColor: `${theme.tooltip.info.background}`,
                    border: `0.3rem solid ${theme.tooltip.info.border}`,
                    borderBottom: `0.2rem solid ${theme.tooltip.info.border}`,
                    opacity: '0',
                  }}}}
                  PopperProps={{modifiers: [{
                    name: "offset",
                    options: {
                      offset: [0, -1],
                    },
                  }]}}
                  title={
                    <FlexWrapper>
                      <span style={{padding: '2rem', margin: '0', fontWeight: '600', textAlign: 'center'}}>
                        Two-Factor Authentication ensures that a user trying to access your application is who they say they are. Passwords are the first line of defense, but a Two-Factor method means compromised passwords will no longer allow unpermitted access.
                      </span>
                    </FlexWrapper>
                  }
                  placement="top"
                  arrow
                >
                  <InfoIcon style={{color: `${theme.icon.neutral.secondary}`, margin: '0 0.3rem 0.2rem 0'}} />
                </Tooltip>
                Require Two-Factor Authentication:
              </Detail>
              <Detail className="thin" style={{height: '4rem', alignItems: 'center'}}>
                <Tooltip
                  componentsProps={{tooltip: {sx: {
                    color: `${theme.tooltip.info.color}`,
                    backgroundColor: `${theme.tooltip.info.background}`,
                    border: `0.3rem solid ${theme.tooltip.info.border}`,
                    borderBottom: `0.2rem solid ${theme.tooltip.info.border}`,
                    opacity: '0',
                  }}}}
                  PopperProps={{modifiers: [{
                    name: "offset",
                    options: {
                      offset: [0, -7],
                    },
                  }]}}
                  title={
                    <FlexWrapper>
                      <span style={{padding: '2rem', margin: '0', fontWeight: '600', textAlign: 'center'}}>
                        Enforcing an IP whitelist means all of your users must verify the devices the access your application from. If a login attempt from an unlisted device is detected, an email will be sent to the corresponding user so that they can confirm they are trying to access the app. Once verified, the IP Address of the device will not need to be verified upon the next visit.
                      </span>
                    </FlexWrapper>
                  }
                  placement="top"
                  arrow
                >
                  <InfoIcon style={{color: `${theme.icon.neutral.secondary}`, margin: '0 0.3rem 0.2rem 0'}} />
                </Tooltip>
                Enforce IP Whitelists:
              </Detail>
            </DetailInfoContainer>
            <DetailInfoContainer style={{height: '8rem', justifyContent: 'space-around', paddingLeft: '0.5rem'}}>
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.error.color}`,
                  backgroundColor: `${theme.tooltip.error.background}`,
                  border: `0.3rem solid ${theme.tooltip.error.border}`,
                  borderBottom: `0.2rem solid ${theme.tooltip.error.border}`,
                  opacity: '0',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -7],
                  },
                }]}}
                title={!editSecuritySettingsPerm ?
                  <FlexWrapper className="alignCenter" style={{padding: '1rem'}}>
                    <DoDisturbIcon style={{color: `${theme.tooltip.error.border}`, height: '2rem', width: '2rem'}} />
                    <Typography style={{margin: '0 0 0 0.5rem', fontWeight: '600', textAlign: 'center'}}>
                      Upgraded permissions required
                    </Typography>
                  </FlexWrapper>
                :
                  ""
                }
                placement="top"
                arrow
              >
                <FormControlLabel 
                  control={<Switch disabled={!editSecuritySettingsPerm} checked={require2FA} />}
                  onChange={handleChange}
                  name="2fa"
                />
              </Tooltip>
              <Tooltip
                componentsProps={{tooltip: {sx: {
                  color: `${theme.tooltip.error.color}`,
                  backgroundColor: `${theme.tooltip.error.background}`,
                  border: `0.3rem solid ${theme.tooltip.error.border}`,
                  borderBottom: `0.2rem solid ${theme.tooltip.error.border}`,
                  opacity: '0',
                }}}}
                PopperProps={{modifiers: [{
                  name: "offset",
                  options: {
                    offset: [0, -7],
                  },
                }]}}
                title={!editSecuritySettingsPerm ?
                  <FlexWrapper className="alignCenter" style={{padding: '1rem'}}>
                    <DoDisturbIcon style={{color: `${theme.tooltip.error.border}`, height: '2rem', width: '2rem'}} />
                    <Typography style={{margin: '0 0 0 0.5rem', fontWeight: '600', textAlign: 'center'}}>
                      Upgraded permissions required
                    </Typography>
                  </FlexWrapper>
                :
                  ""
                }
                placement="top"
                arrow
              >
                <FormControlLabel 
                  control={<Switch disabled={!editSecuritySettingsPerm} checked={enforceIPWhitelist} />} 
                  onChange={handleChange} 
                  name="ipWhitelist" 
                />
              </Tooltip>
            </DetailInfoContainer>
          </DetailListContainer>
        </DetailContainer>
        <Tooltip
          componentsProps={{tooltip: {sx: {
            color: `${theme.tooltip.error.color}`,
            backgroundColor: `${theme.tooltip.error.background}`,
            border: `0.3rem solid ${theme.tooltip.error.border}`,
            borderBottom: `0.2rem solid ${theme.tooltip.error.border}`,
            opacity: '0',
          }}}}
          PopperProps={{modifiers: [{
            name: "offset",
            options: {
              offset: [0, -20],
            },
          }]}}
          title={noChanges ?
            <FlexWrapper className="alignCenter" style={{padding: '1rem'}}>
              <DoDisturbIcon style={{color: `${theme.tooltip.error.border}`, height: '2rem', width: '2rem'}} />
              <Typography style={{margin: '0 0 0 0.5rem', fontWeight: '600', textAlign: 'center'}}>
                No changes made
              </Typography>
            </FlexWrapper>
          :
            ""
          }
          placement="top"
          arrow
        >
          <FlexWrapper className="fitContentWidth">
            <Button 
              className={isFetching ? 'primaryLoading' : (!editSecuritySettingsPerm || noChanges || isError) ? "primaryDisabled" : "primary"}
              disabled={isFetching || !editSecuritySettingsPerm || noChanges || isError}
              onClick={() => handleSaveChanges()}
            >
              {isFetching ?
                <CircularProgress
                  sx={{ color: `${theme.button.text.primaryLoading}` }}
                  size={18}
                />
              :
                `save`
              }
            </Button>
          </FlexWrapper>
        </Tooltip>
      </FlexWrapper>
    </FlexWrapper>
  )
}

export default SecuritySettings