import React, { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from "@mui/icons-material/Error";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import AssigneeDropdown from "../../../DropDowns/AssigneeDropdown";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useAddAssigneesMutation } from "../../../../slices/api/apiSlice";
import { selectAddLeadAssigneesPerm } from "../../../../slices/userActionsPermissionsSlice";
import { SubHeader, Button, ButtonWrapper, FlexWrapper, ErrorWrapper, InputWrapper, ModalContentContainer, ComponentHeader, Typography, ActionIconWrapper } from "../../../../StyledComponents";

const AddAssignees = ({ teamMembersByName, handleCloseModal, activeLead, leadData, mortgageData, origin, userId, userFullName, leadUserNotifications }) => {
  const theme = useTheme()

  const addLeadAssigneesPerm = useSelector(selectAddLeadAssigneesPerm)

  const [isError, setIsError] = useState(false)
  const [leadAssigneeIds, setLeadAssigneeIds] = useState(leadData.assigneeIds)
  const [mortgageAssigneeIds, setMortgageAssigneeIds] = useState(mortgageData.assigneeIds)
  const [newLeadAssignees, setNewLeadAssignees] = useState('')
  const [newMortgageAssignees, setNewMortgageAssignees] = useState('')
  const [removedLeadAssignees, setRemovedLeadAssignees] = useState('')
  const [removedMortgageAssignees, setRemovedMortgageAssignees] = useState('')
  const [statusLeadEmpty, setStatusLeadEmpty] = useState(false)
  const [statusMortgageEmpty, setStatusMortgageEmpty] = useState(false)
  const [noneAdded, setNoneAdded] = useState(false)
  const [leadAssigneesAdded, setLeadAssigneesAdded] = useState(0)
  const [mortgageAssigneesAdded, setMortgageAssigneesAdded] = useState(0)
  const [leadAssigneesRemoved, setLeadAssigneesRemoved] = useState(0)
  const [mortgageAssigneesRemoved, setMortgageAssigneesRemoved] = useState(0)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [formActivated, setFormActivated] = useState(false)

  const[addAssignees] = useAddAssigneesMutation()

  const handleSetLeadAssignee = (event) => {
    setFormActivated(true)
    setLeadAssigneeIds(event.target.value)
    if (isSuccess) {
      setIsSuccess(false)
    }
    if (statusLeadEmpty) {
      setStatusLeadEmpty(false)
    }
    if (noneAdded) {
      setNoneAdded(false)
    }
  }
  const handleSetMortgageAssignee = (event) => {
    setFormActivated(true)
    setMortgageAssigneeIds(event.target.value)
    if (isSuccess) {
      setIsSuccess(false)
    }
    if (statusMortgageEmpty) {
      setStatusMortgageEmpty(false)
    }
    if (noneAdded) {
      setNoneAdded(false)
    }
  }

  const handleAddAssignees = async () => {
    setIsFetching(true)
    setIsSuccess(false)
    setIsError(false)
    let previousLeadAssignees = leadData.assigneeIds
    let newLeadAssignees = []
    let newLeadAssigneesBool = false
    let leadAssigneeNames = []
    let previousLeadAssigneeNames = []
    let previousLeadAssigneeIds = []
    let newLeadAssigneeNames = []
    let newLeadAssigneeIds = []
    let removedLeadAssigneesNames = []
    let removedLeadAssigneeIds = []
    let leadAssigneesRemoved = 0
    let leadAssigneesAdded = 0
    if (activeLead) {
      for (let i = 0; i < leadAssigneeIds.length; i++) {
        let assigneeMatch = previousLeadAssignees.find(assignee => assignee === leadAssigneeIds[i])
        if (!assigneeMatch) {
          newLeadAssignees.push(leadAssigneeIds[i])
          newLeadAssigneesBool = true
        } else {
          let assignee = teamMembersByName.find(assignee => assignee.memberId === leadAssigneeIds[i])
          leadAssigneeNames.push(assignee.fullName)
          let thisIndex = previousLeadAssignees.indexOf(assigneeMatch)
          let theseAssignees = [...previousLeadAssignees]
          previousLeadAssignees = theseAssignees.splice((thisIndex - 1), 1)
          previousLeadAssignees.splice(thisIndex, 1)
          previousLeadAssigneeNames.push(assignee.fullName)
          previousLeadAssigneeIds.push(assignee.memberId)
        }
      }
      for (let i = 0; i < previousLeadAssignees.length; i++) {
        let remainingAssignee = leadAssigneeIds.find(assignee => assignee === previousLeadAssignees[i])
        if (!remainingAssignee) {
          newLeadAssigneesBool = true
          let assignee = teamMembersByName.find(assignee => assignee.memberId === previousLeadAssignees[i])
          removedLeadAssigneesNames.push(assignee.fullName)
          removedLeadAssigneeIds.push(assignee.memberId)
          leadAssigneesRemoved++
        }
      }
    }
    let previousMortgageAssignees = mortgageData.assigneeIds
    let newMortgageAssignees = []
    let newMortgageAssigneesBool = false
    let mortgageAssigneeNames = []
    let previousMortgageAssigneeNames = []
    let previousMortgageAssigneeIds = []
    let newMortgageAssigneeNames = []
    let newMortgageAssigneeIds = []
    let removedMortgageAssigneesNames = []
    let removedMortgageAssigneeIds = []
    let mortgageAssigneesRemoved = 0
    let mortgageAssigneesAdded = 0
    for (let i = 0; i < mortgageAssigneeIds.length; i++) {
      let assigneeMatch = previousMortgageAssignees.find(assignee => assignee === mortgageAssigneeIds[i])
      if (!assigneeMatch) {
        newMortgageAssignees.push(mortgageAssigneeIds[i])
        newMortgageAssigneesBool = true
      } else {
        let assignee = teamMembersByName.find(assignee => assignee.memberId === mortgageAssigneeIds[i])
        mortgageAssigneeNames.push(assignee.fullName)
        let thisIndex = previousMortgageAssignees.indexOf(assigneeMatch)
        let theseAssignees = [...previousMortgageAssignees]
        previousMortgageAssignees = theseAssignees.splice((thisIndex - 1), 1)
        previousMortgageAssigneeNames.push(assignee.fullName)
        previousMortgageAssigneeIds.push(assignee.memberId)
      }
    }
    for (let i = 0; i < previousMortgageAssignees.length; i++) {
      let remainingAssignee = mortgageAssigneeIds.find(assignee => assignee === previousMortgageAssignees[i])
      if (!remainingAssignee) {
        newMortgageAssigneesBool = true
        let assignee = teamMembersByName.find(assignee => assignee.memberId === previousMortgageAssignees[i])
        removedMortgageAssigneesNames.push(assignee.fullName)
        removedMortgageAssigneeIds.push(assignee.memberId)
        mortgageAssigneesRemoved++
      }
    }
    if (!newLeadAssigneesBool && leadAssigneesRemoved === 0 && !newMortgageAssigneesBool && mortgageAssigneesRemoved === 0) {
      setNoneAdded(true)
    } else if (activeLead && leadAssigneeIds.length === 0 && leadAssigneesRemoved === 0 && (mortgageAssigneeIds.length === 0 && mortgageAssigneesRemoved === 0)) {
      if (activeLead && leadAssigneeIds.length === 0 && leadAssigneesRemoved === 0) {
        setStatusLeadEmpty(true)
      }
      if (mortgageAssigneeIds.length === 0 && mortgageAssigneesRemoved === 0) {
        setStatusMortgageEmpty(true)
      }
    } else {
      for (let i = 0; i < newLeadAssignees.length; i++) {
        let assignee = teamMembersByName.find(member => member.memberId === newLeadAssignees[i])
        leadAssigneesAdded++
        leadAssigneeNames.push(assignee.fullName)
        newLeadAssigneeNames.push(assignee.fullName)
        newLeadAssigneeIds.push(assignee.memberId)
      }
      for (let i = 0; i < newMortgageAssignees.length; i++) {
        let assignee = teamMembersByName.find(member => member.memberId === newMortgageAssignees[i])
        mortgageAssigneesAdded++
        mortgageAssigneeNames.push(assignee.fullName)
        newMortgageAssigneeNames.push(assignee.fullName)
        newMortgageAssigneeIds.push(assignee.memberId)
      }

      await addAssignees({
        userId: userId,
        leadId: leadData._id,
        mortgageId: mortgageData._id,
        userFullName: userFullName,
        userNotifications: leadUserNotifications,
        leadAssigneeIds: leadAssigneeIds,
        newLeadAssigneeNames: newLeadAssigneeNames,
        removedLeadAssigneesNames: removedLeadAssigneesNames,
        previousLeadAssigneeNames: previousLeadAssigneeNames,
        leadAssigneeNames: leadAssigneeNames,
        newLeadAssigneeIds: newLeadAssigneeIds,
        removedLeadAssigneeIds: removedLeadAssigneeIds,
        previousLeadAssigneeIds: previousLeadAssigneeIds,
        mortgageAssigneeIds: mortgageAssigneeIds,
        newMortgageAssigneeNames: newMortgageAssigneeNames,
        removedMortgageAssigneesNames: removedMortgageAssigneesNames,
        previousMortgageAssigneeNames: previousMortgageAssigneeNames,
        mortgageAssigneeNames: mortgageAssigneeNames,
        newMortgageAssigneeIds: newMortgageAssigneeIds,
        removedMortgageAssigneeIds: removedMortgageAssigneeIds,
        previousMortgageAssigneeIds: previousMortgageAssigneeIds,
        newMortgageAssigneesBool: newMortgageAssigneesBool,
        newLeadAssigneesBool: newLeadAssigneesBool,
      })
      setIsSuccess(true)
      setNewLeadAssignees(newLeadAssigneeNames)
      setRemovedLeadAssignees(removedLeadAssigneesNames)
      setLeadAssigneesAdded(leadAssigneesAdded)
      setLeadAssigneesRemoved(leadAssigneesRemoved)
      setNewMortgageAssignees(newMortgageAssigneeNames)
      setRemovedMortgageAssignees(removedMortgageAssigneesNames)
      setMortgageAssigneesAdded(mortgageAssigneesAdded)
      setMortgageAssigneesRemoved(mortgageAssigneesRemoved)
    }
    setIsFetching(false)
  }

  return (
    <>
      {isSuccess ?
        <ComponentHeader className="modal green"><CheckIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Success</ComponentHeader>
      : isError ?
        <ComponentHeader className="modal red"><ErrorIcon style={{height: '2.5rem', width: '2.5rem', margin: '0 0.5rem 0.2rem 0'}} />Something went wrong. Please try again or contact support@lancastersweep.com</ComponentHeader>
      :
        <ComponentHeader className="modal">Manage Assignees</ComponentHeader>
      }

      <ModalContentContainer className="centerColumn leadActions">
        {isSuccess ?
          <SubHeader className="modal" style={{margin: '0 auto'}}>
            <div style={{display: 'flex'}}>
              {newLeadAssignees ?
                newLeadAssignees.map((user, index) => (
                  index === 0 ?
                    <Typography key={user} style={{margin: 'auto', fontWeight: '600'}}>{user}</Typography>
                  : index === 1 && index === (newLeadAssignees.length - 1) ?
                    <Typography key={user} style={{marginLeft: '0.45rem'}}>and <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : index !== (newLeadAssignees.length - 1) ?
                    <Typography key={user}>, <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : index === (newLeadAssignees - 1) ?
                    <Typography key={user}>, and <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : 
                    <Typography key={user}></Typography>
                ))
              :
                ``
              }
            </div>
            {leadAssigneesAdded === 1 ?
                <Typography> has been assigned to the lead.</Typography>
              : leadAssigneesAdded > 1 ?
                <Typography> have been assigned to the lead.</Typography>
              : 
                <></>
            }
            <div style={{display: 'flex'}}>
              {newMortgageAssignees ?
                newMortgageAssignees.map((user, index) => (
                  index === 0 ?
                    <Typography key={user} style={{margin: 'auto', fontWeight: '600'}}>{user}</Typography>
                  : index === 1 && index === (newMortgageAssignees.length - 1) ?
                    <Typography key={user} style={{marginLeft: '0.45rem'}}>and <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : index !== (newMortgageAssignees.length - 1) ?
                    <Typography key={user}>, <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : index === (newMortgageAssignees - 1) ?
                    <Typography key={user}>, and <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : 
                    <Typography key={user}></Typography>
                ))
              :
                ``
              }
            </div>
            {mortgageAssigneesAdded === 1 ?
                <Typography> has been assigned to the mortgage.</Typography>
              : mortgageAssigneesAdded > 1 ?
                <Typography> have been assigned to the mortgage.</Typography>
              : 
                <></>
            }
            <div style={{display: 'flex'}}>
              {removedLeadAssignees ? 
                removedLeadAssignees.map((user, index) => (
                  index === 0 ?
                    <Typography key={user} style={{margin: 'auto', fontWeight: '600'}}>{user}</Typography>
                  : index === 1 && index === (removedLeadAssignees.length - 1) ?
                    <Typography key={user} style={{marginLeft: '0.45rem'}}>and <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : index !== (removedLeadAssignees.length - 1) ?
                    <Typography key={user}>, <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : index === (removedLeadAssignees.length - 1) ?
                    <Typography key={user}>, and <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : 
                    <Typography key={user}></Typography>
                ))
              :
                ``
              }
            </div>
            {leadAssigneesRemoved === 1 ?
              <Typography> has been removed from the lead.</Typography>
            : leadAssigneesRemoved > 1 ?
              <Typography> have been removed from the lead.</Typography>
            : 
              <></>
            }
            <div style={{display: 'flex'}}>
              {removedMortgageAssignees ?
                removedMortgageAssignees.map((user, index) => (
                  index === 0 ?
                    <Typography key={user} style={{margin: 'auto', fontWeight: '600'}}>{user}</Typography>
                  : index === 1 && index === (removedMortgageAssignees.length - 1) ?
                    <Typography key={user} style={{marginLeft: '0.45rem'}}>and <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : index !== (removedMortgageAssignees.length - 1) ?
                    <Typography key={user}>, <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : index === (removedMortgageAssignees.length - 1) ?
                    <Typography key={user}>, and <span style={{marginLeft: '0.45rem', fontWeight: '600'}}>{user}</span></Typography>
                  : 
                    <Typography key={user}></Typography>
                ))
              :
                ``
              }
            </div>
            {mortgageAssigneesRemoved === 1 ?
              <Typography> has been removed from the mortgage.</Typography>
            : mortgageAssigneesRemoved > 1 ?
              <Typography> have been removed from the mortgage.</Typography>
            : 
              <></>
            }
          </SubHeader>
        : activeLead ?
          <FlexWrapper className="column jusitfyCenter alignCenter" style={{height: '12rem'}}>
            {(origin === 'mortgage' && !isSuccess) &&
              <ActionIconWrapper className="wrapped caution" style={{padding: '1rem', marginBottom: '1.5rem'}}>
                <ReportProblemIcon style={{marginRight: '0.5rem'}} />
                This mortgage is currently under investigation.
              </ActionIconWrapper>
            }
            <Typography className="small fitContentWidth">
              Select one or more team members to assign.
            </Typography>
            <Typography className="small fitContentWidth">
              Team members assigned to the lead will receive all updates regarding the investigation.
            </Typography>
            <Typography className="small fitContentWidth alignTextCenter">
              Those only assigned to the mortgage will be notfied when the investigation<br/>is closed or when the mortgage's internal records are updated.
            </Typography>
          </FlexWrapper>
        :
          <FlexWrapper className="column jusitfyCenter alignCenter" style={{height: '12rem'}}>
            <Typography className="small fitContentWidth">
              Select one or more team members to assign to the mortgage.
            </Typography>
            <Typography className="small fitContentWidth alignTextCenter">
              Assignees are notified when changes to the public records are detected, or <br/>
              when the mortgage's internal records are updated.
            </Typography>
          </FlexWrapper>
        }
        <FlexWrapper className="justifyCenter alignCenter" style={{height: '7rem'}}>
          {noneAdded ?
            <ErrorWrapper>
              <ErrorIcon />
              No changes were made.
            </ErrorWrapper>
          : statusLeadEmpty ?
            <ErrorWrapper>
              <ErrorIcon />
              Select an assignee
            </ErrorWrapper>
          : statusMortgageEmpty ?
            <ErrorWrapper>
              <ErrorIcon />
              Select an assignee
            </ErrorWrapper>
          :
            <></>
          }
        </FlexWrapper>
        {activeLead ?
          <>
            <InputWrapper style={{width: '45rem', margin: '1rem auto 4rem auto'}}>
              <FlexWrapper>
                <Typography>Assigned to Lead</Typography>
              </FlexWrapper>
              <AssigneeDropdown
                value={leadAssigneeIds}
                handleChange={handleSetLeadAssignee}
                items={teamMembersByName}
              />
            </InputWrapper>
            <InputWrapper style={{width: '45rem', margin: '0 auto 2rem auto'}}>
              <FlexWrapper>
                <Typography>Assigned to Mortgage</Typography>
              </FlexWrapper>
              <AssigneeDropdown
                value={mortgageAssigneeIds}
                handleChange={handleSetMortgageAssignee}
                items={teamMembersByName}
              />
            </InputWrapper>
          </>
        :
          <InputWrapper style={{width: '45rem', margin: '1rem auto 2rem auto'}}>
            <FlexWrapper>
              <Typography>Assigned to Mortgage</Typography>
            </FlexWrapper>
            <AssigneeDropdown
              value={mortgageAssigneeIds}
              handleChange={handleSetMortgageAssignee}
              items={teamMembersByName}
            />
          </InputWrapper>
        }
        <ButtonWrapper className="justifyCenter" style={{marginTop: '4rem'}}>
          <Button 
            style={{width: '16rem'}} 
            className={isFetching ? 'primaryLoading' : (!addLeadAssigneesPerm || isSuccess || !formActivated) ? 'primaryDisabled' : 'primary'}
            disabled={!addLeadAssigneesPerm || isFetching || isSuccess}
            onClick={(addLeadAssigneesPerm && !isFetching && formActivated && !isSuccess) ? () => handleAddAssignees() : null}
          >
            {isFetching ?
              <CircularProgress
                sx={{ color: `${theme.palette.common.white}` }}
                size={18}
              />
            :
              `Confirm`
            }
          </Button>
          <Button 
            className={isFetching ? 'neutralLoading' : 'neutral'}
            disabled={isFetching}
            onClick={isFetching ? null : () => handleCloseModal()}
          >
            Close
          </Button>
        </ButtonWrapper>
      </ModalContentContainer>
    </>
  )
}

export default AddAssignees;