import React , { useState } from "react";
import { useTheme } from "@mui/styles";
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MuiBadge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import ErrorIcon from '@mui/icons-material/Error';
import GroupsIcon from '@mui/icons-material/Groups';
import AdjustIcon from '@mui/icons-material/Adjust';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CircularProgress from "@mui/material/CircularProgress";
import ChangeTargetOutcome from "../../../../../../components/Modals/LeadActions/Components/ChangeTargetOutcome.js";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { setTeamLeadDsiableScan } from "../../../../../../slices/teamActiveLeadsSlice";
import { useDispatch, useSelector } from "react-redux";
import { setMemberLeadDsiableScan } from "../../../../../../slices/memberAssignmentsSlice";
import { setMembersArrayDsiableScan } from "../../../../../../slices/membersDataArraySlice";
import { selectDesignateLeadAwaitingUpdatePerm, selectResolveAllMortgageDiscrepanciesPerm, selectRunSingleScanPerm } from "../../../../../../slices/userActionsPermissionsSlice";
import { ActionMenuItem, ActionMenuTitle, CardButton, Badge, NewLabel, AddButton, CardIndex, FlexWrapper, Typography } from "../../../../../../StyledComponents";
import { useVerifyPublicRecordsMutation, useRecordSweepMutation, useResolveAllLeadDiscrepanciesMutation, useContinueAwaitingUpdateMutation } from "../../../../../../slices/api/apiSlice";
import { selectRunningSweep, setRunningSweep } from "../../../../../../slices/sessionDataSlice.js";

const AwaitingUpdateCard = ({ lead, userId, userFullName, dashPerspective, handleOpenLeadActionsModal, cardIndex, teamId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const resolveAllMortgageDiscrepanciesPerm = useSelector(selectResolveAllMortgageDiscrepanciesPerm)
  const designateLeadAwaitingUpdatePerm = useSelector(selectDesignateLeadAwaitingUpdatePerm)

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.common.error}`,
      fontWeight: '800',
      fontSize: "1rem",
      height: "1.4rem",
      padding: "0 0.5rem",
      borderRadius: "1rem",
      minWidth: "1.2rem",
    }
  }

  const [recordSweep, {isFetching: sweepIsFetching, isSuccess: sweepIsSuccess, isError: sweepIsError}] = useRecordSweepMutation()
  const [verifyPublicRecords, {isFetching: confirmIsFetching, isSuccess: confirmIsSuccess, isError: confirmIsError}] = useVerifyPublicRecordsMutation()
  const [resolveAllDiscrepancies, {isFetching: resolveIsFetching, isSuccess: resolveIsSuccess, isError: resolveIsError}] = useResolveAllLeadDiscrepanciesMutation()
  const [continueAwaitingUpdate, {isFetching: continueWaitingIsFetching, isSuccess: continueWaitingIsSuccess, isError: continueWaitingIsError}] = useContinueAwaitingUpdateMutation()

  const runningSweep = useSelector(selectRunningSweep)
  const runSingleScanPerm = useSelector(selectRunSingleScanPerm)

  const [changeOutcomeDetails, setChangeOutcomeDetails] = useState({})
  const [resolveError, setResolveError] = useState(false)
  const [menuActive, setMenuActive] = useState(false)
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const openActionsMenu = Boolean(actionMenuAnchorEl);
  const [outcomeMenuAnchorEl, setOutcomeMenuAnchorEl] = useState(null);
  const openOutcomeMenu = Boolean(outcomeMenuAnchorEl);

  const handleOpenActions = (event) => {
    setActionMenuAnchorEl(event.currentTarget);
    setMenuActive(true);
  };
  const handleCloseActions = () => {
    setActionMenuAnchorEl(null);
    setMenuActive(false);
    setOutcomeMenuAnchorEl(null)
  };

  const handleOpenOutcomeMenu = (event, leadId, timeline, oldOutcome, userNotifications) => {
    let newOutcome = ''
    if (oldOutcome === 'renegotiation') {
      newOutcome = 'refinance'
    } else {
      newOutcome = 'renegotiation'
    }
    setChangeOutcomeDetails({
      leadId: leadId,
      timeline: timeline,
      newOutcome: newOutcome,
      userNotifications: userNotifications,
    })
    setOutcomeMenuAnchorEl(event.currentTarget);
  }
  const handleCloseOutcomeMenu = () => {
    setOutcomeMenuAnchorEl(null)
    setActionMenuAnchorEl(null)
    setMenuActive(false)
  };

  const handleConfirmRecords = async (clearDiscrepancies) => {
    // if (clearDiscrepancies) {
    //   let resolve = await resolveAllDiscrepancies({
    //     userId: userId,
    //     userFullName: userFullName,
    //     teamId: teamId,
    //     dashPerspective: dashPerspective,
    //     mortgageId: lead.belongsToMortgage._id,
    //     mission: 'confirmUpdate'
    //   })
    //   if (resolve.data.message.includes('resolved')) {
    //     await verifyPublicRecords({
    //       updateReport: false,
    //       timeline: resolve.data.data.newLeads[0].timeline,
    //       userFullName: userFullName,
    //       teamId: teamId,
    //       leadId: lead._id,
    //       dashPerspective: dashPerspective,
    //       mortgageId: lead.belongsToMortgage._id,
    //     })
    //     setResolveError(false)
    //   } else {
    //     setResolveError(true)
    //   }
    // } else {
      await verifyPublicRecords({
        updateReport: true,
        timeline: lead.timeline,
        userFullName: userFullName,
        teamId: teamId,
        leadId: lead._id,
        dashPerspective: dashPerspective,
        mortgageId: lead.belongsToMortgage._id,
      })
    // }
  }

  const handleContinueAwaitingUpdate = async () => {
    await continueAwaitingUpdate({
      userId: userId,
      timeline: lead.timeline,
      userFullName: userFullName,
      teamId: teamId,
      leadId: lead._id,
      dashPerspective: dashPerspective,
      mortgageId: lead.belongsToMortgage._id,
    })
  }
  const handleRunScan = async () => {
    dispatch( setRunningSweep(true) )
    let sweepResult = await recordSweep({
      teamId: teamId,
      userId: userId,
      userFullName: userFullName,
      mortgageId: lead.belongsToMortgage._id,
      querySettings: {
        sweepType: 'Check All Discrepancies',
        sweepSize: '1',
        existingScans: 'Any',
        createLead: 'true',
        recordNewMonthlyStats: 'false',
        recordPerformance: 'true',
        notifyAssignees: 'true',
        matchPublicRecordsAll: 'false',
        updateTeamStats: 'false',
        updateLastQuery: 'false',
      }
    })
    if (!sweepResult.data.data.publicRecordsUpdated) {
      dispatch( setTeamLeadDsiableScan({leadId: lead._id}) )
      dispatch( setMemberLeadDsiableScan({leadId: lead._id}) )
      dispatch( setMembersArrayDsiableScan({assigneeIds: lead.assigneeIds, leadId: lead._id}) )
    }
    dispatch( setRunningSweep(false) )
  }

  return (
    <>
      <CardIndex>
        {cardIndex+1}
      </CardIndex>
      <div style={{display: 'flex', justifyContent: 'space-between', width: '6rem', margin: '0 0 0 0.5rem'}}>
        {lead.tier === 0 ?
          <CardButton
            className="tier manualInvestigation"
            variant="contained"
            disableElevation
          >
            <span style={{transform: "rotate(-90deg)", display: "flex", textWrap: 'nowrap', fontSize: '1.2rem'}}>
              Manual Investigation
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>        
        : lead.tier === 1 ?
          <CardButton
            className="tier one"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="one">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>1</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        : lead.tier === 2 ?
          <CardButton
            className="tier two"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="two">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>2</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        :
          <CardButton
            className="tier three"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="three">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>3</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        }
        <CardButton
          variant="contained"
          disableElevation
          onClick={handleOpenActions}
          className={`${menuActive ? "active" : "actions"}`}
        >
          <span style={{transform: "rotate(-90deg)", display: "flex"}}>
            ACTIONS
            {lead.userNotifications > 0 ?
              <MuiBadge 
                badgeContent={lead.userNotifications} 
                color="error" 
                sx={badgeStyle}   
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <KeyboardArrowDownIcon style={{padding: "0 0 0.3rem 0"}} />
              </MuiBadge>
            :
              <KeyboardArrowDownIcon style={{padding: "0 0 0.3rem 0"}} />
            }
          </span>
        </CardButton>
        <StyledMenu
          anchorEl={actionMenuAnchorEl}
          open={openActionsMenu}
          onClose={handleCloseActions}
        >
          {lead.belongsToMortgage.reports.length === 0 ?
            <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius" style={{background: `${theme.palette.common.disabledGray}`}}>
              <ActionMenuItem className="noHover topRadius">
                <SummarizeIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Reports ({lead.belongsToMortgage.reports.length})</ActionMenuTitle>
              </ActionMenuItem>
            </MenuItem>
          :
            <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius">
              <ActionMenuItem onClick={() => handleOpenLeadActionsModal('reports', lead, lead.userNotifications, lead.belongsToMortgage)}>
                <SummarizeIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Reports ({lead.belongsToMortgage.reports.length})</ActionMenuTitle>
              </ActionMenuItem>
            </MenuItem>
          }
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('notes', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <EditNoteIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Notes ({lead.belongsToMortgage.mortgageNotes.length})</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={(e) => handleOpenOutcomeMenu(e, lead._id, lead.timeline, lead.targetOutcome, lead.userNotifications)} disableRipple className="border">
            <ActionMenuItem>
              <FlagCircleIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              {lead.targetOutcome === 'renegotiation' ?
                <ActionMenuTitle>Switch to Refinance</ActionMenuTitle>
              :
                <ActionMenuTitle>Switch to Renegotiation</ActionMenuTitle>
              }
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <FileCopyIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Compare Records</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('propertyDetails', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <HomeWorkIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Property Details</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('ownerDetails', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <GroupsIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Owner Details</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('editTargets', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <AdjustIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Change Targets</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('addAssignee', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <PersonAddIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Manage Assignees</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions}  className="button buttonTop" disableRipple style={{cursor: "default"}}>
            <ActionMenuItem 
              className="advance" 
              style={{width: '14rem', margin: '0'}} 
              onClick={() => handleOpenLeadActionsModal('finalizeLead', lead, lead.userNotifications, lead.belongsToMortgage)}
            >
              <ActionMenuTitle className="white">Finalize</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} className="button bottomRadius" disableRipple style={{cursor: "default"}}>
            <ActionMenuItem 
              className="dismiss" 
              style={{width: '14rem', margin: '0'}} 
              onClick={() => handleOpenLeadActionsModal('dismissLead', lead, lead.userNotifications, lead.belongsToMortgage)}
            >              
              <ActionMenuTitle className="white">Dismiss</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
        </StyledMenu>
        <StyledMenu
          anchorEl={outcomeMenuAnchorEl}
          open={openOutcomeMenu}
          onClose={handleCloseOutcomeMenu}
        >
          <ChangeTargetOutcome userId={userId} userFullName={userFullName} changeOutcomeDetails={changeOutcomeDetails} dashPerspective={dashPerspective} />
        </StyledMenu>
      </div>

      <FlexWrapper className="column">
        <FlexWrapper>
          {confirmIsFetching || resolveIsFetching || sweepIsFetching || continueWaitingIsFetching || runningSweep ?
            <FlexWrapper className="justifyCenter" style={{maxWidth: '26rem', minWidth: '26rem', height: '100%'}}>
              <CircularProgress 
                sx={{ color: `${theme.palette.primary.main}`, margin: 'auto' }}
                size={24}
              /> 
            </FlexWrapper>
          : confirmIsError || resolveIsError || resolveError || sweepIsError || continueWaitingIsError ?
            <FlexWrapper className="justifyCenter" style={{maxWidth: '26rem', minWidth: '26rem', height: '100%'}}>
              <div style={{textAlign: 'center'}}>
                <FlexWrapper className="alignCenter justifyCenter" style={{height: 'fit-content', marginTop: '1.5rem'}}>
                  <ErrorIcon style={{color: `${theme.palette.common.redDark}`, width: '2rem', height: '2rem'}} />
                  <Typography className="large error">Something went wrong.</Typography>
                </FlexWrapper>
                <Typography className="medium error">If the proplem continues, contact</Typography>
                <Typography className="medium error">solutions@lancastersweep.com</Typography>
              </div>
            </FlexWrapper>
          : !lead.publicRecordsUpdated && lead.awaitingUpdates ?
            <FlexWrapper className="justifyCenter" style={{maxWidth: '26rem', minWidth: '26rem', height: '100%', padding: '0 1rem'}}>
              <FlexWrapper className='column justifyStart alignCenter'> 
                <FlexWrapper style={{margin: '1rem 0'}}>
                  <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                    <Typography className="tinyHeight tiny">Active Discrepancies:</Typography>
                    <Typography className="tinyHeight tiny">Inactive Discrepancies:</Typography>
                    <Typography className="tinyHeight tiny">Last Scan:</Typography>
                  </FlexWrapper>
                  <FlexWrapper className="column" style={{paddingLeft: '0.5rem'}}>
                    {lead.activeDiscrepancies > 0 ?
                      <Typography className="bold tiny">
                        {lead.activeDiscrepancies}
                      </Typography>
                    :
                      <Typography className="tiny accent">-none-</Typography>
                    }
                    {lead.rejectedDiscrepancies === 0 && lead.resolvedDiscrepancies === 0 ?
                      <Typography className="tiny accent">-none-</Typography>
                    : lead.rejectedDiscrepancies === 0 && lead.resolvedDiscrepancies > 0 ?
                      <Typography className="bold tiny">
                        {lead.resolvedDiscrepancies} resolved
                      </Typography>
                    : lead.rejectedDiscrepancies > 0 && lead.resolvedDiscrepancies === 0 ?
                      <Typography className="bold tiny">
                        {lead.rejectedDiscrepancies} rejected
                      </Typography>
                    :
                      <Typography className="bold tiny">
                        {lead.resolvedDiscrepancies} resolved, {lead.rejectedDiscrepancies} rejected
                      </Typography>
                    }
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.lastUpdateDate}
                    </Typography>
                  </FlexWrapper>
                </FlexWrapper> 
                <FlexWrapper className="alignCenter" style={{width: 'fit-content'}}>
                  <Tooltip
                    componentsProps={{tooltip: {sx: {
                      color: `${theme.palette.common.black}`,
                      backgroundColor: `${theme.palette.common.offWhite}`,
                      border: `0.3rem solid ${theme.palette.common.grayText}`,
                      borderRight: `0.2rem solid ${theme.palette.common.grayText}`,
                      opacity: '0',
                    }}}}
                    PopperProps={{modifiers: [{
                      name: "offset",
                      options: {
                        offset: [0, -7],
                      },
                    }]}}
                    title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>{lead.disableScan ? `scan performed` : runSingleScanPerm ? `Run Scan` : 'disabled'}</span></div>} 
                    placement="left"
                    arrow
                  >
                    {lead.disableScan ?
                      <CardButton className="iconButton primaryDisabled">
                        <PendingIcon style={{height: '3rem', width: '3rem'}} />
                      </CardButton>
                    :
                      <CardButton onClick={runSingleScanPerm ? () => handleRunScan() : null} className={runSingleScanPerm ? "iconButton primary cursorResize" : "iconButton primaryDisabled cursorResize"}>
                        <PendingIcon style={{height: '3rem', width: '3rem'}} />
                      </CardButton>
                    }
                  </Tooltip>
                  <Typography className="tiny accent" style={{margin: 'auto 0 auto 0.3rem'}}>no updates were identified</Typography>
                </FlexWrapper>               
              </FlexWrapper>
            </FlexWrapper>  
          :
            <FlexWrapper className="justifyCenter alignCenter" style={{maxWidth: '26rem', minWidth: '26rem', height: '100%'}}>
              <FlexWrapper className="column" style={{paddingTop: '0.5rem'}}>
                {lead.publicRecordsUpdated &&
                  <NewLabel className="leadCard" style={{width: 'fit-content', padding: '0.2rem 1rem'}}>Public Records updated!</NewLabel>
                }
                <FlexWrapper>
                  <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                    <Typography className="tinyHeight tiny">Last Scan:</Typography>
                  </FlexWrapper>
                  <FlexWrapper className="column" style={{paddingLeft: '0.5rem'}}>
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.lastUpdateDate}
                    </Typography>
                  </FlexWrapper>
                </FlexWrapper> 
                {lead.activeDiscrepancies > 0 ?
                  <Typography className="tiny">
                    There are still {lead.activeDiscrepancies} active discrepancies
                  </Typography>
                :
                  <Typography className="bold tiny">
                    no active discrepancies
                  </Typography>
                }
                <FlexWrapper className="column alignCenter" style={{margin: 'auto', height: 'fit-content'}}>
                  <FlexWrapper className="alignCenter" style={{margin: '0 auto', width: 'fit-content'}}>
                    <Tooltip
                      componentsProps={{tooltip: {sx: {
                        color: `${theme.palette.primary.pastelDark}`,
                        backgroundColor: `${theme.palette.primary.pastelLight}`,
                        border: `0.3rem solid ${theme.palette.common.grayTextLight}`,
                        borderRight: `0.2rem solid ${theme.palette.common.grayTextLight}`,
                        opacity: '0',
                      }}}}
                      PopperProps={{modifiers: [{
                        name: "offset",
                        options: {
                          offset: [0, -7],
                        },
                      }]}}
                      title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>verify records</span></div>} 
                      placement="left"
                      arrow
                    >
                      <CardButton onClick={() => handleConfirmRecords(false)} className="iconButton primary">
                        <TaskAltIcon style={{height: '3rem', width: '3rem'}} />
                      </CardButton>
                    </Tooltip>
                    <Typography className="bold tiny" style={{marginLeft: '1rem'}}>
                      Verify the Public Records reflect<br/> the expected arrangements
                    </Typography>
                  </FlexWrapper>
                  <Typography className="accent" style={{paddingLeft: '1rem'}}>-or-</Typography>
                  <FlexWrapper className="alignCenter" style={{margin: '0 auto', width: 'fit-content'}}>
                    <Tooltip
                      componentsProps={{tooltip: {sx: {
                        color: `${theme.palette.common.grayText}`,
                        backgroundColor: `${theme.palette.common.grayHover}`,
                        border: `0.3rem solid ${theme.palette.common.grayText}`,
                        borderRight: `0.2rem solid ${theme.palette.common.grayText}`,
                        opacity: '0',
                      }}}}
                      PopperProps={{modifiers: [{
                        name: "offset",
                        options: {
                          offset: [0, -7],
                        },
                      }]}}
                      title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>await update</span></div>} 
                      placement="left"
                      arrow
                    >
                      <CardButton onClick={designateLeadAwaitingUpdatePerm ? () => handleContinueAwaitingUpdate() : null} className={designateLeadAwaitingUpdatePerm ? "iconButton cancel" : "iconButton primaryDisabled cancel"}>
                        <PendingIcon style={{height: '3rem', width: '3rem'}} />
                      </CardButton>
                    </Tooltip>
                    <Typography className="bold tiny" style={{marginLeft: '1rem'}}>
                      Confirm further updates are required
                    </Typography>
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
          // : !lead.awaitingUpdates && lead.activeDiscrepancies === 0 ?
          // : lead.publicRecordsUpdated && lead.awaitingUpdates && lead.activeDiscrepancies === 0 ?

          // : lead.publicRecordsUpdated && lead.activeDiscrepancies !== 0 ?
          //   <FlexWrapper className="justifyCenter" style={{maxWidth: '25rem', minWidth: '25rem', height: '100%'}}>
          //     <FlexWrapper className="column" style={{paddingTop: '0.5rem'}}>
          //       <NewLabel className="leadCard" style={{width: 'fit-content', padding: '0.2rem 1rem'}}>Public Records updated!</NewLabel>
          //       <DetailListContainer style={{margin: '0.3rem 0 0.5rem 0'}}>
          //         <DetailInfoContainer className="item">
          //           <Detail>Last Scan:</Detail>
          //         </DetailInfoContainer>
          //         <DetailInfoContainer>
          //           <Detail className="item">{lead.belongsToMortgage.lastUpdateDate}</Detail>
          //         </DetailInfoContainer>
          //       </DetailListContainer> 

          //       <FlexWrapper className="column alignCenter" style={{margin: 'auto', height: 'fit-content'}}>
          //         <FlexWrapper className="alignCenter" style={{margin: '0 auto', width: 'fit-content'}}>
          //           <Tooltip
          //             componentsProps={{tooltip: {sx: {
          //               color: `${theme.palette.primary.pastelDark}`,
          //               backgroundColor: `${theme.palette.primary.pastelLight}`,
          //               border: `0.3rem solid ${theme.palette.common.grayTextLight}`,
          //               borderRight: `0.2rem solid ${theme.palette.common.grayTextLight}`,
          //               opacity: '0',
          //             }}}}
          //             PopperProps={{modifiers: [{
          //               name: "offset",
          //               options: {
          //                 offset: [0, -7],
          //               },
          //             }]}}
          //             title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>verify records</span></div>} 
          //             placement="left"
          //             arrow
          //           >
          //             <CardButton onClick={() => handleConfirmRecords(false)} className="iconButton primary">
          //               <TaskAltIcon style={{height: '2.5rem', width: '2.5rem'}} />
          //             </CardButton>
          //           </Tooltip>
          //           <span style={{marginLeft: '1rem'}}>Verify the Public Records reflect<br/> the expected arrangements</span>
          //         </FlexWrapper>
          //         <AccentInfo style={{paddingLeft: '1rem'}}>-or-</AccentInfo>
          //         <FlexWrapper className="alignCenter" style={{margin: '0 auto', width: 'fit-content'}}>
          //           <Tooltip
          //             componentsProps={{tooltip: {sx: {
          //               color: `${theme.palette.common.grayText}`,
          //               backgroundColor: `${theme.palette.common.grayHover}`,
          //               border: `0.3rem solid ${theme.palette.common.grayText}`,
          //               borderRight: `0.2rem solid ${theme.palette.common.grayText}`,
          //               opacity: '0',
          //             }}}}
          //             PopperProps={{modifiers: [{
          //               name: "offset",
          //               options: {
          //                 offset: [0, -7],
          //               },
          //             }]}}
          //             title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>await update</span></div>} 
          //             placement="left"
          //             arrow
          //           >
          //             <CardButton onClick={() => handleContinueAwaitingUpdate()} className="iconButton cancel">
          //               <PendingIcon style={{height: '2.5rem', width: '2.5rem'}} />
          //             </CardButton>
          //           </Tooltip>
          //           <span style={{marginLeft: '1rem'}}>Confirm further updates are required</span>
          //         </FlexWrapper>
          //       </FlexWrapper>
          //     </FlexWrapper>
          //   </FlexWrapper>
          // :
          //   <FlexWrapper className="justifyCenter" style={{maxWidth: '25rem', minWidth: '25rem', height: '100%'}}>
          //     <FlexWrapper className='column justifyStart alignCenter'> 
          //       {lead.publicRecordsUpdated &&
          //         <NewLabel className="leadCard" style={{width: 'fit-content', padding: '0.2rem 1rem'}}>Public Records updated!</NewLabel>
          //       }
          //       <DetailListContainer style={{margin: '1rem 0'}}>
          //         <DetailInfoContainer className="item">
          //           <Detail>Active Discrepancies:</Detail>
          //           <Detail>Inactive Discrepancies:</Detail>
          //           <Detail>Last Scan:</Detail>
          //         </DetailInfoContainer>
          //         <DetailInfoContainer>
          //           <Detail className="item">{lead.activeDiscrepancies}</Detail>
          //           {lead.rejectedDiscrepancies === 0 && lead.resolvedDiscrepancies === 0 ?
          //             <span style={{color: `${theme.palette.common.grayText}`}}>none</span>
          //           : lead.rejectedDiscrepancies === 0 && lead.resolvedDiscrepancies > 0 ?
          //             <Detail className="item">{lead.resolvedDiscrepancies} resolved</Detail>
          //           : lead.rejectedDiscrepancies > 0 && lead.resolvedDiscrepancies === 0 ?
          //             <Detail className="item">{lead.rejectedDiscrepancies} rejected</Detail>
          //           :
          //             <Detail className="item">{lead.resolvedDiscrepancies} resolved, {lead.rejectedDiscrepancies} rejected</Detail>
          //           }
          //           <Detail className="item">{lead.belongsToMortgage.lastUpdateDate}</Detail>
          //         </DetailInfoContainer>
          //       </DetailListContainer> 
          //       {lead.disableScan ?
          //         <FlexWrapper className="alignCenter" style={{width: 'fit-content'}}>
          //           <Tooltip
          //             componentsProps={{tooltip: {sx: {
          //               color: `${theme.palette.common.black}`,
          //               backgroundColor: `${theme.palette.common.offWhite}`,
          //               border: `0.3rem solid ${theme.palette.common.grayText}`,
          //               borderRight: `0.2rem solid ${theme.palette.common.grayText}`,
          //               opacity: '0',
          //             }}}}
          //             PopperProps={{modifiers: [{
          //               name: "offset",
          //               options: {
          //                 offset: [0, -7],
          //               },
          //             }]}}
          //             title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>scan performed</span></div>} 
          //             placement="left"
          //             arrow
          //           >
          //             <CardButton className="iconButton primary disabled">
          //               <PendingIcon style={{height: '3rem', width: '3rem'}} />
          //             </CardButton>
          //           </Tooltip>
          //           <AccentInfo style={{margin: 'auto 0 auto 0.3rem'}}>no updates were identified</AccentInfo>
          //         </FlexWrapper>
          //       :
          //         <FlexWrapper className="alignCenter" style={{width: 'fit-content'}}>
          //           <Tooltip
          //             componentsProps={{tooltip: {sx: {
          //               color: `${theme.palette.common.black}`,
          //               backgroundColor: `${theme.palette.common.offWhite}`,
          //               border: `0.3rem solid ${theme.palette.common.grayText}`,
          //               borderRight: `0.2rem solid ${theme.palette.common.grayText}`,
          //               opacity: '0',
          //             }}}}
          //             PopperProps={{modifiers: [{
          //               name: "offset",
          //               options: {
          //                 offset: [0, -7],
          //               },
          //             }]}}
          //             title={<div style={{display: 'flex', alignItems: "center", height: '3.5rem'}}><span style={{fontWeight: '600', fontSize: '1.2rem', padding: '0 1rem'}}>Run Scan</span></div>} 
          //             placement="left"
          //             arrow
          //           >
          //             <CardButton onClick={() => handleRunScan()} className="iconButton primary cursorResize">
          //               <PendingIcon style={{height: '3rem', width: '3rem'}} />
          //             </CardButton>
          //           </Tooltip>
          //           <AccentInfo style={{margin: 'auto 0 auto 0.3rem'}}>click to run scan</AccentInfo>
          //         </FlexWrapper>
          //       }                     
          //     </FlexWrapper>
          //   </FlexWrapper>  
          }
          <FlexWrapper className="column fitContentHeight"  style={{width: '23rem', minWidth: '23rem', paddingLeft: '1rem'}}>
            <FlexWrapper>
              {lead.newAssignmentNotification &&
                <NewLabel className="leadCard">Assigned</NewLabel>
              }
              {lead.targetOutcome === "renegotiation" ?
                <Typography className="autoMarginHorizontal outcome renegotiation">
                  Renegotiation
                </Typography>
              :
                <Typography className="autoMarginHorizontal outcome refinance">
                  Refinance
                </Typography>
              }
            </FlexWrapper>
            <FlexWrapper>
              <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                <Typography className="tinyHeight tiny">Date Discovered:</Typography>
                <Typography className="tinyHeight tiny">Began Investigation:</Typography>
                {lead.assigneeNames.length === 1 ?
                  <Typography className="tinyHeight tiny">Assignee:</Typography>
                :
                  <Typography className="tinyHeight tiny">Assignees:</Typography>
                }        
              </FlexWrapper>
              <FlexWrapper className="column" style={{paddingLeft: '0.5rem'}}>
                <Typography className="bold tiny">
                  {lead.dateDiscoveredLabel}
                </Typography>
                <Typography className="bold tiny">
                  {lead.dateInvestigating}
                </Typography>
                {lead.activeDiscrepancies > 0 ?
                  <Typography className="bold tiny">
                    {lead.activeDiscrepancies}
                  </Typography>
                :
                  <Typography className="tiny accent">-none-</Typography>
                }
                {lead.assigneeNames.length === 0 ?
                  <Typography className="tiny accent">-none-</Typography>
                : lead.assigneeNames.length === 1 ?
                  <Typography className="bold tiny">
                    {lead.assigneeNames[0]}
                  </Typography>
                :
                  lead.assigneeNames.map((assignee, index) => (
                    <Typography className="bold tiny" key={assignee}>{assignee}</Typography>
                  ))
                }
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper className="justifyAround">
            <FlexWrapper className="column fitContentWidth" style={{padding: '0 1rem'}}>
              <Typography className="subHeader dark autoMarginHorizontal">Mortgage Info</Typography>
              <FlexWrapper className="alignCenter">
                <FlexWrapper className="column alignEnd justifyBetween">
                  <Typography className="tinyHeight tiny">Address:</Typography>
                  <Typography className="tinyHeight"></Typography>
                  {lead.belongsToMortgage.owner1 && lead.belongsToMortgage.owner2 ?
                    <>
                      <Typography className="tinyHeight tiny">Borrowers:</Typography>
                      <Typography className="tinyHeight"></Typography>
                    </>
                  : lead.belongsToMortgage.owner1 || lead.belongsToMortgage.owner2 ?
                    <Typography className="tinyHeight tiny">Borrower:</Typography>
                  :
                    <Typography className="tinyHeight tiny">Borrowers:</Typography>
                  }
                  {lead.belongsToMortgage.originationDateLabel.length > 0 && lead.belongsToMortgage.endDateLabel.length === 0 ?
                    <Typography className="tinyHeight tiny">Originated:</Typography>
                  : lead.belongsToMortgage.endDateLabel.length > 0 && !lead.belongsToMortgage.originationDateLabel.length > 0 ?
                    <Typography className="tinyHeight tiny">Terminates:</Typography>
                  : lead.belongsToMortgage.originationDateLabel.length > 0 && lead.belongsToMortgage.endDateLabel.length > 0 ?
                    <>
                      <Typography className="tinyHeight tiny">Term:</Typography>
                      <Typography className="tinyHeight"></Typography>
                    </>
                  : 
                    <Typography className="tinyHeight tiny">Term:</Typography>
                  }
                  <Typography className="tinyHeight tiny">Remaining:</Typography>           
                </FlexWrapper>
                <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem'}}>
                  {lead.belongsToMortgage.streetAddress.length > 0 ?
                    <>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.streetAddress}
                      </Typography>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.recordDetails.address.City.currentValue}, {lead.belongsToMortgage.recordDetails.address.StateOrProvince.currentValue} {lead.belongsToMortgage.recordDetails.address.PostalCode.currentValue}
                      </Typography>
                    </>
                  :
                    <>
                      <Typography className="tiny accent">-N/A-</Typography>
                      <Typography className="tinyHeight"></Typography>
                    </>
                  }
                  {lead.belongsToMortgage.owner1.length > 0 && lead.belongsToMortgage.owner2.length > 0 ?
                    <>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.owner1}
                      </Typography>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.owner2}
                      </Typography>
                    </>
                  : lead.belongsToMortgage.owner1.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.owner1}
                    </Typography>
                  : lead.belongsToMortgage.owner2.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.owner2}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                  {lead.belongsToMortgage.originationDateLabel.length > 0 && lead.belongsToMortgage.endDateLabel.length > 0 ?
                    <FlexWrapper className="column">
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.originationDateLabel} 
                      </Typography>
                      <Typography className="bold tiny">
                        <Typography className="thin tiny" style={{marginRight: '0.5rem'}}>to</Typography>
                        {lead.belongsToMortgage.endDateLabel}
                      </Typography>
                    </FlexWrapper>
                  : lead.belongsToMortgage.originationDateLabel.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.originationDateLabel}
                    </Typography>
                  : lead.belongsToMortgage.endDateLabel.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.endDateLabel}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                  {lead.belongsToMortgage.remainingTerm.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.remainingTerm}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper className="column alignCenter fitContentWidth fitContentHeight">
            <Typography className="subHeader dark autoMarginHorizontal">Financials</Typography>
            <FlexWrapper className="alignCenter">
              <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                {lead.belongsToMortgage.originalLoanAmount > 0 &&
                  <Typography className="tinyHeight tiny">Loan Amount:</Typography>
                }
                {lead.belongsToMortgage.principalRemaining > 0 &&
                  <Typography className="tinyHeight tiny">Principal Remaining:</Typography>
                }
                {lead.belongsToMortgage.originalInterestRate > 0 &&
                  <Typography className="tinyHeight tiny">Original Interest Rate:</Typography>
                }
                {lead.belongsToMortgage.originalInterestDue > 0 &&
                  <Typography className="tinyHeight tiny">Original Interest Due:</Typography>
                }
                {lead.belongsToMortgage.interestRemaining > 0 &&
                  <Typography className="tinyHeight tiny">Current Interest Due:</Typography>
                }                
              </FlexWrapper>
              <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem'}}>
                {lead.belongsToMortgage.originalLoanAmount > 0 &&
                  <Typography className="bold tiny">
                    ${(lead.belongsToMortgage.originalLoanAmount).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                }
                {lead.belongsToMortgage.principalRemaining > 0 &&
                  <Typography className="bold tiny">
                    ${(lead.belongsToMortgage.principalRemaining).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                }
                {lead.belongsToMortgage.originalInterestRate > 0 &&
                  <Typography className="bold tiny">
                    {lead.belongsToMortgage.originalInterestRate}%
                  </Typography>
                }
                {lead.belongsToMortgage.originalInterestDue > 0 &&
                  <Typography className="bold tiny">
                    ${(lead.belongsToMortgage.originalInterestDue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                }
                {lead.belongsToMortgage.interestRemaining > 0 &&
                  <Typography className="bold tiny">
                    ${(lead.belongsToMortgage.interestRemaining).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                }
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>

        <FlexWrapper className="column" style={{paddingLeft: '2rem'}}>
          <FlexWrapper style={{padding: '0.5rem 0 0 3rem', marginTop: '0.5rem', borderTop: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
            {lead.belongsToMortgage.tags.length === 0 ?
              <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="accent">no mortgage tags</Typography>
                <AddButton onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                  <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
                </AddButton>
              </FlexWrapper>
            :
              <FlexWrapper className="alignCenter" style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="medium" style={{margin: 'auto 0.5rem auto 0'}}>mortgage tags:</Typography>
                {lead.belongsToMortgage.tags.map((tag) => (
                  <CardButton key={tag._id} className="horizontal mortgageTag" onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                    {tag.label === 'Property Type' || tag.label === 'Loan Type' ?
                      `${tag.label} - ${tag.discrepancyFields[0].toUpperCase()}`
                    :
                      `${tag.label}`
                    }
                  </CardButton>
                ))}
              </FlexWrapper>
            }
          </FlexWrapper>
          <FlexWrapper style={{paddingLeft: '3rem'}}>
            {lead.tags.length === 0 ?
              <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="accent">no lead tags</Typography>
                <AddButton onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                  <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
                </AddButton>
              </FlexWrapper>
            :
              <FlexWrapper className="alignCenter" style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="medium" style={{margin: 'auto 0.5rem auto 0'}}>lead tags:</Typography>
                {lead.tags.map((tag) => (
                  <CardButton key={tag._id} className="horizontal" onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                    {tag.label}
                  </CardButton>
                ))}
              </FlexWrapper>
            }
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </>
  )
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '1rem',
    minWidth: 180,
    background: theme.palette.common.grayBorder,
    borderRight: `1rem solid ${theme.palette.common.grayBorder}`,
    borderLeft: `1rem solid ${theme.palette.common.grayBorder}`,
    borderTop: `0.4rem solid ${theme.palette.common.grayBorder}`,
    borderBottom: `0.4rem solid ${theme.palette.common.grayBorder}`,
    '& .MuiMenu-list:hover': {
    },
    '& .MuiMenuItem-root': {
      background: theme.palette.common.pageBackground,
      '&.buttonTop': {
        padding: '1rem 2rem 0.5rem 2rem',
      },
      '&.border': {
        borderBottom: `0.1rem solid ${theme.palette.common.grayBorder}`
      },
      '&.topRadius': {
        borderRadius: '0.6rem 0.6rem 0 0',
      },
      '&.bottomRadius': {
        borderRadius: '0 0 0.6rem 0.6rem',
        padding: '0.5rem 2rem 1rem 2rem',
      }
    },
    '& .MuiMenuItem-root:hover': {
      background: theme.palette.common.grayHover,
      '&.button': {
        background: theme.palette.common.pageBackground,
      },
    },
  },
}));

export default AwaitingUpdateCard;