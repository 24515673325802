import React from "react";
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer } from "../../../StyledComponents";

const AssigneesUpdated = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">updated by:</Detail>
          <Detail className="timelineDetail">date:</Detail>
          {milestone.details[0].newLeadAssignees && milestone.details[0].newLeadAssignees.length === 1 ?
          <Detail className="timelineDetail">new lead assignee:</Detail>
          : milestone.details[0].newLeadAssignees && milestone.details[0].newLeadAssignees.length > 1 ?
          <Detail className="timelineDetail">new lead assignees:</Detail>
          :
            <></>
          }
          {milestone.details[0].removedLeadAssignees && milestone.details[0].removedLeadAssignees.length === 1 ?
          <Detail className="timelineDetail">removed lead assignee:</Detail>
          : milestone.details[0].removedLeadAssignees && milestone.details[0].removedLeadAssignees.length > 1 ?
          <Detail className="timelineDetail">removed lead assignees:</Detail>
          :
            <></>
          }
          {milestone.details[0].newMortgageAssignees && milestone.details[0].newMortgageAssignees.length === 1 ?
          <Detail className="timelineDetail">new mortgage assignee:</Detail>
          : milestone.details[0].newMortgageAssignees && milestone.details[0].newMortgageAssignees.length > 1 ?
          <Detail className="timelineDetail">new mortgage assignees:</Detail>
          :
            <></>
          }
          {milestone.details[0].removedMortgageAssignees && milestone.details[0].removedMortgageAssignees.length === 1 ?
          <Detail className="timelineDetail">removed mortgage assignee:</Detail>
          : milestone.details[0].removedMortgageAssignees && milestone.details[0].removedMortgageAssignees.length > 1 ?
          <Detail className="timelineDetail">removed mortgage assignees:</Detail>
          :
            <></>
          }
        </DetailInfoContainer>
        <DetailInfoContainer className="space" style={{height: 'fit-content'}}>
          <Detail className="timelineItem">{milestone.contributor}</Detail>
          <Detail className="timelineItem">{milestone.date}</Detail>
          {milestone.details[0].newLeadAssignees &&
            <Detail className="timelineItem column">
              {milestone.details[0].newLeadAssignees.map((assignee) => (
                <span key={assignee}>{assignee}</span>
              ))}
            </Detail>
          }
          {milestone.details[0].removedLeadAssignees &&
            <Detail className="timelineItem column">
              {milestone.details[0].removedLeadAssignees.map((assignee) => (
                <span key={assignee}>{assignee}</span>
              ))}
            </Detail>
          }
          {milestone.details[0].newMortgageAssignees &&
            <Detail className="timelineItem column">
              {milestone.details[0].newMortgageAssignees.map((assignee) => (
                <span key={assignee}>{assignee}</span>
              ))}
            </Detail>
          }
          {milestone.details[0].removedMortgageAssignees &&
            <Detail className="timelineItem column">
              {milestone.details[0].removedMortgageAssignees.map((assignee) => (
                <span key={assignee}>{assignee}</span>
              ))}
            </Detail>
          }
        </DetailInfoContainer>
      </DetailListContainer>
    </DetailContainer>
  )
}

export default AssigneesUpdated