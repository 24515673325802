import React from "react";
import { useTheme } from "@mui/styles";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useSelector } from "react-redux";
import { selectUserRole } from "../../../slices/userInfoSlice";
import { AccentInfo, Detail, DetailContainer, DetailInfoContainer, DetailListContainer, FlexWrapper } from "../../../StyledComponents";

const RecordScannedSuccessfully = ({milestone}) => {
  const theme = useTheme()

  const userRole = useSelector(selectUserRole)

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      {milestone.milestone === 'Record Scanned Successfully' &&
        <Detail><AccentInfo>No discrepancies detected</AccentInfo></Detail>
      }
      <DetailListContainer className='timeline'>
        <DetailInfoContainer className="item timeline">
          <Detail className="timelineDetail">date:</Detail>
          {userRole === 'super' ?
            <Detail className="timelineDetail">Queries:</Detail>
          :
            <></>
          }
        </DetailInfoContainer>
        <DetailInfoContainer className="space">
          <Detail className="timelineItem">{milestone.date}</Detail>
          {userRole === 'super' ?
            <Detail className="timelineItem">{milestone.details}</Detail>
          :
            <></>
          }
        </DetailInfoContainer>
      </DetailListContainer>
      {milestone.newMortgageTags.length > 0 ?
        <>
          <Detail>
            <AccentInfo>
              {milestone.newMortgageTags.length > 1 ? `New Mortgage Tags` : `New Mortgage Tag`}
            </AccentInfo>
          </Detail>
          <FlexWrapper className="flexWrap" style={{width: '100%', maxWidth: '35rem'}}>
            {milestone.newMortgageTags.map((mortgageTag) => (
              <FlexWrapper className="justifyCenter" key={mortgageTag.label} style={{margin: '0.1rem 0'}}>
                <Detail className="timelineDetail tier three" style={{alignItems: 'center'}}>
                  <LocalOfferIcon style={{height: '1.4rem', width: '1.4rem', marginRight: '0.4rem', color: `${theme.palette.primary.dark}`}} />
                  {mortgageTag.label}
                  {mortgageTag.label === 'Owner Occupied' || mortgageTag.label === 'Entity Owned' ?
                    <></>
                  :
                    mortgageTag.discrepancyFields.map((field, index) => (                        
                      index === (mortgageTag.discrepancyFields.length - 1) ?
                        ` - ${field}`
                      :
                        ``
                    ))
                  }
                </Detail>
              </FlexWrapper>
            ))}
          </FlexWrapper>
        </>
      :
        <></>
      }
    </DetailContainer>
  )
}

export default RecordScannedSuccessfully