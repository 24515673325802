import React, { PureComponent } from 'react';
import { useTheme } from "@mui/styles";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ReferenceLine, ResponsiveContainer, Label} from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <div style={{backgroundColor: `${theme.palette.common.cardBackground}`, padding: "10px", width: "200px", borderRadius: ".5rem", border: `0.1rem solid ${theme.palette.common.black}`}}>
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          {`Month: `}
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.sessionLabelFull}`}
          </span>
        </div>

        <hr />
        
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Leads Dismissed: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.leadsDismissed}`}
          </span>
        </div>

        <hr />
        
        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Closed Renegotiations: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.closedRenegotiations}`}
          </span>
        </div>

        <hr />

        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Closed Refinances: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.closedRefinances}`}
          </span>
        </div>

        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Gross Profit: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`$ ${payload[0].payload.grossProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}`}
          </span>
        </div>

        <div style={{margin: "0", display: "flex", justifyContent: "space-between", fontWeight: "600"}}>
          <span>{`Gross Revenue Increase: `}</span>
          <span style={{color: `${theme.palette.common.black}`}}>
            {`${payload[0].payload.grossProfitPercent.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} %`}
          </span>
        </div>
      </div>
    );
  }

  return null;
};


export default class MemberMonthlyChart extends PureComponent {

  render() 
  {
    return (
      <ResponsiveContainer width="95%" height="95%">
        <BarChart
          data={this.props.selectedMemberMonthlyStats}
          layout="horizontal"
          margin={{
            top: 0,
            right: 5,
            left: 15,
            bottom: 0,
          }}
        >
          <XAxis dataKey="sessionLabel">
            <Label angle={0} position='left' style={{ textAnchor: 'right'}} offset={-25} dy={20}>
              Year/Month
            </Label>
          </XAxis>
          <YAxis 
            type="number" 
            yAxisId="left" 
            orientation="left" 
            stroke="#5a5c68" 
            domain={[0, dataMax => dataMax === 0 ? 1 : (Math.round(((dataMax * 1.1))/1000)*1000)]}
            tickFormatter={tick => {
              return `$${tick.toLocaleString('en-US')}`
            }}
          >
            <Label angle={270} position='right' style={{ textAnchor: 'middle' }} offset={-70}>
              Gross Profit
            </Label>
          </YAxis>
          <YAxis dataKey="totalCompleted" type="number" domain={[0, dataMax => dataMax === 0 ? 1 : (dataMax * 2)]} yAxisId="right" orientation="right" stroke="#5a5c68" >
            <Label angle={90} position='right' style={{ textAnchor: 'middle' }} offset={-20}>
              Closed Leads
            </Label>
          </YAxis>
          <Tooltip content={<CustomTooltip/>} cursor={{fill: `#93959921`}} />
          <Legend />
          <ReferenceLine y={0} stroke="#4b4b4d" yAxisId="left" />
          <Bar yAxisId="left" dataKey="grossProfitNumber" fill="#00B812" stackId="a" name="Gross Profit" />
          <Bar yAxisId="right" dataKey="closedRenegotiations" fill="#E38A86" stackId="b" name="Renegotiations" />
          <Bar yAxisId="right" dataKey="closedRefinances" fill="#CAABD1" stackId="c" name="Refinances" />
          <Bar yAxisId="right" dataKey="leadsDismissed" fill="#CAABD1" stackId="d" name="Leads Dismissed" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}