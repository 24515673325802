import React from "react";
import { useTheme } from "@mui/styles";
import { AccentInfo, Detail, DetailContainer, DetailListContainer, FlexWrapper, DetailInfoContainer } from "../../../StyledComponents";

const LeadDiscovered = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent" style={{padding: '0.5rem 0 0 0'}}>
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <div>
        <Detail style={{margin: '0 0 0 1rem'}}>{milestone.date}</Detail>
        {milestone.tier1Discrepancies.length > 0 || milestone.tier2Discrepancies.length > 0 || milestone.tier3Discrepancies.length > 0 ?
          <>
            <Detail className="timelineDetail" style={{margin: '0.5rem 0 0.5rem 0', fontWeight: '400', gridRow: '2'}}>Discrepancies Identified:</Detail>
            <DetailListContainer className="timeline column" style={{gridRow: '3'}}>
              {milestone.tier1Discrepancies.map((discrepancy, index) => (
                discrepancy.publicRecordValue !== null ?
                  <FlexWrapper key={discrepancy.label} className="alignCenter">
                    <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                      <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
                    </div>
                    <Detail style={{padding: '0 0 0 0.3rem'}}>
                      {discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' ?
                        <>
                          ${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        </>
                      : discrepancy.label === 'Tax Annual Amount' ?
                        <>
                          ${parseFloat(discrepancy.publicRecordValue.substring(1)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                        </>
                      : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                        <>
                          {parseFloat(discrepancy.publicRecordValue)}
                        </>
                      : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                        <>
                          {parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
                        </>
                      :
                        discrepancy.publicRecordValue
                      }
                    </Detail>
                  </FlexWrapper>
                :
                  <FlexWrapper key={discrepancy.label}>
                    <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
                    <Detail><AccentInfo>-empty-</AccentInfo></Detail>
                  </FlexWrapper>
              ))}
              {milestone.tier2Discrepancies.map((discrepancy, index) => (
                discrepancy.publicRecordValue !== null ?
                  <FlexWrapper key={discrepancy.label} className="alignCenter">
                    <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                      <Detail className="timelineDetail tier two">{discrepancy.label}</Detail>
                    </div>
                    <Detail style={{padding: '0 0 0 0.3rem'}}>
                      {discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' ?
                        <>
                          ${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        </>
                      : discrepancy.label === 'Tax Annual Amount' ?
                        <>
                          ${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                        </>
                      : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                        <>
                          {parseFloat(discrepancy.publicRecordValue)}
                        </>
                      : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                        <>
                          {parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
                        </>
                      :
                        discrepancy.publicRecordValue
                      }
                    </Detail>
                  </FlexWrapper>
                :
                  <FlexWrapper key={discrepancy.label}>
                    <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
                    <Detail><AccentInfo>-empty-</AccentInfo></Detail>
                  </FlexWrapper>
              ))}
              {milestone.tier3Discrepancies.map((discrepancy, index) => (
                discrepancy.publicRecordValue !== null ?
                  <FlexWrapper key={discrepancy.label} className="alignCenter">
                    <div style={{width: '19rem', display: 'flex', justifyContent: 'flex-end'}}>
                      <Detail className="timelineDetail tier three">{discrepancy.label}</Detail>
                    </div>
                    <Detail style={{padding: '0 0 0 0.3rem'}}>
                      {discrepancy.label === 'Assessed Value' || discrepancy.label === 'Land Value' || discrepancy.label === 'Improvements Value' || discrepancy.label === 'Close Price' || discrepancy.label === 'Last Sale Price' || discrepancy.label === 'Assessed Land Value' || discrepancy.label === 'Assessed Improvement Value' ?
                        <>
                          ${parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        </>
                      : discrepancy.label === 'Tax Annual Amount' ?
                        <>
                          ${parseFloat(discrepancy.publicRecordValue.substring(1)).toLocaleString("en-US", {maximumFractionDigits: 2, minimumFractionDigits: 2})}
                        </>
                      : discrepancy.label === 'Latitude' || discrepancy.label === 'Longitude' || discrepancy.label === 'Rooms' || discrepancy.label === 'Bedrooms' || discrepancy.label === 'Bathrooms Full' || discrepancy.label === 'Bathrooms Total' ?
                        <>
                          {parseFloat(discrepancy.publicRecordValue)}
                        </>
                      : discrepancy.label === 'Living Area' || discrepancy.label === 'Garage Area' || discrepancy.label === 'Building Area Total' || discrepancy.label === 'Gross Area' || discrepancy.label === 'Lot Size Square Feet' ?
                        <>
                          {parseFloat(discrepancy.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})} sq ft
                        </>
                      :
                        discrepancy.publicRecordValue
                      }
                    </Detail>
                  </FlexWrapper>
                :
                  <FlexWrapper key={discrepancy.label}>
                    <Detail className="timelineDetail tier one">{discrepancy.label}</Detail>
                    <Detail><AccentInfo>-empty-</AccentInfo></Detail>
                  </FlexWrapper>
              ))}
            </DetailListContainer>
          </>
        :
          <></>
        }
        {milestone.previousNotes.length > 0 &&
          <>
            <Detail style={{margin: '0.5rem 0 0.5rem 0', fontWeight: '400', gridRow: '2'}}>Notes:</Detail>
            <DetailInfoContainer style={{margin: '0 0 1rem 0'}}>
              {milestone.previousNotes.length > 0 &&
                milestone.previousNotes.map((note, index) => (
                  <Detail key={note._id}>{note.contributor}, {note.date}:</Detail>
                ))
              }
              {milestone.previousNotes.length > 0 &&
                milestone.previousNotes.map((note, index) => (
                  <Detail style={{margin: '0 0 0 3rem'}} key={note._id}>{note.details}</Detail>
                ))
              }
            </DetailInfoContainer>
          </>
        }
        {milestone.newLeadTags.length > 0 ?
          milestone.newLeadTags.length > 1 ?
            <>
              <Detail>New Lead Tags</Detail>
              <DetailListContainer style={{flexWrap: 'wrap', maxWidth: '35rem', padding: '1rem 0'}}>
                {milestone.newLeadTags.map((leadTag) => (
                  <Detail key={leadTag._id} className="timelineDetail tier three" style={{margin: '0.2rem auto 0.1rem 2rem'}}>{leadTag.label}</Detail>
                ))}
              </DetailListContainer>
            </>
          :
            <>
              <Detail>New Lead Tag</Detail>
              <DetailListContainer style={{padding: '1rem 0'}}>
                <Detail className="timelineDetail tier three" style={{margin: '0.2rem auto 0.1rem 2rem'}}>{milestone.newLeadTags[0].label}</Detail>
              </DetailListContainer>
            </>
            
        :
          <></>
        }
        {milestone.newMortgageTags.length > 0 ?
          milestone.newMortgageTags.length > 1 ?
            <>
              <Detail>New Mortgage Tags</Detail>
              <DetailListContainer style={{flexWrap: 'wrap', maxWidth: '35rem', padding: '1rem 0'}}>
                {milestone.newMortgageTags.map((mortgageTag) => (
                  <Detail key={mortgageTag._id} className="timelineDetail tier three" style={{margin: '0.2rem auto 0.1rem 2rem'}}>{mortgageTag.label}</Detail>
                ))}
              </DetailListContainer>
            </>
          :
            <>
              <Detail>New Mortgage Tag</Detail>
              <DetailListContainer style={{padding: '1rem 0'}}>
                <Detail className="timelineDetail tier three" style={{margin: '0.2rem auto 0.1rem 2rem'}}>{milestone.newMortgageTags[0].label}</Detail>
              </DetailListContainer>
            </>
            
        :
          <></>
        }
      </div>
    </DetailContainer>
  )
}

export default LeadDiscovered