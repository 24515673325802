import React from "react"
import NoteAdded from "./components/NoteAdded";
import Finalized from "./components/Finalized";
import Unassigned from "./components/Unassigned";
import TagAssigned from "./components/TagAssigned";
import { useTheme } from "@mui/styles";
import LeadDiscovered from "./components/LeadDiscovered";
import TargetsUpdated from "./components/TargetsUpdates";
import { FlexWrapper, ScrollWindow } from "../../StyledComponents";
import { useSelector } from "react-redux";
import MortgageUpdated from "./components/MortgageUpdated";
import AssigneesUpdated from "./components/AssigneesUpdated";
import LeadPathConnectors from "./components/PathConnectors/LeadPathConnectors";
import UnderInvestigation from "./components/UnderInvestigation";
import DiscrepancyRejected from "./components/DiscrepancyRejected";
import DiscrepancyResolved from "./components/DiscrepancyResolved";
import ManualInvestigation from "./components/ManualInvestigation";
import TargetOutcomeUpdated from "./components/TargetOutcomeUpdated";
import ContinueAwaitingUpdates from "./components/ContinueAwaitingUpdates";
import DiscrepanciesAllResolved from "./components/DiscrepanciesAllResolved";
import RecordScannedSuccessfully from "./components/RecordScannedSuccessfully";
import NewDiscrepanciesDiscovered from "./components/NewDiscrepanciesDiscovered";
import DiscrepancyEditedOrRejected from "./components/DiscrepancyEditedOrCreated";
import { selectViewingLeadTimeline } from "../../slices/viewingLeadSlice";
import PublicRecordsConfirmed from "./components/PublicRecordsConfirmed";

const LeadTimeline = () => {
  const theme = useTheme()

  const timeline = useSelector(selectViewingLeadTimeline)

  return (
    <ScrollWindow className="verticalOnly">
      {timeline.map((milestone, index) => (
        <div key={milestone.milestone} style={{padding: index === 0 ? '0.5rem 0 0 0.5rem' : '0 0 0 0.5rem'}}>
          <FlexWrapper style={{marginBottom: '0.5rem', boxShadow: `0rem 0rem 0.5rem ${theme.palette.common.grayText}`, border: `0.1rem solid ${theme.palette.common.grayText}`}}>
            <LeadPathConnectors milestone={milestone} mapIndex={index} timeline={timeline} />
            {milestone.milestone === 'Lead Discovered' ?
              <LeadDiscovered milestone={milestone} />
            : milestone.milestone === 'Targets Updated' ?
              <TargetsUpdated milestone={milestone} />
            : milestone.milestone === 'Target Outcome Updated' ?
              <TargetOutcomeUpdated milestone={milestone} />
            : milestone.milestone === 'Under Investigation' ?
              <UnderInvestigation milestone={milestone} />
            : milestone.milestone === 'Lead Tag Created & Assigned' || milestone.milestone === 'Lead Tag Assigned' || milestone.milestone === 'Mortgage Tag Created & Assigned' || milestone.milestone === 'Mortgage Tag Assigned' ?
              <TagAssigned milestone={milestone} />
            : milestone.milestone === 'Manual Investigation' ?
              <ManualInvestigation milestone={milestone} />
            : milestone.milestone === 'Assignees Updated' ?
              <AssigneesUpdated milestone={milestone} />
            : milestone.milestone === 'Note Added' ?
              <NoteAdded milestone={milestone} />
            : milestone.milestone === 'New Discrepancies Discovered' || milestone.milestone === 'Public Records Updated' ?
              <NewDiscrepanciesDiscovered milestone={milestone} />
            : milestone.milestone === 'Mortgage Updated' ?
              <MortgageUpdated milestone={milestone} />
            : milestone.milestone === 'Discrepancy Resolved' ?
              <DiscrepancyResolved milestone={milestone} />
            : milestone.milestone === 'Discrepancy Rejected' ?
              <DiscrepancyRejected milestone={milestone} />
            : milestone.milestone === 'Discrepancy Edited' || milestone.milestone === 'Discrepancy Created' ?
              <DiscrepancyEditedOrRejected milestone={milestone} />
            : milestone.milestone === 'Finalized' ?
              <Finalized milestone={milestone} origin={'lead'} />
            : milestone.milestone === 'All Discrepancies Resolved' ?
              <DiscrepanciesAllResolved milestone={milestone} />
            : milestone.milestone === 'Record Scanned Successfully' ?
              <RecordScannedSuccessfully milestone={milestone} />
            : milestone.milestone === 'Continue Waiting for Updates' ?
              <ContinueAwaitingUpdates milestone={milestone} />
            : milestone.milestone === 'Public Records Verified' ?
              <PublicRecordsConfirmed milestone={milestone} />
            :
              <Unassigned milestone={milestone} />
            }
          </FlexWrapper>
        </div>
      ))}
    </ScrollWindow>
  )
}

export default LeadTimeline;