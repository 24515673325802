import React, { useEffect, useState } from "react";
import Card from "./components/Card/Card";
import Controls from "./components/Controls";
import { Dialog } from "@mui/material";
import { useTheme } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import LeadActionsModal from "../../../../components/Modals/LeadActions/LeadActionsModal";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { setViewingLead } from "../../../../slices/viewingLeadSlice";
import { selectUserFullName } from "../../../../slices/userInfoSlice";
import { setViewingMortgage } from "../../../../slices/viewingMortgageSlice";
import { selectAllTeamReports } from "../../../../slices/teamReportsSlice";
import { setViewingLeadReports } from "../../../../slices/viewingLeadReportsSlice";
import handleLeadNewFilterChanges from "./functions/filterNew";
import handleLeadTagFilterChanges from "./functions/filterTag";
import handleLeadTierFilterChanges from "./functions/filterTier";
import handleLeadTypeFilterChanges from "./functions/filterType";
import handleLeadSortMethodChanges from "./functions/sortMethod";
import { useDispatch, useSelector } from "react-redux";
import handleLeadSortCategoryChanges from "./functions/sortCategory";
import { removeNewLeadNotification } from "../../../../slices/userNotificationsSlice";
import { selectTeamLeadTagIds, selectTeamLeadTags } from "../../../../slices/teamAndUserSettingsSlice";
import { useDeleteUserLeadNotificationMutation, useDeleteUserNewAssignmentNotificationMutation } from "../../../../slices/api/apiSlice";
import { CardControlsContainer, CardListContainer, CardListDetail, CardListHeader, FlexWrapper, HeaderText, PageContent } from "../../../../StyledComponents";
import { selectFilteredMemberAwaitingActionLeads, selectFilteredMemberAwaitingUpdateLeads, selectFilteredMemberClosingLeads, selectFilteredMemberInvestigatingLeads, selectMemberAwaitingActionLeads, selectMemberAwaitingUpdateLeads, selectMemberClosingLeads, selectMemberInvestigatingLeads, setFilteredMemberAwaitingActionLeads, setFilteredMemberClosingLeads, setFilteredMemberInvestigatingLeads, setSortedMemberAwaitingActionLeads, setSortedMemberClosingLeads, setSortedMemberInvestigatingLeads } from "../../../../slices/memberAssignmentsSlice";
import { selectTeamClosingLeads, selectFilteredTeamClosingLeads, selectFilteredTeamInvestigatingLeads, selectTeamInvestigatingLeads, setFilteredTeamClosingLeads, setFilteredTeamInvestigatingLeads, setSortedTeamClosingLeads, setSortedTeamInvestigatingLeads, setSortedTeamAwaitingActionLeads, setFilteredTeamAwaitingActionLeads, selectTeamAwaitingActionLeads, selectFilteredTeamAwaitingActionLeads, selectTeamAwaitingUpdateLeads, selectFilteredTeamAwaitingUpdateLeads } from "../../../../slices/teamActiveLeadsSlice";
import { selectDashPerspective, selectDashUserFullName, setLeadActionNavMenuSelection } from "../../../../slices/sessionDataSlice";

const ActiveLeads = ({ teamId, userId }) => {
  const theme = useTheme();
  const dispatch = useDispatch()

  const [deleteUserLeadNotification] = useDeleteUserLeadNotificationMutation()
  const [deleteUserNewAssignmentNotification] = useDeleteUserNewAssignmentNotificationMutation()

  const teamReports = useSelector(selectAllTeamReports)
  const teamLeadTags = useSelector(selectTeamLeadTags)
  const userFullName = useSelector(selectUserFullName)
  const memberFullName = useSelector(selectDashUserFullName)
  const teamLeadTagIds = useSelector(selectTeamLeadTagIds)
  const dashPerspective = useSelector(selectDashPerspective)
  
  const teamAwaitingActionLeads = useSelector(selectTeamAwaitingActionLeads)
  const filteredTeamAwaitingActionLeads = useSelector(selectFilteredTeamAwaitingActionLeads)
  const teamInvestigatingLeads = useSelector(selectTeamInvestigatingLeads)
  const filteredTeamInvestigatingLeads = useSelector(selectFilteredTeamInvestigatingLeads)
  const teamClosingLeads = useSelector(selectTeamClosingLeads)
  const filteredTeamClosingLeads = useSelector(selectFilteredTeamClosingLeads)
  const teamAwaitingUpdateLeads = useSelector(selectTeamAwaitingUpdateLeads)
  const filteredTeamAwaitingUpdateLeads = useSelector(selectFilteredTeamAwaitingUpdateLeads)
  const memberAwaitingActionLeads = useSelector(selectMemberAwaitingActionLeads)
  const filteredMemberAwaitingActionLeads = useSelector(selectFilteredMemberAwaitingActionLeads)
  const memberInvestigatingLeads = useSelector(selectMemberInvestigatingLeads)
  const filteredMemberInvestigatingLeads = useSelector(selectFilteredMemberInvestigatingLeads)
  const memberClosingLeads = useSelector(selectMemberClosingLeads)
  const filteredMemberClosingLeads = useSelector(selectFilteredMemberClosingLeads)
  const memberAwaitingUpdateLeads = useSelector(selectMemberAwaitingUpdateLeads)
  const filteredMemberAwaitingUpdateLeads = useSelector(selectFilteredMemberAwaitingUpdateLeads)

  const [openLeadActionsModal, setOpenLeadActionsModal] = useState(false)
  const [leadStatusFilter, setLeadStatusFilter] = useState('Awaiting Action')
  const [leadNewFilter, setLeadNewFilter] = useState('false')
  const [leadTypeFilter, setLeadTypeFilter] = useState('All')
  const [leadTierFilter, setLeadTierFilter] = useState('All')
  const [leadTagFilter, setLeadTagFilter] = useState('All')
  const [leadCategorySort, setLeadCategorySort] = useState('Date Discovered')
  const [leadSortMethod, setLeadSortMethod] = useState('High-Low')
  const [sortLoaded, setSortLoaded] = useState(true)
  const [leadUserNotifications, setLeadUserNotifications] = useState(0)
  
  useEffect(() => {
    setLeadTagFilter('All')
    setLeadTypeFilter('All')
    setLeadTierFilter('All')
    setLeadCategorySort('Date Discovered')
    setLeadSortMethod('High-Low')
  }, [dashPerspective])

  const handleLeadSortCategoryChange = async (event) => {
    setLeadCategorySort(event.target.value);
    setSortLoaded(false)
    let sortedLeads = {}
    let sortedFilteredLeads = {}
    if (leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false') {
      if (dashPerspective === 'team') {
        sortedLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads)
        dispatch( setSortedTeamAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedTeamInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedTeamClosingLeads(sortedLeads.closing) )
      } else {
        sortedLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads)
        dispatch( setSortedMemberAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedMemberInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedMemberClosingLeads(sortedLeads.closing) )
      }
      setSortLoaded(true)
    } else {
      if (dashPerspective === 'team') {
        sortedFilteredLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads)
        dispatch( setFilteredTeamAwaitingActionLeads(sortedFilteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(sortedFilteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(sortedFilteredLeads.closing) )
        setSortLoaded(true)
        sortedLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads)
        dispatch( setSortedTeamAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedTeamInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedTeamClosingLeads(sortedLeads.closing) )        
      } else {
        sortedFilteredLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads)
        dispatch( setFilteredMemberAwaitingActionLeads(sortedFilteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(sortedFilteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(sortedFilteredLeads.closing) )
        setSortLoaded(true)
        sortedLeads = await handleLeadSortCategoryChanges(event.target.value, leadSortMethod, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads)
        dispatch( setSortedMemberAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedMemberInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedMemberClosingLeads(sortedLeads.closing) )
      }
    }
  }
  const handleLeadSortMethodChange = async (event) => {
    setLeadSortMethod(event.target.value);
    setSortLoaded(false)
    let sortedLeads = {}
    let sortedFilteredLeads = {}
    if (leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false') {
      if (dashPerspective === 'team') {
        sortedLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads)
        dispatch( setSortedTeamAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedTeamInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedTeamClosingLeads(sortedLeads.closing) )
        setSortLoaded(true)
      } else {
        sortedLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads)
        dispatch( setSortedMemberAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedMemberInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedMemberClosingLeads(sortedLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      if (dashPerspective === 'team') {
        sortedFilteredLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads)
        dispatch( setFilteredTeamAwaitingActionLeads(sortedFilteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(sortedFilteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(sortedFilteredLeads.closing) )
        setSortLoaded(true)
        sortedLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads)
        dispatch( setSortedTeamAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedTeamInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedTeamClosingLeads(sortedLeads.closing) )
      } else {
        sortedFilteredLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads)
        dispatch( setFilteredMemberAwaitingActionLeads(sortedFilteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(sortedFilteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(sortedFilteredLeads.closing) )
        setSortLoaded(true)
        sortedLeads = await handleLeadSortMethodChanges(event.target.value, leadCategorySort, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads)
        dispatch( setSortedMemberAwaitingActionLeads(sortedLeads.awaitingAction) )
        dispatch( setSortedMemberInvestigatingLeads(sortedLeads.investigating) )
        dispatch( setSortedMemberClosingLeads(sortedLeads.closing) )
      }
    }
  }
  const handleLeadTierFilterChange = async (event) => {
    setSortLoaded(false)
    if (leadTypeFilter === 'All' && leadNewFilter === 'true' && leadTierFilter === 'All') {
      if (dashPerspective === 'team') {
        setLeadTierFilter(event.target.value);
        let filteredLeads = await handleLeadTierFilterChanges(event.target.value, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads);
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTierFilter(event.target.value);
        let filteredLeads = await handleLeadTierFilterChanges(event.target.value, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      if (dashPerspective === 'team') {
        setLeadTierFilter(event.target.value);
        let filteredLeads = await handleLeadTierFilterChanges(event.target.value, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads, leadTypeFilter, leadNewFilter);
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTierFilter(event.target.value);
        let filteredLeads = await handleLeadTierFilterChanges(event.target.value, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads, leadTypeFilter, leadNewFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    }
  }
  const handleLeadTagFilterChange = async (event) => {
    setSortLoaded(false)
    if (leadTypeFilter === 'All' && leadNewFilter === 'true' && leadTierFilter === 'All' && leadTagFilter === 'All') {
      if (dashPerspective === 'team') {
        setLeadTagFilter(event.target.value);
        let filteredLeads = await handleLeadTagFilterChanges(teamLeadTagIds, event.target.value, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads, leadTypeFilter);
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTagFilter(event.target.value);
        let filteredLeads = await handleLeadTagFilterChanges(teamLeadTagIds, event.target.value, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads, leadTypeFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      if (dashPerspective === 'team') {
        setLeadTagFilter(event.target.value);
        let filteredLeads = await handleLeadTagFilterChanges(teamLeadTagIds, event.target.value, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads, leadTagFilter, leadNewFilter, leadTypeFilter);
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTagFilter(event.target.value);
        let filteredLeads = await handleLeadTagFilterChanges(teamLeadTagIds, event.target.value, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads, leadTagFilter, leadNewFilter, leadTypeFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    }
  }
  const handleLeadStatusFilterChange = (event) => {
    setLeadStatusFilter(event.target.value)
  }
  const handleLeadNewFilterChange = async (event) => {
    setSortLoaded(false)
    if (leadTypeFilter === 'All' && leadTierFilter === 'All' && leadNewFilter === 'false') {
      if (dashPerspective === 'team') {
        setLeadNewFilter(event.target.value);
        let filteredLeads = await handleLeadNewFilterChanges(event.target.value, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads)
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadNewFilter(event.target.value);
        let filteredLeads = await handleLeadNewFilterChanges(event.target.value, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads)
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      if (dashPerspective === 'team') {
        setLeadNewFilter(event.target.value);
        let filteredLeads = await handleLeadNewFilterChanges(event.target.value, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads, leadTierFilter, leadTypeFilter)
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadNewFilter(event.target.value);
        let filteredLeads = await handleLeadNewFilterChanges(event.target.value, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads, leadTierFilter, leadTypeFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    }
  }
  const handleLeadTypeFilterChange = async (event) => {
    setSortLoaded(false)
    if (leadTierFilter === 'All' && leadNewFilter !== 'false' && leadTypeFilter === 'All') {
      if (dashPerspective === 'team') {
        setLeadTypeFilter(event.target.value);
        let filteredLeads = await handleLeadTypeFilterChanges(event.target.value, filteredTeamAwaitingActionLeads, filteredTeamInvestigatingLeads, filteredTeamClosingLeads)
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTypeFilter(event.target.value);
        let filteredLeads = await handleLeadTypeFilterChanges(event.target.value, filteredMemberAwaitingActionLeads, filteredMemberInvestigatingLeads, filteredMemberClosingLeads);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    } else {
      if (dashPerspective === 'team') {
        setLeadTypeFilter(event.target.value);
        let filteredLeads = await handleLeadTypeFilterChanges(event.target.value, teamAwaitingActionLeads, teamInvestigatingLeads, teamClosingLeads, leadTierFilter, leadNewFilter)
        dispatch( setFilteredTeamAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredTeamInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredTeamClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      } else {
        setLeadTypeFilter(event.target.value);
        let filteredLeads = await handleLeadTypeFilterChanges(event.target.value, memberAwaitingActionLeads, memberInvestigatingLeads, memberClosingLeads, leadTierFilter, leadNewFilter);
        dispatch( setFilteredMemberAwaitingActionLeads(filteredLeads.awaitingAction) )
        dispatch( setFilteredMemberInvestigatingLeads(filteredLeads.investigating) )
        dispatch( setFilteredMemberClosingLeads(filteredLeads.closing) )
        setSortLoaded(true)
      }
    }
  }

  const handleOpenLeadActionsModal = (destination, lead, userNotifications, leadMortgage) => {
    let populatedReports = []
    for (let i = 0; i < leadMortgage.reports.length; i++) {
      let match = teamReports.find(report => report._id === leadMortgage.reports[i])
      populatedReports.push(match)
    }
    dispatch( setViewingLeadReports(populatedReports) )
    setLeadUserNotifications(userNotifications)
    dispatch( setViewingLead(lead) )
    dispatch( setViewingMortgage(leadMortgage) )
    dispatch( setLeadActionNavMenuSelection(destination) )
    if (lead.userNotifications > 0) {
      dispatch( removeNewLeadNotification(lead.status) )
      deleteUserLeadNotification({
        userId: userId,
        leadId: lead._id,
      })
    }
    if (lead.newAssignmentNotification) {
      deleteUserNewAssignmentNotification({
        userId: userId,
        leadId: lead._id,
      })
    }
    setOpenLeadActionsModal(true)
  }
  const handleCloseModal = () => {
    setOpenLeadActionsModal(false)
  }

  return (
    <>
      <PageContent className="grid activeLeads">
        {leadStatusFilter === "Awaiting Action" ?
          dashPerspective === 'team' ?
            <>
              {teamAwaitingActionLeads !== undefined && sortLoaded && leadTierFilter === 'All' && leadNewFilter === 'false' ?
                teamAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3', paddingBottom: '4rem'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team Investigations Awaiting Action
                      <CardListDetail>Total: {teamAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {teamAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              : filteredTeamAwaitingActionLeads !== undefined && sortLoaded ?
                filteredTeamAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team Investigations Awaiting Action
                      <CardListDetail>Total: {filteredTeamAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredTeamAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>  
              }
            </>        
          : dashPerspective === userId ?
            <>
              {memberAwaitingActionLeads !== undefined && sortLoaded && leadTierFilter === 'All' && leadNewFilter === 'false' ?
                memberAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Investigations Awaiting Action
                      <CardListDetail>Total: {memberAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {memberAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              : filteredMemberAwaitingActionLeads !== undefined && sortLoaded ?
                filteredMemberAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Investigations Awaiting Action
                      <CardListDetail>Total: {filteredMemberAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredMemberAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          :
            <>
              {memberAwaitingActionLeads !== undefined && sortLoaded && leadTierFilter === 'All' && leadNewFilter === 'false' ?
                memberAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Investigations Awaiting Action
                      <CardListDetail>Total: {memberAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {memberAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              : sortLoaded && filteredMemberAwaitingActionLeads !== undefined ?
                filteredMemberAwaitingActionLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no investigations awaiting action</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Investigations Awaiting Action
                      <CardListDetail>Filtered Total: {filteredMemberAwaitingActionLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredMemberAwaitingActionLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
        : leadStatusFilter === 'Investigating' ?
          dashPerspective === 'team' ?
            <>
              {teamInvestigatingLeads !== undefined && teamClosingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' && sortLoaded ?
                teamInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team's Leads Under Investigation
                      <CardListDetail>Total: {teamInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {teamInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              : filteredTeamInvestigatingLeads !== undefined && filteredTeamClosingLeads !== undefined && sortLoaded ?
                filteredTeamInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team's Leads Under Investigation
                      <CardListDetail>Filtered Total: {filteredTeamInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredTeamInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          : dashPerspective === userId ?
            <>
              {sortLoaded && memberInvestigatingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
                memberInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Leads Under Investigation
                      <CardListDetail>Total: {memberInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {memberInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              : sortLoaded && filteredMemberInvestigatingLeads !== undefined ?
                filteredMemberInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Leads Under Investigation
                      <CardListDetail>Filtered Total: {filteredMemberInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredMemberInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          :
            <>
              {sortLoaded && memberInvestigatingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
                memberInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Leads Under Investigation
                      <CardListDetail>Total: {memberInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {memberInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              : sortLoaded && filteredMemberInvestigatingLeads !== undefined ?
                filteredMemberInvestigatingLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads under investigation</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Leads Under Investigation
                      <CardListDetail>Filtered Total: {filteredMemberInvestigatingLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredMemberInvestigatingLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
        : leadStatusFilter === "Awaiting Update" ?
          dashPerspective === 'team' ?
            <>
              {teamAwaitingUpdateLeads !== undefined && teamClosingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' && sortLoaded ?
                teamAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team's Leads Awaiting Public Records Update
                      <CardListDetail>Total: {teamAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {teamAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter} handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </CardListContainer>
                  </>
              : filteredTeamAwaitingUpdateLeads !== undefined && filteredTeamInvestigatingLeads !== undefined && filteredTeamClosingLeads !== undefined && sortLoaded ?
                filteredTeamAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      Team's Leads Awaiting Public Records Update
                      <CardListDetail>Filtered Total: {filteredTeamAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredTeamAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          : dashPerspective === userId ?
            <>
              {sortLoaded && memberAwaitingUpdateLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
                memberAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Leads Awaiting Public Records Update
                      <CardListDetail>Total: {memberAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {memberAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </CardListContainer>
                  </>
              : sortLoaded && filteredMemberAwaitingUpdateLeads !== undefined ?
                filteredMemberAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      My Leads Awaiting Public Records Update
                      <CardListDetail>Filtered Total: {filteredMemberAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredMemberAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
          :
            <>
              {sortLoaded && memberAwaitingUpdateLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
                memberAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Leads Awaiting Public Records Update
                      <CardListDetail>Total: {memberAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {memberAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </CardListContainer>
                  </>
              : sortLoaded && filteredMemberAwaitingUpdateLeads !== undefined ?
                filteredMemberAwaitingUpdateLeads.length === 0 ?
                  <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                    <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                    <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads awaiting updates</HeaderText>
                  </FlexWrapper>
                :
                  <>
                    <CardListHeader>
                      {memberFullName}'s Leads Awaiting Public Records Update
                      <CardListDetail>Filtered Total: {filteredMemberAwaitingUpdateLeads.length}</CardListDetail>
                    </CardListHeader>
                    <CardListContainer>
                      {filteredMemberAwaitingUpdateLeads.map((lead, index) => (
                        <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} teamId={teamId}  />
                      ))}
                    </CardListContainer>
                  </>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
            </>
        : dashPerspective === 'team' ?
          teamInvestigatingLeads !== undefined && teamClosingLeads !== undefined && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' && sortLoaded ?
            teamClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  Team's Closing Leads
                  <CardListDetail>Total: {teamClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <CardListContainer>
                  {teamClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </CardListContainer>
              </>
          : filteredTeamInvestigatingLeads !== undefined && filteredTeamClosingLeads !== undefined && sortLoaded ?
            filteredTeamClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  Team's Closing Leads
                  <CardListDetail>Filtered Total: {filteredTeamClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <CardListContainer>
                  {filteredTeamClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </CardListContainer>
              </>
          :
            <CardListContainer className="loading">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </CardListContainer>
        : dashPerspective === userId ?
          sortLoaded && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
            memberClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  My Closing Leads
                  <CardListDetail>Total: {memberClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <CardListContainer>
                  {memberClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </CardListContainer>
              </>
          : sortLoaded ?
            filteredMemberClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  My Closing Leads
                  <CardListDetail>Filtered Total: {filteredMemberClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <CardListContainer>
                  {filteredMemberClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </CardListContainer>
              </>
          :
            <CardListContainer className="loading">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </CardListContainer>
        :
          sortLoaded && leadTierFilter === 'All' && leadTypeFilter === 'All' && leadNewFilter === 'false' ?
            memberClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leads currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  {memberFullName}'s Closing Leads
                  <CardListDetail>Total: {memberClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <CardListContainer>
                  {memberClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </CardListContainer>
              </>
          : sortLoaded ?
            filteredMemberClosingLeads.length === 0 ?
              <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1', gridRow: '1/3'}}>
                <VerifiedUserIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
                <HeaderText style={{fontSize: '1.8rem', color: `${theme.palette.common.grayTextLight}`}}>no leadsre currently closing</HeaderText>
              </FlexWrapper>
            :
              <>
                <CardListHeader>
                  {memberFullName}'s Closing Leads
                  <CardListDetail>Filtered Total: {filteredMemberClosingLeads.length}</CardListDetail>
                </CardListHeader>
                <CardListContainer>
                  {filteredMemberClosingLeads.map((lead, index) => (
                    <Card cardIndex={index} leadStatusFilter={leadStatusFilter}  handleOpenLeadActionsModal={handleOpenLeadActionsModal} key={lead._id} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} lead={lead} />
                  ))}
                </CardListContainer>
              </>
          :
            <CardListContainer className="loading">
              <CircularProgress
                sx={{ color: `${theme.palette.primary.main}`}}
                size={48}
              />
            </CardListContainer>
        }
        <CardControlsContainer>
          <Controls leadStatusFilter={leadStatusFilter} handleLeadStatusFilterChange={handleLeadStatusFilterChange} leadCategorySort={leadCategorySort} handleLeadSortCategoryChange={handleLeadSortCategoryChange} leadSortMethod={leadSortMethod} handleLeadSortMethodChange={handleLeadSortMethodChange} leadTypeFilter={leadTypeFilter} leadTierFilter={leadTierFilter} handleLeadTierFilterChange={handleLeadTierFilterChange} handleLeadTypeFilterChange={handleLeadTypeFilterChange} leadNewFilter={leadNewFilter} handleLeadNewFilterChange={handleLeadNewFilterChange} leadTagFilter={leadTagFilter} handleLeadTagFilterChange={handleLeadTagFilterChange} teamLeadTags={teamLeadTags} memberAwaitingActionAmount={memberAwaitingActionLeads.length} memberInvestigatingAmount={memberInvestigatingLeads.length} memberClosingAmount={memberClosingLeads.length} memberAwaitingUpdateAmount={memberAwaitingUpdateLeads.length} teamAwaitingActionAmount={teamAwaitingActionLeads.length} teamInvestigatingAmount={teamInvestigatingLeads.length} teamClosingAmount={teamClosingLeads.length} teamAwaitingUpdateAmount={teamAwaitingUpdateLeads.length} dashPerspective={dashPerspective} />
        </CardControlsContainer>
      </PageContent>

      <Dialog open={openLeadActionsModal} onClose={handleCloseModal}
        PaperProps={{ sx: {width: "95%", minWidth: "140rem", maxWidth: "160rem", height: "fit-content", minHeight: "66rem", maxHeight: "95%"}}}
      >
        <LeadActionsModal handleCloseModal={handleCloseModal} userId={userId} userFullName={userFullName} dashPerspective={dashPerspective} leadUserNotifications={leadUserNotifications} setLeadUserNotifications={setLeadUserNotifications} teamId={teamId} leadStatusFilter={leadStatusFilter} />
      </Dialog>
    </>
  )
}

export default ActiveLeads;