import React from "react";
import styled from "@emotion/styled";
import Tooltip from '@mui/material/Tooltip';
import SellIcon from '@mui/icons-material/Sell';
import ErrorIcon from '@mui/icons-material/Error';
import AdjustIcon from '@mui/icons-material/Adjust';
import { useTheme } from "@mui/styles";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useDispatch, useSelector } from "react-redux";
import { selectLeadActionsNavSelection, setLeadActionNavMenuSelection } from "../../../slices/sessionDataSlice";
import { Button, FlexWrapper, NavIndex, NavText, NavigationContainer, SideBarNavHeader, Typography } from "../../../StyledComponents";

const LeadActionsNav = ({ mortgageData, reportsNumber, leadStatus, handleCloseModal }) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const navSelection = useSelector(selectLeadActionsNavSelection)
  
  const changeSelection = (content) => {
    dispatch( setLeadActionNavMenuSelection(content))
  }

  return (
    <NavigationContainer className="modal" style={{gridRow:'2/5'}}>
      <SideBarNavHeader 
        className="modal"
        style={{
          margin: mortgageData.financialsPresent ? '4.7rem 0 0.5rem 0' : '0 0 0.5rem 0',
          padding: mortgageData.financialsPresent ? '0.4rem 0 0.3rem 1.5rem' : '1.1rem 0 0.3rem 1.5rem',
        }}
      >
        Actions
      </SideBarNavHeader>
          {navSelection === 'reports' ?
            <ListItemNav selected top>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          : reportsNumber > 0 ?
            <ListItemNav onClick={() => changeSelection('reports')} top>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          :
            <ListItemNav top disabled>
              <NavIndex>
                <SummarizeIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Reports ({reportsNumber})</NavText>
            </ListItemNav>
          }
          {navSelection === 'notes' ?
            <ListItemNav selected>
              <NavIndex>
                <EditNoteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Notes ({mortgageData.mortgageNotes.length})</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('notes')}>
              <NavIndex>
                <EditNoteIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Notes ({mortgageData.mortgageNotes.length})</NavText>
            </ListItemNav>
          }
          {navSelection === 'compareRecords' ?
            <ListItemNav selected>
              <NavIndex>
                <FileCopyIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Compare and Update Records</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('compareRecords')}>
              <NavIndex>
                <FileCopyIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Compare and Update Records</NavText>
            </ListItemNav>
          }
          {navSelection === 'propertyDetails' ?
            <ListItemNav selected>
              <NavIndex>
                <HomeWorkIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Property Details</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('propertyDetails')}>
              <NavIndex>
                <HomeWorkIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View Property Details</NavText>
            </ListItemNav>
          }
          {!mortgageData.financialsPresent ?
            navSelection === 'provideTerms' || navSelection === 'provideFinancialsModal' ?
              <ListItemNav attention selected style={{padding: '0.1rem 0 0 0.3rem'}}>
                <NavIndex>
                  <AttachMoneyIcon fontSize="large" />
                </NavIndex>
                <NavText className="main">Provide Original Terms</NavText>
                <ErrorIcon style={{margin: '0 0 2.4rem 0.8rem', width: '1.2rem'}} />
                <NavText className="label" style={{margin: '0 0 2.4rem 0'}}>ToDo</NavText>
              </ListItemNav>
            :
              <ListItemNav attention onClick={() => changeSelection('provideTerms')} style={{padding: '0.1rem 0 0 0.3rem'}}>
                <NavIndex>
                  <AttachMoneyIcon fontSize="large" />
                </NavIndex>
                <NavText className="main">Provide Original Terms</NavText>
                <ErrorIcon style={{margin: '0 0 2.4rem 0.8rem', width: '1.2rem'}} />
                <NavText className="label" style={{margin: '0 0 2.4rem 0'}}>ToDo</NavText>
              </ListItemNav>
          :
            <></>
          }
          {navSelection === 'targetOutcome' ?
            <ListItemNav selected>
              <NavIndex>
                <FlagCircleIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Change Target Outcome</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('targetOutcome')}>
              <NavIndex>
              <FlagCircleIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Change Target Outcome</NavText>
            </ListItemNav>
          }
          {mortgageData.originalLoanAmount && mortgageData.activeLead ?
            navSelection === 'editTargets' ?
              <ListItemNav selected>
                <NavIndex>
                  <AdjustIcon fontSize="large" />
                </NavIndex>
                <NavText className="main">Change Refinance Targets</NavText>
              </ListItemNav>
            :
              <ListItemNav onClick={() => changeSelection('editTargets')}>
                <NavIndex>
                  <AdjustIcon fontSize="large" />
                </NavIndex>
                <NavText className="main">Change Refinance Targets</NavText>
              </ListItemNav>
          : mortgageData.activeLead ?
            <Tooltip
              componentsProps={{tooltip: {sx: {
                color: `${theme.palette.common.white}`,
                backgroundColor: `${theme.palette.common.black}`,
                fontSize: "2rem",
                margin: ' 0'
              }}}}
              PopperProps={{modifiers: [{
                name: "offset",
                options: {
                  offset: [0, -8],
                },
              }]}}
              title={<div style={{display: 'flex', flexDirection: 'column', justifyContent: "center", width: '14rem'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <p style={{fontSize: "1rem", fontWeight: "600", padding: "0 0.5rem", margin: '0'}}>no financial data provided</p>
                </div>
              </div>} 
              placement="right"
              arrow
            >
              <ListItemNav disabled>
                <NavIndex>
                  <AdjustIcon fontSize="large" />
                </NavIndex>
                <NavText className="main">Change Refinance Targets</NavText>
              </ListItemNav>
            </Tooltip>
          :
            <ListItemNav disabled>
              <NavIndex>
                <AdjustIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Change Refinance Targets</NavText>
            </ListItemNav>
          }
          {navSelection === 'editTags' ?
            <ListItemNav selected>
              <NavIndex>
                <SellIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Tags</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('editTags')}>
              <NavIndex>
                <SellIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">View/Add Tags</NavText>
            </ListItemNav>
          }
          {navSelection === 'addAssignees' ?
            <ListItemNav selected bottom>
              <NavIndex>
                <PersonAddIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Manage Assignees</NavText>
            </ListItemNav>
          :
            <ListItemNav onClick={() => changeSelection('addAssignees')} bottom>
              <NavIndex>
                <PersonAddIcon fontSize="large" />
              </NavIndex>
              <NavText className="main">Manage Assignees</NavText>
            </ListItemNav>
          }
          <FlexWrapper 
            className="column justifyCenter alignCenter fitContentHeight"
            style={{margin: mortgageData.financialsPresent ? '3rem 0 0 0' : 'auto 0'}}
          >
            {leadStatus === 'awaitingAction' ?
              <Button 
                style={{margin: '0 auto', width: '18rem'}} 
                className={navSelection === 'investigateLead' ? "primaryFocus disabled" : "primary" }
                disabled={navSelection === 'investigateLead'}
                onClick={navSelection === 'investigateLead' ? null : () => changeSelection('investigateLead')}
              >
                <Typography className="small">Open Investigation</Typography>
              </Button>
            : leadStatus === 'investigating' ?
              <Button 
                style={{margin: '0 auto', width: '18rem'}} 
                className={navSelection === 'beginClosingLead' ? "primaryFocus disabled" : "primary" }
                disabled={navSelection === 'beginClosingLead'}
                onClick={navSelection === 'beginClosingLead' ? null : () => changeSelection('beginClosingLead')}
              >
                <Typography className="small">Designate as Closing</Typography>
              </Button>
            : leadStatus === 'closing' ?
              <Button 
                style={{margin: '0 auto', width: '18rem'}} 
                className={navSelection === 'finalizeLead' ? "primaryFocus disabled" : "primary" }
                disabled={navSelection === 'finalizeLead'}
                onClick={navSelection === 'finalizeLead' ? null : () => changeSelection('finalizeLead')}
              >
                <Typography className="small">Finalize</Typography>
              </Button>
            : leadStatus === 'awaitingUpdate' ?
              <Button 
                style={{margin: '0 auto', width: '18rem'}} 
                className={navSelection === 'confirmUpdate' ? "primaryFocus disabled" : "primary" }
                disabled={navSelection === 'confirmUpdate'}
                onClick={navSelection === 'confirmUpdate' ? null : () => changeSelection('confirmUpdate')}
              >
                <Typography className="small">Confirm Updates</Typography>
              </Button>
            :
              <></>
            }
            <Button 
              style={{margin: '0.5rem auto 0 auto', width: '18rem', height: '3rem'}} 
              className={navSelection === 'dismissLead' ? "dismissFocus disabled" : "dismiss" }
              disabled={navSelection === 'dismissLead'}
              onClick={navSelection === 'dismissLead' ? null : () => changeSelection('dismissLead')}
            >
              <Typography className="small">
                {leadStatus === 'awaitingAction' ?
                  `Dismiss Lead`
                :
                  `Dismiss Investigation`
                }
              </Typography>
            </Button>
            <Button 
              className="neutral"
              style={{margin: mortgageData.financialsPresent ? '2rem auto 0 auto' : '0.5rem auto 0 auto', width: '18rem'}}
              onClick={() => handleCloseModal()}
            >
              <Typography className="small">Close Window</Typography>
            </Button>
          </FlexWrapper>
    </NavigationContainer>
  )
}

const ListItemNav = styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 0;
  padding: 0.5rem 0 0.5rem 0.3rem;
  margin: 0;
  background-color: ${(props) => props.attention && props.selected ? '#00bc1d94' : props.attention ? `#00b8125e` : props.selected && !props.disabled ? "#4B14752B" : props.disabled ? "none" : "none"};
  cursor: ${(props) => props.selected || props.noCursor ? "default" : props.disabled ? 'not-allowed' : "pointer"};
  color: ${(props) => props.disabled ? '#939599' : '#00B812'};
  border-top: ${(props) => props.top ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  border-bottom: ${(props) => props.bottom ? "0.2rem solid #939599" : "0.1rem solid #939599"};
  &:hover {
    background-color: ${(props) => props.attention && props.selected ? '#00ab2b8c' : props.attention ? '#00bc1d94' : props.selected && !props.disabled ? "#4B147533" : props.disabled ? "#E0E0E0" : "#F2F2F2"};
  }
`

export default LeadActionsNav;