import React, { useContext, useEffect, useState } from "react"
import "./App.css"
import axios from 'axios'
import Login from "./pages/LoginPortal/Login"
import Error from "./pages/Error"
import Launch from "./pages/LoginPortal/Launch"
import SuperDash from "./pages/Super/SuperDash"
import Dashboard from "./pages/Dashboard/Dashboard"
import lightTheme from "./theme"
import ScreenSaver from "./pages/ScreenSaver"
import { AppState } from "./AppState"
import { setLoginEnabled } from "./slices/functionAvailabilitySlice"
import ExcludeMobileVisitor from "./pages/ExcludeMobileVisitor"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material"
import { setUserReqCredentials, selectUserId, selectTeamId } from "./slices/userInfoSlice"
import { useEstablishRequestCredentialsMutation, useLogReturningVisitMutation } from "./slices/api/apiSlice"
import { selectAppError, selectExcludeMobileVisitor, selectIPAcquired, selectLaunchRequired, selectSuperAuthenticated, selectTheme, selectUserAuthenticated, setAppError, setAppLoaded, setExcludeMobileVisitor, setIPAcquired, setIPError, setLaunchRequired } from "./slices/sessionDataSlice"

const client = new ApolloClient({
  cache: new InMemoryCache(),
});

const App = () => {
  const dispatch = useDispatch()
  const { user, sessionRestored } = useContext(AppState)

  const teamId = useSelector(selectTeamId)
  const userId = useSelector(selectUserId)
  const appError = useSelector(selectAppError)
  const ipAcquired = useSelector(selectIPAcquired)
  const selectedTheme = useSelector(selectTheme)
  const launchRequired = useSelector(selectLaunchRequired)
  const userAuthenticated = useSelector(selectUserAuthenticated)
  const superAuthenticated = useSelector(selectSuperAuthenticated)
  const excludeMobileVisitor = useSelector(selectExcludeMobileVisitor)

  const [logReturningVisit] = useLogReturningVisitMutation()
  const [establishRequestCredentials] = useEstablishRequestCredentialsMutation()

  const [width, setWidth] = useState(window.innerWidth)

  //TODO: change 'lead tags' to 'violation tags'
  //TODO: make better assignment/presentation of mortgage tags
  //TODO: must maintain sort after status or a lead is changed
  //TODO: change date from being done here to using Moment on the back end
  //TODO: figure out time issues 
  //TODO: team profit for closures needs work
  //TODO: change "profitPercent" to "revenueIncrease". Should be clearer for users.
  //TODO: change "investigating" to "under review" in appropriate circumstances
  //TODO: test negative refinances on stats overview
  //TODO: paginate the active leads list?
  //TODO: add functionality to 'refresh' target stats based on elapsed time. Make it so that at certain workflow 'milestone', this is performed automatically (this already happens with refinances going to closing)
  //TODO: check all validation to ensure invalid characters are not sent

  useEffect(() => {
    if (sessionRestored && !launchRequired) {
      try {
        const fetchCredentials = async() => {
          const reqIP = await axios.get("https://api.ipify.org/?format=json")
          const visitorCredentials = await establishRequestCredentials({reqIP: reqIP.data.ip})
          if (visitorCredentials.data.message.includes('success')) {
            if (width < 900) {
              dispatch( setLoginEnabled(false) )
              dispatch( setExcludeMobileVisitor(true) )
            } else if (!visitorCredentials.data.data.existingTeam) {
              dispatch( setLaunchRequired(true) )
              dispatch( setLoginEnabled(false) )
            } else if (user) {
              dispatch( setLoginEnabled(true) )
              dispatch( setUserReqCredentials({protocol: visitorCredentials.data.data.reqCredentials.reqIP, location: visitorCredentials.data.data.reqCredentials.reqLocation, isp: visitorCredentials.data.data.reqCredentials.reqISP, mobile: visitorCredentials.data.data.reqCredentials.reqMobile, proxy: visitorCredentials.data.data.reqCredentials.reqProxy}) )
              let returningVisit = await logReturningVisit({
                userId: user.userId,
                reqIP: visitorCredentials.data.data.reqCredentials.reqIP,
                reqLocation: visitorCredentials.data.data.reqCredentials.reqLocation,
                reqISP: visitorCredentials.data.data.reqCredentials.reqISP,
                reqProxy: visitorCredentials.data.data.reqCredentials.reqProxy,
                reqMobile: visitorCredentials.data.data.reqCredentials.reqMobile,
                reqUserAgent: window.navigator.userAgent,
                reqUserAgentData: window.navigator.userAgentData,
              })
              if (returningVisit.error) {
                dispatch( setAppError(true) )
              } else if (returningVisit.data.message.includes("Failed")) {
                localStorage.clear()
              }
            } else {
              dispatch( setLoginEnabled(true) )
            }
            dispatch( setIPAcquired(true) )
            dispatch( setAppLoaded(true) )
          } else {
            dispatch( setIPError(true) )
            dispatch( setIPAcquired(false) )
          }
        }
        fetchCredentials()
      } catch (error) {
        dispatch( setAppError(true) )
      }
    }
  }, [sessionRestored, dispatch, establishRequestCredentials, launchRequired, logReturningVisit, user])

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ApolloProvider client={client}>
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={selectedTheme === 'light' ? lightTheme : lightTheme }>
            <CssBaseline />
            {appError ? (
              <Routes>
                <Route
                  path="*"
                  element={<Error />}
                />
              </Routes>
            ) : launchRequired ? (
              <Routes>
                <Route
                  path="*"
                  element={<Launch />}
                />
              </Routes>
            ) : excludeMobileVisitor ? (
              <Routes>
                <Route
                  path="*"
                  element={<ExcludeMobileVisitor />}
                />
              </Routes>
            ) : userAuthenticated ? (
              <Routes>
                <Route
                  path="/dashboard/activeLeads"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'activeLeads'} />}
                />
                <Route
                  path="/dashboard/leadGeneration"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'leadGeneration'} />}
                />
                <Route
                  path="/dashboard/renegotiationsOverview"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'renegotiationsOverview'} />}
                />
                <Route
                  path="/dashboard/refinancesOverview"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'refinancesOverview'} />}
                />
                <Route
                  path="/dashboard/memberPerformance"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'memberPerformance'} />}
                />
                <Route
                  path="/dashboard/mortgageRepository"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'mortgageRepository'} />}
                />
                <Route
                  path="/dashboard/configuration"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'configuration'} />}
                />
                <Route
                  path="/dashboard/propertySearch"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'propertySearch'} />}
                />
                <Route
                  path="/dashboard/reports"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'reports'} />}
                />
                <Route
                  path="/dashboard/notifications"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'notifications'} />}
                />
                <Route
                  path="/dashboard/settings"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'settings'} />}
                />
                <Route
                  path="/dashboard/guide"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'guide'} />}
                />
                <Route
                  path="*"
                  element={<Dashboard teamId={teamId} userId={userId} subPage={'guide'} />}
                />
                {superAuthenticated &&
                  <>
                    <Route
                      path="/admin/uploads"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'uploads'} />}
                    />
                    <Route
                      path="/admin/repository"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'repository'} />}
                    />
                    <Route
                      path="/admin/recordSweeps"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'recordSweeps'} />}
                    />
                    <Route
                      path="/admin/log"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'log'} />}
                    />
                    <Route
                      path="/admin/database"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'database'} />}
                    />
                    <Route
                      path="/admin/scheduledTasks"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'scheduledTasks'} />}
                    />
                    <Route
                      path="/admin/dataModel"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'dataModel'} />}
                    />
                    <Route
                      path="/admin/appSettings"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'appSettings'} />}
                    />
                    <Route
                      path="/admin"
                      element={<SuperDash teamId={teamId} userId={userId} subPage={'uploads'} />}
                    />
                  </>
                }
              </Routes>
            ) : ipAcquired ? (
              <Routes>
                <Route
                  path="*"
                  element={<Login />}
                />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={<ScreenSaver />}
                />
              </Routes>
            )}
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </ApolloProvider>
  )
}

export default App