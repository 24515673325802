import React, { Component, useState } from "react";
import 'react-csv-importer/dist/index.css';
import StopIcon from '@mui/icons-material/Stop';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RecordRow from "./CompareRecords/RecordRow";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CircularProgress from "@mui/material/CircularProgress";
import { useResolveAllLeadDiscrepanciesMutation } from "../../../../slices/api/apiSlice";
import { selectResolveAllMortgageDiscrepanciesPerm } from "../../../../slices/userActionsPermissionsSlice";
import { FlexWrapper, CompareDetail, ListRow, ModalContentContainer, DetailWrapper, CompareLink, ComponentSectionRow, ColumnContent, AccentInfo, ComponentHeader, ScrollWindow, Typography } from "../../../../StyledComponents";

const CompareRecords = ({ mortgageData, dashPerspective, userId, userFullName, setLeadUserNotifications, leadUserNotifications, leadData, teamId, location }) => {
  const theme = useTheme()

  const resolveAllMortgageDiscrepanciesPerm = useSelector(selectResolveAllMortgageDiscrepanciesPerm)

  const [resolveAllDiscrepancies, {isLoading, isSuccess, isError}] = useResolveAllLeadDiscrepanciesMutation()

  const [fieldEditing, setFieldEditing] = useState('')
  const [workflowStage, setWorkflowStage] = useState('')
  const [noChanges, setNoChanges] = useState(false)
  const [resolveAllWorkflow, setResolveAllWorkflow] = useState(false)

  const handleWorkFlow = (workflow, field) => {
    setWorkflowStage(workflow)
    setFieldEditing(field)
    setNoChanges(false)
  }
  const handleResolveAll = async () => {
    await resolveAllDiscrepancies({
      userId: userId,
      userFullName: userFullName,
      teamId: teamId,
      dashPerspective: dashPerspective,
      mortgageId: mortgageData._id,
      mission: 'single'
    })
  }

  return (
    <>
      <FlexWrapper className="column" style={{gridRow: '2/4', gridColumn: '3'}}>
        <ComponentSectionRow className="header" style={{padding: '0.4rem 0', borderBottom: `0.1rem solid ${theme.palette.common.grayText}`}}>
          <ColumnContent className="column">
            <FlexWrapper className="alignCenter justifyCenter"><StopIcon style={{color: `${theme.palette.common.redDark}`}}/> active discrepancy</FlexWrapper>
            <span>total: <span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{leadData.activeDiscrepancies}</span></span>
          </ColumnContent>
          <ColumnContent className="column">
            <FlexWrapper className="alignCenter justifyCenter"><StopIcon style={{color: `${theme.palette.common.orange}`}} /> manually edited but contradicts public record</FlexWrapper>
            <span>total: <span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{leadData.rejectedDiscrepancies}</span></span>
          </ColumnContent>
          <ColumnContent className="column">
            <FlexWrapper className="alignCenter justifyCenter"><StopIcon style={{color: `${theme.palette.primary.dark}`}} /> resolved discrepancy</FlexWrapper>
            <span>total: <span style={{fontWeight: '600', marginLeft: '0.3rem'}}>{leadData.resolvedDiscrepancies}</span></span>
          </ColumnContent>
        </ComponentSectionRow>
        <ComponentSectionRow style={{background: `${theme.palette.common.grayBorderMedium}`, padding: '0.3rem 0'}}>
          {leadData.activeDiscrepancies > 0 ?
            resolveAllWorkflow && !isLoading ?
              <div style={{margin: '0 0 0 4.8rem', padding: '0.15rem 0'}}>
                <FlexWrapper className="alignCenter">
                  <span style={{width: '13.5rem'}}>Resolve all discrepancies?</span>
                  <CompareLink className="small green" onClick={() => handleResolveAll()} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0.3rem 0 0', width: '1.7rem'}}>
                    <CheckIcon style={{height: '1rem', width: '1rem'}} />
                  </CompareLink>
                  <CompareLink className="small gray" onClick={() => setResolveAllWorkflow(false)} style={{height: '1.7rem', fontSize: '1rem', margin: '0 0 0 0', width: '1.7rem'}}>
                    <ClearIcon style={{height: '1rem', width: '1rem'}} />
                  </CompareLink>
                </FlexWrapper>
              </div>
            : resolveAllWorkflow && isLoading ?
              <div style={{margin: '0 0 0 12.5rem', padding: '0.4rem 8rem 0.4rem 0'}}>
                <FlexWrapper className="alignCenter">
                  <CircularProgress style={{height: '1.2rem', width: '1.2rem', color: `${theme.palette.primary.main}`}} />
                </FlexWrapper>
              </div>
            :
              <div style={{margin: '0 0 0 8.5rem', padding: '0 4rem 0 0'}}>
                <DetailWrapper className={resolveAllMortgageDiscrepanciesPerm ? "addButton" : "addButtonDisabled"} onClick={resolveAllMortgageDiscrepanciesPerm ? () => setResolveAllWorkflow(true) : null} style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', gridColumn: '2', margin: 'auto 0 auto auto'}}>
                  Resolve All
                  <AutoFixHighIcon style={{height: '1.4rem', width: '1.4rem', color: `${theme.palette.primary.main}`, margin: '0 0 0 0.3rem'}} /> 
                </DetailWrapper>
              </div>
          :
            <div style={{margin: '0 0 0 8.5rem', padding: '0 4rem 0 0'}}>
              <DetailWrapper className="addButtonDisabled" style={{width: 'fit-content', fontSize: '0.8rem', textWrap: 'nowrap', gridColumn: '2', margin: 'auto 0 auto auto'}}>
                Resolve All
                <AutoFixHighIcon style={{height: '1.4rem', width: '1.4rem', color: `${theme.palette.primary.main}`, margin: '0 0 0 0.3rem'}} /> 
              </DetailWrapper>
            </div>
          }
          <FlexWrapper className="alignCenter justifyCenter" style={{width: '100%'}}>
            <DetailWrapper className="one">Tier 1 Discrepancy</DetailWrapper>
            <DetailWrapper className="two" style={{margin: '0 0.4rem'}}>Tier 2 Discrepancy</DetailWrapper>
            <DetailWrapper className="three">Tier 3 Discrepancy</DetailWrapper>
          </FlexWrapper>
        </ComponentSectionRow>
        <ComponentSectionRow className="header gray" style={{display: 'grid', gridTemplateColumns: `0rem 20rem 1fr 1fr 5rem`}}>
          <Typography className="header justifyCenter info" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`, padding: '0.6rem'}}>
            Public Record
          </Typography>
          <Typography className="header justifyCenter info" style={{gridColumn: '4', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`, padding: '0.6rem'}}>
            Internal Record
          </Typography>
        </ComponentSectionRow>
      </FlexWrapper>

      <ScrollWindow className="verticalOnly" style={{gridRow: '4'}}>
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Address
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.address.StreetAddressOneLine &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StreetAddressOneLine'} valueType={'string'} record={mortgageData.recordDetails.address.StreetAddressOneLine} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.StreetAddressLine1 &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StreetAddressLine1'} valueType={'string'} record={mortgageData.recordDetails.address.StreetAddressLine1} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.StreetAddressLine2 &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StreetAddressLine2'} valueType={'string'} record={mortgageData.recordDetails.address.StreetAddressLine2} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.StreetNumber &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StreetNumber'} valueType={'number'} record={mortgageData.recordDetails.address.StreetNumber} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.UnitPrefix &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'UnitPrefix'} valueType={'string'} record={mortgageData.recordDetails.address.UnitPrefix} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.UnitNumber &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'UnitNumber'} valueType={'number'} record={mortgageData.recordDetails.address.UnitNumber} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.StreetDirPrefix &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StreetDirPrefix'} valueType={'string'} record={mortgageData.recordDetails.address.StreetDirPrefix} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.StreetName &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StreetName'} valueType={'string'} record={mortgageData.recordDetails.address.StreetName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.StreetSuffix &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StreetSuffix'} valueType={'string'} record={mortgageData.recordDetails.address.StreetSuffix} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.StreetDirSuffix &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StreetDirSuffix'} valueType={'string'} record={mortgageData.recordDetails.address.StreetDirSuffix} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.City &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'City'} valueType={'string'} record={mortgageData.recordDetails.address.City} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.StateOrProvince &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'StateOrProvince'} valueType={'string'} record={mortgageData.recordDetails.address.StateOrProvince} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.PostalCode &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'PostalCode'} valueType={'number'} record={mortgageData.recordDetails.address.PostalCode} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.address.PostalCodePlus4 &&
          <RecordRow location={location} recordFieldRoot={'address'} recordField={'PostalCodePlus4'} valueType={'number'} record={mortgageData.recordDetails.address.PostalCodePlus4} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Primary Owner
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.owner1.Owner1OwnershipRights &&
          <RecordRow location={location} recordFieldRoot={'owner1'} recordField={'Owner1OwnershipRights'} valueType={'string'} record={mortgageData.recordDetails.owner1.Owner1OwnershipRights} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.owner1.Owner1IsCorporation &&
          <RecordRow location={location} recordFieldRoot={'owner1'} recordField={'Owner1IsCorporation'} valueType={'boolean'} record={mortgageData.recordDetails.owner1.Owner1IsCorporation} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.owner1.CorporateIndicator &&
          <RecordRow location={location} recordFieldRoot={'owner1'} recordField={'CorporateIndicator'} valueType={'boolean'} record={mortgageData.recordDetails.owner1.CorporateIndicator} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.owner1.Owner1FullName &&
          <RecordRow location={location} recordFieldRoot={'owner1'} recordField={'Owner1FullName'} valueType={'string'} record={mortgageData.recordDetails.owner1.Owner1FullName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.owner1.Owner1LastName &&
          <RecordRow location={location} recordFieldRoot={'owner1'} recordField={'Owner1LastName'} valueType={'string'} record={mortgageData.recordDetails.owner1.Owner1LastName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.owner1.Owner1FirstName &&
          <RecordRow location={location} recordFieldRoot={'owner1'} recordField={'Owner1FirstName'} valueType={'string'} record={mortgageData.recordDetails.owner1.Owner1FirstName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Secondary Owner
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.owner2.Owner2IsCorporation &&
          <RecordRow location={location} recordFieldRoot={'owner2'} recordField={'Owner2IsCorporation'} valueType={'boolean'} record={mortgageData.recordDetails.owner2.Owner2IsCorporation} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.owner2.Owner2FullName &&
          <RecordRow location={location} recordFieldRoot={'owner2'} recordField={'Owner2FullName'} valueType={'string'} record={mortgageData.recordDetails.owner2.Owner2FullName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.owner2.Owner2LastName &&
          <RecordRow location={location} recordFieldRoot={'owner2'} recordField={'Owner2LastName'} valueType={'string'} record={mortgageData.recordDetails.owner2.Owner2LastName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.owner2.Owner2FirstName &&
          <RecordRow location={location} recordFieldRoot={'owner2'} recordField={'Owner2FirstName'} valueType={'string'} record={mortgageData.recordDetails.owner2.Owner2FirstName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Sale
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.sale.LeaseholdOrFeeSimple &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LeaseholdOrFeeSimple'} valueType={'string'} record={mortgageData.recordDetails.sale.LeaseholdOrFeeSimple} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead}  />
        }
        {mortgageData.recordDetails.sale.LastSaleRecordingDate &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleRecordingDate'} valueType={'date'} record={mortgageData.recordDetails.sale.LastSaleRecordingDate} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSaleContractDate &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleContractDate'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleContractDate} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSaleDate &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleDate'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleDate} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSalePrice &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSalePrice'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSalePrice} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.ClosePrice &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'ClosePrice'} valueType={'string'} record={mortgageData.recordDetails.sale.ClosePrice} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.ClosePriceDescription &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'ClosePriceDescription'} valueType={'string'} record={mortgageData.recordDetails.sale.ClosePriceDescription} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSaleType &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleType'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead}  />
        }
        {mortgageData.recordDetails.sale.LastSaleTransactionId &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleTransactionId'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleTransactionId} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSaleBuyerName &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleBuyerName'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleBuyerName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead}  />
        }
        {mortgageData.recordDetails.sale.SaleDocType &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'SaleDocType'} valueType={'string'} record={mortgageData.recordDetails.sale.SaleDocType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSaleRecordingDocumentId &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleRecordingDocumentId'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleRecordingDocumentId} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSaleDocumentType &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleDocumentType'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleDocumentType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSaleSeller1FullName &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleSeller1FullName'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleSeller1FullName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.sale.LastSaleSeller2FullName &&
          <RecordRow location={location} recordFieldRoot={'sale'} recordField={'LastSaleSeller2FullName'} valueType={'string'} record={mortgageData.recordDetails.sale.LastSaleSeller2FullName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Building
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.building.BuildingQualityScore &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'BuildingQualityScore'} valueType={'string'} record={mortgageData.recordDetails.building.BuildingQualityScore} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.BuildingCondition &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'BuildingCondition'} valueType={'number'} record={mortgageData.recordDetails.building.BuildingCondition} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.YearBuilt &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'YearBuilt'} valueType={'number'} record={mortgageData.recordDetails.building.YearBuilt} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.YearBuiltEffective &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'YearBuiltEffective'} valueType={'number'} record={mortgageData.recordDetails.building.YearBuiltEffective} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.NumberOfBuildings &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'NumberOfBuildings'} valueType={'string'} record={mortgageData.recordDetails.building.NumberOfBuildings} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.NumberOfUnitsTotal &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'NumberOfUnitsTotal'} valueType={'string'} record={mortgageData.recordDetails.building.NumberOfUnitsTotal} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.ArchitecturalStyle &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'ArchitecturalStyle'} valueType={'string'} record={mortgageData.recordDetails.building.ArchitecturalStyle} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.ConstructionType &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'ConstructionType'} valueType={'string'} record={mortgageData.recordDetails.building.ConstructionType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.FrameType &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'FrameType'} valueType={'number'} record={mortgageData.recordDetails.building.FrameType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.ExteriorWallsType &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'ExteriorWallsType'} valueType={'number'} record={mortgageData.recordDetails.building.ExteriorWallsType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.InteriorWallsType &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'InteriorWallsType'} valueType={'number'} record={mortgageData.recordDetails.building.InteriorWallsType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.RoofType &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'RoofType'} valueType={'number'} record={mortgageData.recordDetails.building.RoofType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.RoofCoverType &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'RoofCoverType'} valueType={'number'} record={mortgageData.recordDetails.building.RoofCoverType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.CarStorageType &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'CarStorageType'} valueType={'string'} record={mortgageData.recordDetails.building.CarStorageType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.GarageSpaces &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'GarageSpaces'} valueType={'string'} record={mortgageData.recordDetails.building.GarageSpaces} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.GarageArea &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'GarageArea'} valueType={'string'} record={mortgageData.recordDetails.building.GarageArea} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.building.ParkingSpaces &&
          <RecordRow location={location} recordFieldRoot={'building'} recordField={'ParkingSpaces'} valueType={'number'} record={mortgageData.recordDetails.building.ParkingSpaces} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Living
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.living.BuildingAreaTotal &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'BuildingAreaTotal'} valueType={'number'} record={mortgageData.recordDetails.living.BuildingAreaTotal} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.GrossArea &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'GrossArea'} valueType={'number'} record={mortgageData.recordDetails.living.GrossArea} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.LivingArea &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'LivingArea'} valueType={'number'} record={mortgageData.recordDetails.living.LivingArea} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.Stories &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'Stories'} valueType={'string'} record={mortgageData.recordDetails.living.Stories} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.RoomsTotal &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'RoomsTotal'} valueType={'number'} record={mortgageData.recordDetails.living.RoomsTotal} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.BedroomsTotal &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'BedroomsTotal'} valueType={'number'} record={mortgageData.recordDetails.living.BedroomsTotal} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.BathroomsFull &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'BathroomsFull'} valueType={'number'} record={mortgageData.recordDetails.living.BathroomsFull} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.BathroomsTotalInteger &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'BathroomsTotalInteger'} valueType={'number'} record={mortgageData.recordDetails.living.BathroomsTotalInteger} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.FireplacesTotal &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'FireplacesTotal'} valueType={'string'} record={mortgageData.recordDetails.living.FireplacesTotal} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.Heating &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'Heating'} valueType={'string'} record={mortgageData.recordDetails.living.Heating} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.Cooling &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'Cooling'} valueType={'string'} record={mortgageData.recordDetails.living.Cooling} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.living.PoolType &&
          <RecordRow location={location} recordFieldRoot={'living'} recordField={'PoolType'} valueType={'number'} record={mortgageData.recordDetails.living.PoolType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Assessment
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.assessment.AssessedYear &&
          <RecordRow location={location} recordFieldRoot={'assessment'} recordField={'AssessedYear'} valueType={'number'} record={mortgageData.recordDetails.assessment.AssessedYear} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.assessment.AssessedValue &&
          <RecordRow location={location} recordFieldRoot={'assessment'} recordField={'AssessedValue'} valueType={'number'} record={mortgageData.recordDetails.assessment.AssessedValue} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.assessment.AssessedLandValue &&
          <RecordRow location={location} recordFieldRoot={'assessment'} recordField={'AssessedLandValue'} valueType={'number'} record={mortgageData.recordDetails.assessment.AssessedLandValue} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.assessment.AssessedImprovementValue &&
          <RecordRow location={location} recordFieldRoot={'assessment'} recordField={'AssessedImprovementValue'} valueType={'number'} record={mortgageData.recordDetails.assessment.AssessedImprovementValue} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.assessment.AssessorsMapReference &&
          <RecordRow location={location} recordFieldRoot={'assessment'} recordField={'AssessorsMapReference'} valueType={'string'} record={mortgageData.recordDetails.assessment.AssessorsMapReference} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Lot
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.lot.County &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'County'} valueType={'string'} record={mortgageData.recordDetails.lot.County} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.Municipality &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'Municipality'} valueType={'string'} record={mortgageData.recordDetails.lot.Municipality} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.LegalSubdivisionName &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'LegalSubdivisionName'} valueType={'string'} record={mortgageData.recordDetails.lot.LegalSubdivisionName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.ParcelNumber &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'ParcelNumber'} valueType={'string'} record={mortgageData.recordDetails.lot.ParcelNumber} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.Latitude &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'Latitude'} valueType={'number'} record={mortgageData.recordDetails.lot.Latitude} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.Longitude &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'Longitude'} valueType={'number'} record={mortgageData.recordDetails.lot.Longitude} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.LotSizeAcres &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'LotSizeAcres'} valueType={'number'} record={mortgageData.recordDetails.lot.LotSizeAcres} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.LotSizeSquareFeet &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'LotSizeSquareFeet'} valueType={'number'} record={mortgageData.recordDetails.lot.LotSizeSquareFeet} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.Zoning &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'Zoning'} valueType={'string'} record={mortgageData.recordDetails.lot.Zoning} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.CountyLandUseCode &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'CountyLandUseCode'} valueType={'number'} record={mortgageData.recordDetails.lot.CountyLandUseCode} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.BelowGradeTotalArea &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'BelowGradeTotalArea'} valueType={'number'} record={mortgageData.recordDetails.lot.BelowGradeTotalArea} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.BelowGradeFinishedArea &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'BelowGradeFinishedArea'} valueType={'number'} record={mortgageData.recordDetails.lot.BelowGradeFinishedArea} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.LandUseCode &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'LandUseCode'} valueType={'number'} record={mortgageData.recordDetails.lot.LandUseCode} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.CountyUseCode &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'CountyUseCode'} valueType={'number'} record={mortgageData.recordDetails.lot.CountyUseCode} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.TaxLot &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'TaxLot'} valueType={'string'} record={mortgageData.recordDetails.lot.TaxLot} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.TaxBlock &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'TaxBlock'} valueType={'number'} record={mortgageData.recordDetails.lot.TaxBlock} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.TaxCodeArea &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'TaxCodeArea'} valueType={'number'} record={mortgageData.recordDetails.lot.TaxCodeArea} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.lot.CensusTractId &&
          <RecordRow location={location} recordFieldRoot={'lot'} recordField={'CensusTractId'} valueType={'number'} record={mortgageData.recordDetails.lot.CensusTractId} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            summary
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.summary.WaterSource &&
          <RecordRow location={location} recordFieldRoot={'summary'} recordField={'WaterSource'} valueType={'string'} record={mortgageData.recordDetails.summary.WaterSource} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.summary.SewerType &&
          <RecordRow location={location} recordFieldRoot={'summary'} recordField={'SewerType'} valueType={'string'} record={mortgageData.recordDetails.summary.SewerType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.summary.SchoolDistrict &&
          <RecordRow location={location} recordFieldRoot={'summary'} recordField={'SchoolDistrict'} valueType={'string'} record={mortgageData.recordDetails.summary.SchoolDistrict} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.summary.PropertyType &&
          <RecordRow location={location} recordFieldRoot={'summary'} recordField={'PropertyType'} valueType={'string'} record={mortgageData.recordDetails.summary.PropertyType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.summary.PropertySubType &&
          <RecordRow location={location} recordFieldRoot={'summary'} recordField={'PropertySubType'} valueType={'string'} record={mortgageData.recordDetails.summary.PropertySubType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.summary.QuitClaimFlag &&
          <RecordRow location={location} recordFieldRoot={'summary'} recordField={'QuitClaimFlag'} valueType={'boolean'} record={mortgageData.recordDetails.summary.QuitClaimFlag} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.summary.REOflag &&
          <RecordRow location={location} recordFieldRoot={'summary'} recordField={'REOflag'} valueType={'boolean'} record={mortgageData.recordDetails.summary.REOflag} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.summary.DistressYN &&
          <RecordRow location={location} recordFieldRoot={'summary'} recordField={'DistressYN'} valueType={'string'} record={mortgageData.recordDetails.summary.DistressYN} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            Tax
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.tax.TaxYear &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxYear'} valueType={'number'} record={mortgageData.recordDetails.tax.TaxYear} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxAnnualAmount &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxAnnualAmount'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxAnnualAmount} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.OwnerOccupied &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'OwnerOccupied'} valueType={'boolean'} record={mortgageData.recordDetails.tax.OwnerOccupied} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionHomestead &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionHomestead'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionHomestead} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionVeteran &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionVeteran'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionVeteran} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionDisabled &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionDisabled'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionDisabled} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionWidow &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionWidow'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionWidow} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionSenior &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionSenior'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionSenior} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionSchoolCollege &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionSchoolCollege'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionSchoolCollege} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionReligious &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionReligious'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionReligious} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionWelfare &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionWelfare'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionWelfare} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionPublicUtility &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionPublicUtility'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionPublicUtility} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionCemetery &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionCemetery'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionCemetery} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionHospital &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionHospital'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionHospital} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxExemptionLibrary &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxExemptionLibrary'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxExemptionLibrary} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.tax.TaxInitialDeliquencyYear &&
          <RecordRow location={location} recordFieldRoot={'tax'} recordField={'TaxInitialDeliquencyYear'} valueType={'string'} record={mortgageData.recordDetails.tax.TaxInitialDeliquencyYear} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        <ListRow>
          <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
            {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageAmount && mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageAmount.currentValue > 0 ?
              `Primary Mortgage`
            :
              `Current Mortgage`
            }
          </ComponentHeader>
        </ListRow>
        {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageAmount &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'PrimaryMortgageAmount'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.PrimaryMortgageAmount} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageInterestRate &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'PrimaryMortgageInterestRate'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.PrimaryMortgageInterestRate} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.PrimaryLenderName &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'PrimaryLenderName'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.PrimaryLenderName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageStartDate &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'PrimaryMortgageStartDate'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.PrimaryMortgageStartDate} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.PrimaryLoanType &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'PrimaryLoanType'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.PrimaryLoanType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageTerm &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'PrimaryMortgageTerm'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.PrimaryMortgageTerm} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.PrimaryMortgageDueDate &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'PrimaryMortgageDueDate'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.PrimaryMortgageDueDate} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.TitleCompany &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'TitleCompany'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.TitleCompany} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.OwnershipRights &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'OwnershipRights'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.OwnershipRights} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.primaryMortgage.BorrowerRelationship &&
          <RecordRow location={location} recordFieldRoot={'primaryMortgage'} recordField={'BorrowerRelationship'} valueType={'string'} record={mortgageData.recordDetails.primaryMortgage.BorrowerRelationship} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
        }
        {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageAmount && mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageAmount.currentValue > 0 ?
          <>
            <ListRow>
              <ComponentHeader className="listSection" style={{gridColumn: '1/7'}}>
                Secondary Mortgage
              </ComponentHeader>
            </ListRow>
            {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageAmount &&
              <RecordRow location={location} recordFieldRoot={'secondaryMortgage'} recordField={'SecondaryMortgageAmount'} valueType={'string'} record={mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageAmount} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
            }
            {mortgageData.recordDetails.secondaryMortgage.SecondaryLenderName &&
              <RecordRow location={location} recordFieldRoot={'secondaryMortgage'} recordField={'SecondaryLenderName'} valueType={'string'} record={mortgageData.recordDetails.secondaryMortgage.SecondaryLenderName} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
            }
            {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageRecordingDate &&
              <RecordRow location={location} recordFieldRoot={'secondaryMortgage'} recordField={'SecondaryMortgageRecordingDate'} valueType={'string'} record={mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageRecordingDate} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
            }
            {mortgageData.recordDetails.secondaryMortgage.SecondaryLoanType &&
              <RecordRow location={location} recordFieldRoot={'secondaryMortgage'} recordField={'SecondaryLoanType'} valueType={'string'} record={mortgageData.recordDetails.secondaryMortgage.SecondaryLoanType} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
            }
            {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageTerm &&
              <RecordRow location={location} recordFieldRoot={'secondaryMortgage'} recordField={'SecondaryMortgageTerm'} valueType={'string'} record={mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageTerm} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
            }
            {mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageDueDate &&
              <RecordRow location={location} recordFieldRoot={'secondaryMortgage'} recordField={'SecondaryMortgageDueDate'} valueType={'string'} record={mortgageData.recordDetails.secondaryMortgage.SecondaryMortgageDueDate} leadId={leadData._id} userNotifications={leadUserNotifications} dashPerspective={dashPerspective} handleWorkFlow={handleWorkFlow} userId={userId} userFullName={userFullName} mortgageId={mortgageData._id} setLeadUserNotifications={setLeadUserNotifications} workflowStage={workflowStage} fieldEditing={fieldEditing} activeDiscrepancies={leadData.activeDiscrepancies} resolvedDiscrepancies={leadData.resolvedDiscrepancies} rejectedDiscrepancies={leadData.rejectedDiscrepancies} noChanges={noChanges} setNoChanges={setNoChanges} activeLead={mortgageData.activeLead} />
            }
          </>
        :
          <></>
        }
      </ScrollWindow>
    </>
  )
}

export default CompareRecords;