import React, { useState } from "react";
import Controls from "./components/Controls";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationCard from "./components/Card/NotificationCard";
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { selectAllUserInAppNotifications } from "../../../../slices/userNotificationsSlice";
import { CardControlsContainer, CardListContainer, CardListHeader, FlexWrapper, HeaderText, PageContent } from "../../../../StyledComponents";

const Notifications = ({ userId }) => {
  const theme = useTheme();
  
  const userInAppNotifications = useSelector(selectAllUserInAppNotifications)

  const [notificationStatusFilter, setNotificationStatusFilter] = useState('All');
  const handleNotificationStatusFilterChange = (event) => {
    setNotificationStatusFilter(event.target.value);
  }

  return (
    <PageContent className={userInAppNotifications.length > 0 ? "grid notifications" : ""}>
      {userInAppNotifications.length > 0 ?
        <>
          <CardListHeader>Your Notifications</CardListHeader>
          {notificationStatusFilter === 'All' ?
            <>
              {userInAppNotifications !== undefined ? 
                <CardListContainer>
                  {userInAppNotifications.map((notification, index) => (
                    <NotificationCard key={notification._id} cardIndex={index} notification={notification} userId={userId} />
                  ))}
                </CardListContainer>
              :
                <CardListContainer className="loading">
                  <CircularProgress
                    sx={{ color: `${theme.palette.primary.main}`}}
                    size={48}
                  />
                </CardListContainer>
              }
              <CardControlsContainer>
                <Controls notificationStatusFilter={notificationStatusFilter} handleNotificationStatusFilterChange={handleNotificationStatusFilterChange} />
              </CardControlsContainer>
            </>
          :
            <></>
          }
        </>
      :
        <FlexWrapper className="column justifyCenter alignCenter" style={{gridColumn: '1/3', gridRow: '1/3'}}>
          <NotificationsOffIcon style={{color: `${theme.palette.common.grayBorderLight}`, height: '25rem', width: '25rem'}} />
          <HeaderText style={{fontSize: '2.2rem', color: `${theme.palette.common.grayTextLight}`}}>no notifications</HeaderText>
        </FlexWrapper>
      }
    </PageContent>
  )
}

export default Notifications;