import React from "react";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AssignOrCreateTag from "./AssignOrCreateTag";
import { AccentInfo, ComponentSectionRow, ModalContentContainer, FlexWrapper, CardButton, ListRow, CompareDetail, ScrollWindow, Typography, ComponentHeader } from "../../../../../StyledComponents";

const EditTags = ({ leadData, userFullName, teamId, dashPerspective, userId, activeLead, mortgageData }) => {

  return (
    <>
      <ComponentHeader className="modal">
        <Typography className="xLarge">
          {leadData.tags.length > 0 && mortgageData.tags.length > 0 ?
            `Mortgage and Lead tags`
          : leadData.tags.length > 0 ?
            `Lead tags`
          : mortgageData.tags.length > 0 ?
            `Mortgage tags`
          :
            `Tags`
          }
        </Typography>
      </ComponentHeader>
      <ModalContentContainer className="topColumn" style={{gridRow: '3/5', overflow: 'hidden'}}>
        <ComponentSectionRow className="header gray thinBorder">
          <ul style={{padding: '0.125rem 0', marginLeft: '3rem'}}>
            <li>"<span style={{fontWeight: '600'}}>violating fields</span>" are the specific fields where detected discrepancies triggered the assignment of a tag</li>
          </ul>
        </ComponentSectionRow>
        <ScrollWindow className="verticalOnly" style={{width: "100%"}}>
          {leadData.tags && leadData.tags.length > 0 &&
            <>
              <ListRow style={{marginBottom: '1rem'}}>
                <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                  Scan Assigned Lead Tags
                </CompareDetail>
              </ListRow>
              {leadData && leadData.tags.map((tag) => (tag.status === 'query' &&
                <ComponentSectionRow key={tag.label} style={{width: '90%', margin: '2rem auto 2rem 2rem'}}>
                  <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
                    <FlexWrapper className="alignCenter">
                      <div style={{marginRight: '1rem', fontWeight: '600'}}>
                        <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                          <LocalOfferIcon style={{height: '1rem', width: '1rem', marginRight: '0.4rem'}} />
                          <span>{tag.label}</span>
                        </CardButton>
                      </div>
                      {tag.apiMapping === 'loanType' || tag.apiMapping === 'propertyType' ?
                          <Typography className="large bold accent">
                            {tag.discrepancyFields.map((field, index) => (
                              index === (tag.discrepancyFields.length - 1) ?
                                  `${field}`
                              :
                                  `${field},`
                            ))}
                          </Typography>
                        :
                        <FlexWrapper style={{flexWrap: 'wrap', margin: '0.5rem 0 1rem 1rem'}}>
                          <span style={{fontWeight: '600'}}>violating fields:</span>
                          {tag.discrepancyFields.map((field, index) => (
                            index === (tag.discrepancyFields.length - 1) ?
                              <div key={field} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                                {field}
                              </div>
                            :
                              <div key={field} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                                {field},
                              </div>
                          ))}
                        </FlexWrapper>
                      }
                    </FlexWrapper>
                  </FlexWrapper>
                </ComponentSectionRow>
              ))}
              {leadData && leadData.tagAdded &&
                <>
                  <ListRow style={{margin: '0.3rem 0 2rem 0'}}>
                    <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                      Manually Assigned Lead Tags
                    </CompareDetail>
                  </ListRow>
                  {leadData && leadData.tags.map((tag) => (tag.status === 'manual' &&
                    <ComponentSectionRow key={tag.label} style={{width: '90%', margin: '2rem auto 2rem 2rem'}}>
                      <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
                        <FlexWrapper className="alignCenter" style={{margin: '0 0 1rem 0'}}>
                          <div style={{marginRight: '1rem', fontWeight: '600'}}>
                            <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                              <LocalOfferIcon style={{height: '1rem', width: '1rem', marginRight: '0.4rem'}} />
                              <span>{tag.label}</span>
                            </CardButton>
                          </div>
                          {tag.origin === 'default' ?
                            <></>
                          :
                            <span style={{fontWeight: '600'}}>{tag.description}</span>
                          }
                        </FlexWrapper>
                        <FlexWrapper style={{width: '60rem', flexWrap: 'wrap', margin: '0.5rem 0 1rem 3rem'}}>
                          <span style={{fontWeight: '600'}}>violating fields:</span>
                          {tag.discrepancyFields.map((field, index) => (
                            index === (tag.discrepancyFields.length - 1) ?
                              <div key={field} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                                {field}
                              </div>
                            :
                              <div key={field} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                                {field},
                              </div>
                          ))}
                        </FlexWrapper>
                      </FlexWrapper>
                    </ComponentSectionRow>
                  ))}
                </>
              }
            </>
          }
          {leadData.tags && leadData.tags.length === 0 ?
            <>
              <ListRow style={{marginBottom: '1rem'}}>
                <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                  Scan Assigned Lead Tags
                </CompareDetail>
              </ListRow>
              <ComponentSectionRow>
                <AccentInfo style={{margin: '1rem 0 0 3rem'}}>-no lead tags-</AccentInfo>
              </ComponentSectionRow>
            </>
          :
            <></>
          }
          {mortgageData.tags.length > 0 &&
            <>
              <ListRow style={{margin: '0.3rem 0 2rem 0'}}>
                <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                  Automatically Assigned Mortgage Tags 
                </CompareDetail>
              </ListRow>
              <ComponentSectionRow style={{margin: '2rem auto 2rem 2rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                {mortgageData.tags.map((tag) => ((tag.status === 'query' && (tag.apiMapping === 'loanType' || tag.apiMapping === 'propertyType')) &&
                  <FlexWrapper key={tag.label} className="column" style={{width: 'fit-content', margin: '0 5rem'}}>
                    <FlexWrapper className="alignCenter">
                      <div style={{marginRight: '1rem', fontWeight: '600'}}>
                        <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                          <LocalOfferIcon style={{height: '1rem', width: '1rem', marginRight: '0.4rem'}} />
                          <span>{tag.label}</span>
                        </CardButton>
                      </div>
                      <Typography className="large bold accent">
                        {tag.discrepancyFields.map((field, index) => (
                          index === (tag.discrepancyFields.length - 1) ?
                              `${field}`
                          :
                              `${field},`
                        ))}
                      </Typography>
                    </FlexWrapper>
                  </FlexWrapper>
                ))}
              </ComponentSectionRow>
              <ComponentSectionRow style={{margin: '2rem auto 2rem 2rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                {mortgageData.tags.map((tag) => ((tag.status === 'query' && (tag.apiMapping !== 'loanType' && tag.apiMapping !== 'propertyType')) &&
                  <FlexWrapper key={tag.label} className="column" style={{width: '100%', margin: '0 0 0 1rem'}}>
                    <FlexWrapper className="alignCenter">
                      <div style={{marginRight: '1rem', fontWeight: '600'}}>
                        <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                          <LocalOfferIcon style={{height: '1rem', width: '1rem', marginRight: '0.4rem'}} />
                          <span>{tag.label}</span>
                        </CardButton>
                      </div>
                      <span style={{fontWeight: '600'}}>{tag.description}</span>
                    </FlexWrapper>
                    <FlexWrapper style={{flexWrap: 'wrap', margin: '0.5rem 0 1rem 3rem'}}>
                      <span style={{fontWeight: '600'}}>violating fields:</span>
                      {tag.discrepancyFields.map((field, index) => (
                        index === (tag.discrepancyFields.length - 1) ?
                          <div key={field} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                            {field}
                          </div>
                        :
                          <div key={field} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                            {field},
                          </div>
                      ))}
                    </FlexWrapper>
                  </FlexWrapper>
                ))}
              </ComponentSectionRow>
              {mortgageData.tagAdded &&
                <>
                  <ListRow style={{margin: '0.3rem 0 2rem 0'}}>
                    <CompareDetail className="section header" style={{gridColumn: '1/7'}}>
                      Manually Assigned Mortgage Tags
                    </CompareDetail>
                  </ListRow>
                  {mortgageData.tags.map((tag) => (tag.status === 'manual' &&
                    <ComponentSectionRow key={tag.label} style={{width: '90%', margin: '2rem auto 2rem 2rem'}}>
                      <FlexWrapper className="column" style={{margin: '0 0 0 1rem'}}>
                        <FlexWrapper className="alignCenter" style={{margin: '0 0 1rem 0'}}>
                          <div style={{marginRight: '1rem', fontWeight: '600'}}>
                            <CardButton className="horizontal noHover" style={{width: 'fit-content'}}>
                              <LocalOfferIcon style={{height: '1rem', width: '1rem', marginRight: '0.4rem'}} />
                              <span>{tag.label}</span>
                            </CardButton>
                          </div>
                          {tag.origin === 'default' ?
                            <></>
                          :
                            <span style={{fontWeight: '600'}}>{tag.description}</span>
                          }
                        </FlexWrapper>
                        <FlexWrapper style={{width: '60rem', flexWrap: 'wrap', margin: '0.5rem 0 1rem 3rem'}}>
                          <span style={{fontWeight: '600'}}>violating fields:</span>
                          {tag.discrepancyFields.map((field, index) => (
                            index === (tag.discrepancyFields.length - 1) ?
                              <div key={field} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                                {field}
                              </div>
                            :
                              <div key={field} style={{margin: '0 0 0 0.6rem', fontWeight: '300'}}>
                                {field},
                              </div>
                          ))}
                        </FlexWrapper>
                      </FlexWrapper>
                    </ComponentSectionRow>
                  ))}
                </>
              }
            </>
          }
          {mortgageData.tags.length === 0 ?
            <ComponentSectionRow>
              <AccentInfo style={{margin: '1rem 0 0 3rem'}}>-no mortgage tags-</AccentInfo>
            </ComponentSectionRow>
          :
            <div style={{height: '3rem'}} />
          }
        </ScrollWindow>
        <AssignOrCreateTag leadData={leadData} userFullName={userFullName} teamId={teamId} dashPerspective={dashPerspective} userId={userId} activeLead={activeLead} mortgageData={mortgageData} />
      </ModalContentContainer>
    </>
  )
}

export default EditTags;