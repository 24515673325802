import React , { useState} from "react";
import { useTheme } from "@mui/styles";
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MuiBadge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import GroupsIcon from '@mui/icons-material/Groups';
import AdjustIcon from '@mui/icons-material/Adjust';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import ChangeTargetOutcome from "../../../../../../components/Modals/LeadActions/Components/ChangeTargetOutcome.js";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ActionMenuItem, ActionMenuTitle, CardButton, Badge, NewLabel, AddButton, CardIndex, FlexWrapper, Typography, ActionIconWrapper } from "../../../../../../StyledComponents";

const ClosingCard = ({ lead, userId, userFullName, dashPerspective, handleOpenLeadActionsModal, cardIndex }) => {
  const theme = useTheme();

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.common.error}`,
      fontWeight: '800',
      fontSize: "1rem",
      height: "1.4rem",
      padding: "0 0.5rem",
      borderRadius: "1rem",
      minWidth: "1.2rem",
    }
  }

  const [changeOutcomeDetails, setChangeOutcomeDetails] = useState({})

  const [menuActive, setMenuActive] = useState(false)
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const openActionsMenu = Boolean(actionMenuAnchorEl);
  const [outcomeMenuAnchorEl, setOutcomeMenuAnchorEl] = useState(null);
  const openOutcomeMenu = Boolean(outcomeMenuAnchorEl);

  const handleOpenActions = (event) => {
    setActionMenuAnchorEl(event.currentTarget);
    setMenuActive(true);
  };
  const handleCloseActions = () => {
    setActionMenuAnchorEl(null);
    setMenuActive(false);
    setOutcomeMenuAnchorEl(null)
  };

  const handleOpenOutcomeMenu = (event, leadId, timeline, oldOutcome, userNotifications) => {
    let newOutcome = ''
    if (oldOutcome === 'renegotiation') {
      newOutcome = 'refinance'
    } else {
      newOutcome = 'renegotiation'
    }
    setChangeOutcomeDetails({
      leadId: leadId,
      timeline: timeline,
      newOutcome: newOutcome,
      userNotifications: userNotifications,
    })
    setOutcomeMenuAnchorEl(event.currentTarget);
  }
  const handleCloseOutcomeMenu = () => {
    setOutcomeMenuAnchorEl(null)
    setActionMenuAnchorEl(null)
    setMenuActive(false)
  };

  return (
    <>
      <CardIndex>
        {cardIndex+1}
      </CardIndex>
      <div style={{display: 'flex', justifyContent: 'space-between', width: '6rem', margin: '0 0 0 0.5rem'}}>
        {lead.tier === 0 ?
          <CardButton
            className="tier manualInvestigation"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex", textWrap: 'nowrap', fontSize: '1.2rem'}}>
              Manual Investigation
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>        
        : lead.tier === 1 ?
          <CardButton
            className="tier one"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="one">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>1</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        : lead.tier === 2 ?
          <CardButton
            className="tier two"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="two">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>2</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        :
          <CardButton
            className="tier three"
            variant="contained"
            disableElevation
            onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}
          >
            <span style={{transform: "rotate(-90deg)", display: "flex"}}>
              {lead.activeDiscrepancies > 0 ?
                <Badge className="three">{lead.activeDiscrepancies}</Badge>
              :
                <></>
              }
              TIER
              <span style={{padding: "0 0 0 0.2rem"}}>3</span>
              <OpenInNewIcon style={{padding: "0 0 0.3rem 0"}} />
            </span>
          </CardButton>
        }
        <CardButton
          variant="contained"
          disableElevation
          onClick={handleOpenActions}
          className={`${menuActive ? "active" : "actions"}`}
        >
          <span style={{transform: "rotate(-90deg)", display: "flex"}}>
            ACTIONS
            {lead.userNotifications > 0 ?
              <MuiBadge 
                badgeContent={lead.userNotifications} 
                color="error" 
                sx={badgeStyle}   
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <KeyboardArrowDownIcon style={{padding: "0 0 0.3rem 0"}} />
              </MuiBadge>
            :
              <KeyboardArrowDownIcon style={{padding: "0 0 0.3rem 0"}} />
            }
          </span>
        </CardButton>
        <StyledMenu
          anchorEl={actionMenuAnchorEl}
          open={openActionsMenu}
          onClose={handleCloseActions}
        >
          {lead.belongsToMortgage.reports.length === 0 ?
            <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius" style={{background: `${theme.palette.common.disabledGray}`}}>
              <ActionMenuItem className="noHover topRadius">
                <SummarizeIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Reports ({lead.belongsToMortgage.reports.length})</ActionMenuTitle>
              </ActionMenuItem>
            </MenuItem>
          :
            <MenuItem onClick={handleCloseActions} disableRipple className="border topRadius">
              <ActionMenuItem onClick={() => handleOpenLeadActionsModal('reports', lead, lead.userNotifications, lead.belongsToMortgage)}>
                <SummarizeIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
                <ActionMenuTitle>Reports ({lead.belongsToMortgage.reports.length})</ActionMenuTitle>
              </ActionMenuItem>
            </MenuItem>
          }
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('notes', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <EditNoteIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Notes ({lead.belongsToMortgage.mortgageNotes.length})</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={(e) => handleOpenOutcomeMenu(e, lead._id, lead.timeline, lead.targetOutcome, lead.userNotifications)} disableRipple className="border">
            <ActionMenuItem>
              <FlagCircleIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              {lead.targetOutcome === 'renegotiation' ?
                <ActionMenuTitle>Switch to Refinance</ActionMenuTitle>
              :
                <ActionMenuTitle>Switch to Renegotiation</ActionMenuTitle>
              }
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('compareRecords', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <FileCopyIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Compare Records</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('propertyDetails', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <HomeWorkIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Property Details</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('ownerDetails', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <GroupsIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Owner Details</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('editTargets', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <AdjustIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Change Targets</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} disableRipple className="border">
            <ActionMenuItem onClick={() => handleOpenLeadActionsModal('addAssignee', lead, lead.userNotifications, lead.belongsToMortgage)}>
              <PersonAddIcon style={{color: `${theme.palette.common.grayText}`, margin: "0", height: "2.2rem", width: "2.2rem"}} />
              <ActionMenuTitle>Manage Assignees</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions}  className="button buttonTop" disableRipple style={{cursor: "default"}}>
            <ActionMenuItem 
              className="advance" 
              style={{width: '14rem', margin: '0'}} 
              onClick={() => handleOpenLeadActionsModal('finalizeLead', lead, lead.userNotifications, lead.belongsToMortgage)}
            >
              <ActionMenuTitle className="white">Finalize</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
          <MenuItem onClick={handleCloseActions} className="button bottomRadius" disableRipple style={{cursor: "default"}}>
            <ActionMenuItem 
              className="dismiss" 
              style={{width: '14rem', margin: '0'}} 
              onClick={() => handleOpenLeadActionsModal('dismissLead', lead, lead.userNotifications, lead.belongsToMortgage)}
            >              
              <ActionMenuTitle className="white">Dismiss</ActionMenuTitle>
            </ActionMenuItem>
          </MenuItem>
        </StyledMenu>
        <StyledMenu
          anchorEl={outcomeMenuAnchorEl}
          open={openOutcomeMenu}
          onClose={handleCloseOutcomeMenu}
        >
          <ChangeTargetOutcome userId={userId} userFullName={userFullName} changeOutcomeDetails={changeOutcomeDetails} dashPerspective={dashPerspective} />
        </StyledMenu>
      </div>

      <FlexWrapper className="column">
        <FlexWrapper>
          <FlexWrapper className="column"  style={{width: '24rem', minWidth: '24rem', paddingLeft: '1rem'}}>
            <FlexWrapper>
              {lead.newAssignmentNotification &&
                <NewLabel className="leadCard">Assigned</NewLabel>
              }
              {lead.targetOutcome === "renegotiation" ?
                <Typography className="autoMarginHorizontal outcome renegotiation">
                  Renegotiation
                </Typography>
              :
                <Typography className="autoMarginHorizontal outcome refinance">
                  Refinance
                </Typography>
              }
            </FlexWrapper>
            <FlexWrapper>
              <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                <Typography className="tinyHeight tiny">Date Discovered:</Typography>
                <Typography className="tinyHeight tiny">Began Investigation:</Typography>
                <Typography className="tinyHeight tiny">Active Discrepancies:</Typography>
                <Typography className="tinyHeight tiny">Inactive Discrepancies:</Typography>
                {lead.assigneeNames.length === 1 ?
                  <Typography className="tinyHeight tiny">Assignee:</Typography>
                :
                  <Typography className="tinyHeight tiny">Assignees:</Typography>
                }        
              </FlexWrapper>
              <FlexWrapper className="column" style={{paddingLeft: '0.5rem'}}>
                <Typography className="bold tiny">
                  {lead.dateDiscoveredLabel}
                </Typography>
                <Typography className="bold tiny">
                  {lead.dateInvestigating}
                </Typography>
                {lead.activeDiscrepancies > 0 ?
                  <Typography className="bold tiny">
                    {lead.activeDiscrepancies}
                  </Typography>
                :
                  <Typography className="tiny accent">-none-</Typography>
                }
                {lead.rejectedDiscrepancies === 0 && lead.resolvedDiscrepancies === 0 ?
                  <Typography className="tiny accent">-none-</Typography>
                : lead.rejectedDiscrepancies === 0 && lead.resolvedDiscrepancies > 0 ?
                  <Typography className="bold tiny">
                    {lead.resolvedDiscrepancies} resolved
                  </Typography>
                : lead.rejectedDiscrepancies > 0 && lead.resolvedDiscrepancies === 0 ?
                  <Typography className="bold tiny">
                    {lead.rejectedDiscrepancies} rejected
                  </Typography>
                :
                  <Typography className="bold tiny">
                    {lead.resolvedDiscrepancies} resolved, {lead.rejectedDiscrepancies} rejected
                  </Typography>
                }
                {lead.assigneeNames.length === 0 ?
                  <Typography className="tiny accent">-none-</Typography>
                : lead.assigneeNames.length === 1 ?
                  <Typography className="bold tiny">
                    {lead.assigneeNames[0]}
                  </Typography>
                :
                  lead.assigneeNames.map((assignee, index) => (
                    <Typography className="bold tiny" key={assignee}>{assignee}</Typography>
                  ))
                }
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>

          <FlexWrapper className="justifyAround">
            <FlexWrapper className="column fitContentWidth">
              <Typography className="subHeader dark autoMarginHorizontal">Mortgage Info</Typography>
              <FlexWrapper className="alignCenter">
                <FlexWrapper className="column alignEnd justifyBetween">
                  <Typography className="tinyHeight tiny">Address:</Typography>
                  <Typography className="tinyHeight"></Typography>
                  {lead.belongsToMortgage.owner1 && lead.belongsToMortgage.owner2 ?
                    <>
                      <Typography className="tinyHeight tiny">Borrowers:</Typography>
                      <Typography className="tinyHeight"></Typography>
                    </>
                  : lead.belongsToMortgage.owner1 || lead.belongsToMortgage.owner2 ?
                    <Typography className="tinyHeight tiny">Borrower:</Typography>
                  :
                    <Typography className="tinyHeight tiny">Borrowers:</Typography>
                  }
                  {lead.belongsToMortgage.originationDateLabel.length > 0 && lead.belongsToMortgage.endDateLabel.length === 0 ?
                    <Typography className="tinyHeight tiny">Originated:</Typography>
                  : lead.belongsToMortgage.endDateLabel.length > 0 && !lead.belongsToMortgage.originationDateLabel.length > 0 ?
                    <Typography className="tinyHeight tiny">Terminates:</Typography>
                  : lead.belongsToMortgage.originationDateLabel.length > 0 && lead.belongsToMortgage.endDateLabel.length > 0 ?
                    <>
                      <Typography className="tinyHeight tiny">Term:</Typography>
                      <Typography className="tinyHeight"></Typography>
                    </>
                  : 
                    <Typography className="tinyHeight tiny">Term:</Typography>
                  }
                  <Typography className="tinyHeight tiny">Remaining:</Typography>           
                </FlexWrapper>
                <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem'}}>
                  {lead.belongsToMortgage.streetAddress.length > 0 ?
                    <>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.streetAddress}
                      </Typography>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.recordDetails.address.City.currentValue}, {lead.belongsToMortgage.recordDetails.address.StateOrProvince.currentValue} {lead.belongsToMortgage.recordDetails.address.PostalCode.currentValue}
                      </Typography>
                    </>
                  :
                    <>
                      <Typography className="tiny accent">-N/A-</Typography>
                      <Typography className="tinyHeight"></Typography>
                    </>
                  }
                  {lead.belongsToMortgage.owner1.length > 0 && lead.belongsToMortgage.owner2.length > 0 ?
                    <>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.owner1}
                      </Typography>
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.owner2}
                      </Typography>
                    </>
                  : lead.belongsToMortgage.owner1.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.owner1}
                    </Typography>
                  : lead.belongsToMortgage.owner2.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.owner2}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                  {lead.belongsToMortgage.originationDateLabel.length > 0 && lead.belongsToMortgage.endDateLabel.length > 0 ?
                    <FlexWrapper className="column">
                      <Typography className="bold tiny">
                        {lead.belongsToMortgage.originationDateLabel} 
                      </Typography>
                      <Typography className="bold tiny">
                        <Typography className="thin tiny" style={{marginRight: '0.5rem'}}>to</Typography>
                        {lead.belongsToMortgage.endDateLabel}
                      </Typography>
                    </FlexWrapper>
                  : lead.belongsToMortgage.originationDateLabel.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.originationDateLabel}
                    </Typography>
                  : lead.belongsToMortgage.endDateLabel.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.endDateLabel}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                  {lead.belongsToMortgage.remainingTerm.length > 0 ?
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.remainingTerm}
                    </Typography>
                  :
                    <Typography className="tiny accent">-N/A-</Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
            
            <FlexWrapper className="column alignCenter fitContentWidth">
              <Typography className="subHeader dark autoMarginHorizontal">Financials</Typography>
              <FlexWrapper className="alignCenter">
                <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                  {lead.belongsToMortgage.originalLoanAmount > 0 &&
                    <Typography className="tinyHeight tiny">Loan Amount:</Typography>
                  }
                  {lead.belongsToMortgage.principalRemaining > 0 &&
                    <Typography className="tinyHeight tiny">Principal Remaining:</Typography>
                  }
                  {lead.belongsToMortgage.originalInterestRate > 0 &&
                    <Typography className="tinyHeight tiny">Original Interest Rate:</Typography>
                  }
                  {lead.belongsToMortgage.originalInterestDue > 0 &&
                    <Typography className="tinyHeight tiny">Original Interest Due:</Typography>
                  }
                  {lead.belongsToMortgage.interestRemaining > 0 &&
                    <Typography className="tinyHeight tiny">Current Interest Due:</Typography>
                  }                
                </FlexWrapper>
                <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem'}}>
                  {lead.belongsToMortgage.originalLoanAmount > 0 &&
                    <Typography className="bold tiny">
                      ${(lead.belongsToMortgage.originalLoanAmount).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  }
                  {lead.belongsToMortgage.principalRemaining > 0 &&
                    <Typography className="bold tiny">
                      ${(lead.belongsToMortgage.principalRemaining).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  }
                  {lead.belongsToMortgage.originalInterestRate > 0 &&
                    <Typography className="bold tiny">
                      {lead.belongsToMortgage.originalInterestRate}%
                    </Typography>
                  }
                  {lead.belongsToMortgage.originalInterestDue > 0 &&
                    <Typography className="bold tiny">
                      ${(lead.belongsToMortgage.originalInterestDue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  }
                  {lead.belongsToMortgage.interestRemaining > 0 &&
                    <Typography className="bold tiny">
                      ${(lead.belongsToMortgage.interestRemaining).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>

          <FlexWrapper className="column alignCenter fitContentHeight" style={{width: 'fit-content', minWidth: 'fit-content', paddingRight: '0.5rem'}}>
            <Typography className="subHeader dark autoMarginHorizontal">Refinance Potential</Typography>
            {lead.belongsToMortgage.financialsPresent ?
              <FlexWrapper className="alignCenter">
                <FlexWrapper className="column alignEnd justifyBetween fitContentHeight">
                  <Typography className="tinyHeight tiny">Target Interest Rate:</Typography>
                    <>
                      <Typography className="tinyHeight tiny">Target Interest Due:</Typography>
                      <Typography className="largeHeight tiny">Change in Interest Owed:</Typography>
                      <Typography className="largeHeight tiny">Change in Revenue:</Typography>              
                    </>
                </FlexWrapper>
                <FlexWrapper className="column fitContentHeight" style={{paddingLeft: '0.5rem'}}>
                  <Typography className="tinyHeight tiny bold">{lead.targetInterestRate}%</Typography>              
                  <Typography className="tinyHeight tiny bold">${lead.targetInterestDue.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}</Typography>
                  {parseInt(lead.targetProfitNumber) > 0 ?
                    <Typography className="largeHeight large bold success">
                      ${lead.targetProfitNumber.toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography> 
                  : parseInt(lead.targetProfitNumber) < 0 ?
                    <Typography className="largeHeight large bold error">
                      -${(lead.targetProfitNumber*-1).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    </Typography> 
                  :
                    <Typography className="largeHeight tiny">$0</Typography>
                  }
                  {parseInt(lead.targetProfitPercent) >= 0 ?
                    <Typography className="largeHeight large bold success">
                      {lead.targetProfitPercent}%
                    </Typography> 
                  :
                    <Typography className="largeHeight large bold error">
                      {lead.targetProfitPercent}%
                    </Typography> 
                  }
                </FlexWrapper>
              </FlexWrapper>
            :
              <ActionIconWrapper style={{margin: '1rem auto 0 auto'}} className="primary wrapped caution hover" onClick={() => handleOpenLeadActionsModal('provideFinancialsCard', lead, lead.userNotifications, lead.belongsToMortgage)}>
                <AddCircleOutlineIcon style={{marginRight: '0.5rem'}} />
                <Typography className="caution">Add Original Terms</Typography>
              </ActionIconWrapper>
            }
          </FlexWrapper>
        </FlexWrapper>

        <FlexWrapper className="column" style={{paddingLeft: '2rem'}}>
          <FlexWrapper style={{padding: '0.5rem 0 0 3rem', marginTop: '0.5rem', borderTop: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
            {lead.belongsToMortgage.tags.length === 0 ?
              <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="accent">no mortgage tags</Typography>
                <AddButton onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                  <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
                </AddButton>
              </FlexWrapper>
            :
              <FlexWrapper className="alignCenter" style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="medium" style={{margin: 'auto 0.5rem auto 0'}}>mortgage tags:</Typography>
                {lead.belongsToMortgage.tags.map((tag) => (
                  <CardButton key={tag._id} className="horizontal mortgageTag" onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                    {tag.label === 'Property Type' || tag.label === 'Loan Type' ?
                      `${tag.label} - ${tag.discrepancyFields[0].toUpperCase()}`
                    :
                      `${tag.label}`
                    }
                  </CardButton>
                ))}
              </FlexWrapper>
            }
          </FlexWrapper>
          <FlexWrapper style={{paddingLeft: '3rem'}}>
            {lead.tags.length === 0 ?
              <FlexWrapper style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="accent">no lead tags</Typography>
                <AddButton onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                  <AddCircleOutlineIcon style={{margin: "0", height: "100%", width: "100%"}} />
                </AddButton>
              </FlexWrapper>
            :
              <FlexWrapper className="alignCenter" style={{flexWrap: 'wrap', marginLeft: '1rem', padding: '0.2rem'}}>
                <Typography className="medium" style={{margin: 'auto 0.5rem auto 0'}}>lead tags:</Typography>
                {lead.tags.map((tag) => (
                  <CardButton key={tag._id} className="horizontal" onClick={() => handleOpenLeadActionsModal('editTags', lead, lead.userNotifications, lead.belongsToMortgage)}>
                    {tag.label}
                  </CardButton>
                ))}
              </FlexWrapper>
            }
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </>
  )
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '1rem',
    minWidth: 180,
    background: theme.palette.common.grayBorder,
    borderRight: `1rem solid ${theme.palette.common.grayBorder}`,
    borderLeft: `1rem solid ${theme.palette.common.grayBorder}`,
    borderTop: `0.4rem solid ${theme.palette.common.grayBorder}`,
    borderBottom: `0.4rem solid ${theme.palette.common.grayBorder}`,
    '& .MuiMenu-list:hover': {
    },
    '& .MuiMenuItem-root': {
      background: theme.palette.common.pageBackground,
      '&.buttonTop': {
        padding: '1rem 2rem 0.5rem 2rem',
      },
      '&.border': {
        borderBottom: `0.1rem solid ${theme.palette.common.grayBorder}`
      },
      '&.topRadius': {
        borderRadius: '0.6rem 0.6rem 0 0',
      },
      '&.bottomRadius': {
        borderRadius: '0 0 0.6rem 0.6rem',
        padding: '0.5rem 2rem 1rem 2rem',
      }
    },
    '& .MuiMenuItem-root:hover': {
      background: theme.palette.common.grayHover,
      '&.button': {
        background: theme.palette.common.pageBackground,
      },
    },
  },
}));

export default ClosingCard;