import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_URL } from '../../config';

//* Above each cache is the component location where the query or mutation is called from
//* Note that these are in addition to the pre-fetches that are done within the App.js component upon initial load

//TODO: add headers
//TODO: switch a lot of these pessimistic loads to optimistic
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: builder => ({
    launchTeam: builder.mutation({
      query: body => ({
        url: `/launch/team`,
        method: 'POST',
        body: body,
      })
    }),
    establishRequestCredentials: builder.mutation({
      query: body => ({
        url: `/auth/establishCredentials`,
        method: 'PATCH',
        body: body,
      })
    }),
    createGuestAccount: builder.mutation({
      query: body => ({
        url: `/auth/create/guest`,
        method: 'POST',
        body: body
      }),
    }),
    loginReturningUser: builder.mutation({
      query: body => ({
        url: `/auth/login/returning/user`,
        method: 'PATCH',
        body: body
      }),
    }),
    selectLoginByEmail: builder.mutation({
      query: body => ({
        url: `/login/select/email`,
        method: 'POST',
        body: body
      }),
    }),
    logReturningVisit: builder.mutation({
      query: body => ({
        url: `/auth/log/returning`,
        method: 'PATCH',
        body: body,
      })
    }),
    initialLoad: builder.mutation({
      query: body => ({
        url: `/load/initial/${body.userId}`,
        method: 'GET',
        headers: { 'authorization': `${body.authToken}` }
      })
    }),
    uploadCSVMortgages: builder.mutation({
      query: body => ({
        url: `/uploads/mortgages/csv`,
        method: 'POST',
        body: body
      }),
    }),
    deleteUploadReport: builder.mutation({
      query: body => ({
        url: `/uploads/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    dismissLead: builder.mutation({
      query: body => ({
        url: `/activeLeads/dismiss`,
        method: 'PATCH',
        body: body
      }),
    }),
    setLeadStatusInvestigating: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/investigating`,
        method: 'PATCH',
        body: body
      }),
    }),
    setLeadStatusClosing: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/closing`,
        method: 'PATCH',
        body: body
      }),
    }),
    setLeadStatusFinalized: builder.mutation({
      query: body => ({
        url: `/activeLeads/set/finalized`,
        method: 'POST',
        body: body
      }),
    }),
    verifyPublicRecords: builder.mutation({
      query: body => ({
        url: `/activeLeads/verifyPublicRecords`,
        method: 'PATCH',
        body: body
      }),
    }),
    continueAwaitingUpdate: builder.mutation({
      query: body => ({
        url: `/activeLeads/continueAwaitingUpdate`,
        method: 'PATCH',
        body: body
      }),
    }),
    saveLeadTargetUpdates: builder.mutation({
      query: body => ({
        url: `/activeLeads/targets/save`,
        method: 'PATCH',
        body: body
      }),
    }),
    changeTargetOutcome: builder.mutation({
      query: body => ({
        url: `/activeLeads/changeOutcome`,
        method: 'PATCH',
        body: body
      }),
    }),
    addLeadTag: builder.mutation({
      query: body => ({
        url: `/activeLeads/addLeadTag`,
        method: 'PATCH',
        body: body
      }),
    }),
    addAssignees: builder.mutation({
      query: body => ({
        url: `/mortgages/addAssignees`,
        method: 'PATCH',
        body: body
      }),
    }),
    resolveAllLeadDiscrepancies: builder.mutation({
      query: body => ({
        url: `/activeLeads/resolve/all`,
        method: 'PATCH',
        body: body
      }),
    }),
    reloadLeadGenerationQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/teamMonthlyStats/team/quarterBreakdown/leadGeneration/reload`,
        method: 'POST',
        body: body
      })
    }),
    reloadRenegotiationQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/teamMonthlyStats/team/quarterBreakdown/renegotiation/reload`,
        method: 'POST',
        body: body
      })
    }),
    reloadRefinanceQuarterlyBreakdown: builder.mutation({
      query: body => ({
        url: `/teamMonthlyStats/team/quarterBreakdown/refinance/reload`,
        method: 'POST',
        body: body
      })
    }),
    addMortgageNote: builder.mutation({
      query: body => ({
        url: `/mortgages/notes/add`,
        method: 'POST',
        body: body
      }),
    }),
    updateMortgageDetails: builder.mutation({
      query: body => ({
        url: `/mortgages/update`,
        method: 'POST',
        body: body
      }),
    }),
    checkForMortgageDuplicates: builder.mutation({
      query: body => ({
        url: `/mortgages/duplicates`,
        method: 'POST',
        body: body
      }),
    }),
    deleteMortgage: builder.mutation({
      query: body => ({
        url: `/mortgages/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    addMortgageTag: builder.mutation({
      query: body => ({
        url: `/mortgages/tags/add`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteMortgagePublicRecordAndCurrentDetails: builder.mutation({
      query: body => ({
        url: "mortgages/delete/publicRecordDetails",
        method: 'PATCH',
        body: body,
      })
    }),
    provideMortgageFinancialInformation: builder.mutation({
      query: body => ({
        url: "mortgages/provide/financialInfo",
        method: 'PATCH',
        body: body,
      })
    }),
    updateNotificationStatus: builder.mutation({
      query: body => ({
        url: `/inAppNotifications/status`,
        method: 'PATCH',
        body: body
      })
    }),
    deleteNotification: builder.mutation({
      query: body => ({
        url: `/inAppNotifications/delete/${body.notificationId}/${body.userId}/${body.status}/${body.newInAppNotifications}`,
        method: 'DELETE',
      })
    }),
    updateSecuritySetting: builder.mutation({
      query: body => ({
        url: `/teams/update/security`,
        method: 'PATCH',
        body: body
      })
    }),
    selectNewUserActionsPermissionsSet: builder.mutation({
      query: body => ({
        url: `/users/selectRolePermissions`,
        method: 'PATCH',
        body: body
      })
    }),
    updateTeamDefaultTargetParameters: builder.mutation({
      query: body => ({
        url: `/teams/settings/defaultTargets`,
        method: 'PATCH',
        body: body
      })
    }),
    updateTeamDiscrepancyParamterSelection: builder.mutation({
      query: body => ({
        url: `/teams/parameter/activeStatus`,
        method: 'PATCH',
        body: body
      })
    }),
    updateSweepParamterSettings: builder.mutation({
      query: body => ({
        url: `/teams/parameter/settings`,
        method: 'PATCH',
        body: body
      })
    }),
    removeReportNotifyUser: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/report`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUserLeadNotification: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/lead`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUserNewAssignmentNotification: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/newAssignment`,
        method: 'PATCH',
        body: body,
      })
    }),
    deleteUser: builder.mutation({
      query: body => ({
        url: `/users/removeNotification/deleteUser`,
        method: 'DELETE',
        body: body,
      })
    }),
    recordSweep: builder.mutation({
      query: body => ({
        url: `/queries/recordSweep`,
        method: 'POST',
        body: body,
      })
    }),
    recordSweepQuery: builder.mutation({
      query: body => ({
        url: `/queries/recordSweep/query`,
        method: 'POST',
        body: body,
      })
    }),
    recordRecordSweep: builder.mutation({
      query: body => ({
        url: `/queries/recordSweep/record`,
        method: 'POST',
        body: body,
      })
    }),
    runPropertySearch: builder.mutation({
      query: body => ({
        url: `/queries/property/search`,
        method: 'POST',
        body: body,
      })
    }),
    saveProperty: builder.mutation({
      query: body => ({
        url: `/uploads/mortgages/search`,
        method: 'POST',
        body: body,
      })
    }),
    deleteActionOrErrorLog: builder.mutation({
      query: body => ({
        url: `/teams/actionAndErrorLog/delete`,
        method: 'DELETE',
        body: body,
      })
    }),
    createCronTicker: builder.mutation({
      query: body => ({
        url: `/scheduledTasks/cronTicker/create`,
        method: 'POST',
        body: body,
      })
    }),
    editMortgage: builder.mutation({
      query: body => ({
        url: `/mortgages/edit`,
        method: 'PATCH',
        body: body,
      })
    }),
    dropAllFromDatabase: builder.mutation({
      query: body => ({
        url: `/database/drop/all`,
        method: 'DELETE',
        body: body,
      })
    }),
  })
})

export const { usePrefetch, useUploadCSVMortgagesMutation, useSetLeadStatusInvestigatingMutation, useSetLeadStatusClosingMutation, useSetLeadStatusFinalizedMutation, useUpdateMortgageDetailsMutation, useAddMortgageNoteMutation, useUpdateNotificationStatusMutation, useDeleteNotificationMutation, useUpdateTeamDefaultTargetParametersMutation, useUpdateTeamDiscrepancyParamterSelectionMutation, useSaveLeadTargetUpdatesMutation, useDismissLeadMutation, useAddAssigneesMutation, useChangeTargetOutcomeMutation, useReloadLeadGenerationQuarterlyBreakdownMutation, useReloadRenegotiationQuarterlyBreakdownMutation, useReloadRefinanceQuarterlyBreakdownMutation, useRemoveReportNotifyUserMutation, useAddLeadTagMutation, useRecordSweepMutation, useDeleteUserLeadNotificationMutation, useDeleteUserNewAssignmentNotificationMutation, useInitialLoadMutation, useDeleteUserMutation, useCheckForMortgageDuplicatesMutation, useDeleteUploadReportMutation, useDeleteMortgageMutation, useResolveAllLeadDiscrepanciesMutation, useDeleteActionOrErrorLogMutation, useCreateCronTickerMutation, useAddMortgageTagMutation, useUpdateSweepParamterSettingsMutation, useCreateGuestAccountMutation, useEstablishRequestCredentialsMutation, useLoginReturningUserMutation, useLaunchTeamMutation, useLogReturningVisitMutation, useVerifyPublicRecordsMutation, useContinueAwaitingUpdateMutation, useDeleteMortgagePublicRecordAndCurrentDetailsMutation, useSelectNewUserActionsPermissionsSetMutation, useRunPropertySearchMutation, useSavePropertyMutation, useSelectLoginByEmailMutation, useProvideMortgageFinancialInformationMutation, useUpdateSecuritySettingMutation, useRecordRecordSweepMutation, useRecordSweepQueryMutation, useEditMortgageMutation, useDropAllFromDatabaseMutation } = apiSlice