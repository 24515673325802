import React from "react"
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { selectTeamLeadTags } from "../../slices/teamAndUserSettingsSlice";
import { ColumnContent, ComponentSectionRow, Divider, FlexWrapper, ScrollWindow, Typography } from "../../StyledComponents";

const LeadTagPerformance = () => {
  const theme = useTheme()

  const teamLeadTags = useSelector(selectTeamLeadTags)

  return (
    <FlexWrapper className="column" style={{maxHeight: 'calc(100vh - 16.3rem)'}}>
      <Divider className="darkGray" />
      <Typography className="subHeader medium alignTextCenter" style={{width: '80rem', margin: '1rem auto'}}>
        The system assigns Tags to assist your team in organizing investigations.<br/>
        The table below shows which Tags are applied when a Discrepancy is discovered in each respective Parameter.
      </Typography>
      <ComponentSectionRow className="header gray" style={{display: 'grid', gridTemplateColumns: `7rem 22rem 15rem 15rem 15rem 15rem 15rem 1fr 0.6rem`}}>
        <ColumnContent className="header" style={{gridColumn: '1', borderLeft: `0.1rem solid ${theme.palette.common.grayBorder}`}}>
          <Typography style={{margin: '0 auto'}}>
            label
          </Typography>
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '2', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0 0.6rem 0.7rem'}}>
          <Typography style={{margin: '0 auto'}}>
            label
          </Typography>
        </ColumnContent>  
        <ColumnContent className="header" style={{gridColumn: '3', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0 0.6rem 0.7rem'}}>
          <Typography className="alignTextCenter" style={{margin: '0 auto'}}>
            Total times<br/>assigned
          </Typography>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '4', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0 0.6rem 0.7rem'}}>
          <Typography style={{margin: '0 auto'}}>
            Currently assigned<br/>to an<br/>Investigation
          </Typography>
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '5', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0 0.6rem 0.7rem'}}>
          When Assigned,<br/>how often a<br/>Renegotitaion<br/>results
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '6', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0 0.6rem 0.7rem'}}>
          When Assigned,<br/>how often a<br/>Refinance<br/>results
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '7', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0 0.6rem 0.7rem'}}>
          When Assigned,<br/>how often a<br/>Dismissal<br/>results
        </ColumnContent>
        <ColumnContent className="header" style={{gridColumn: '8', borderLeft: `0.1rem solid ${theme.palette.common.grayText}`, padding: '0.6rem 0 0.6rem 0.7rem'}}>
          triggers
        </ColumnContent>
      </ComponentSectionRow>
      <ScrollWindow className="verticalOnly" style={{paddingBottom: '5rem', background: '#fff'}}>
        {teamLeadTags.map((tag, index) => (
          <ComponentSectionRow style={{padding: '0.1rem 0', background: index%2 === 1 ? `${theme.component.row.even}` : `${theme.component.row.odd}`, display: 'grid', gridTemplateColumns: `7rem 22rem 15rem 15rem 15rem 15rem 15rem 1fr 0.6rem`}} key={tag._id} >
            {tag.origin === 'default' ?
              <ColumnContent style={{gridColumn: `1`, color: `${theme.palette.primary.main}`}} >
                DEFAULT 
              </ColumnContent>   
            :
              <ColumnContent style={{gridColumn: `1`, color: `${theme.palette.secondary.main}`}} >
                CUSTOM 
              </ColumnContent>   
            }
            <ColumnContent className="detail" style={{gridColumn: `2`}} >
              {tag.label}  
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `3`, fontWeight: tag.assignments > 0 ? '600' : '400'}} >
              {tag.currentAssignments}  
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `4`, fontWeight: tag.activeLeads > 0 ? '600' : '400'}} >
              {tag.activeLeads}  
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `5`, fontWeight: tag.renegotiations > 0 ? '600' : '400'}} >
              {tag.renegotiations} {tag.assignments > 0 && (((tag.renegotiations/tag.assignments)*100).toFixed(0) + '%')}
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `6`, fontWeight: tag.refinances > 0 ? '600' : '400'}} >
              {tag.refinances} {tag.assignments > 0 && (((tag.refinances/tag.assignments)*100).toFixed(0) + '%')}
            </ColumnContent>   
            <ColumnContent style={{gridColumn: `7`, fontWeight: tag.dismissals > 0 ? '600' : '400'}} >
              {tag.dismissals} {tag.assignments > 0 && (((tag.dismissals/tag.assignments)*100).toFixed(0) + '%')}
            </ColumnContent>   
            <ColumnContent className="detail" style={{gridColumn: `8`, padding: '1rem'}} >
              {tag.origin === 'default' ?
                `-`
              :
                tag.discrepancyFields.map((field, index) => (
                  index === (tag.discrepancyFields.length - 1) ?
                    <span key={field} style={{padding: '0 0 0 0.3rem'}}>{field}</span>
                  :
                    <span key={field} style={{padding: '0 0 0 0.3rem'}}>{field},</span>
                ))
              }
            </ColumnContent>   
          </ComponentSectionRow>
        ))}
      </ScrollWindow>
    </FlexWrapper>
  )
}

export default LeadTagPerformance;