import React from "react";
import HouseIcon from '@mui/icons-material/House';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import SellIcon from '@mui/icons-material/Sell';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import PersonIcon from '@mui/icons-material/Person';
import FoundationIcon from '@mui/icons-material/Foundation';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PoolIcon from '@mui/icons-material/Pool';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WarningIcon from '@mui/icons-material/Warning';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import WhiteLogo from '../../../../../icon.logo.white.png';
import { ActionIconWrapper, CardContainer, ComponentSectionRow, DetailText, Divider, FlexWrapper, GridWrapper, ImageElements, InfoBubble, InputWrapper, Label, ScrollWindow, StatusAndLabelWrapper, Typography } from "../../../../../StyledComponents";
import { useTheme } from "@mui/styles";

const SearchResults = ({ propertyDetails, showAll, origin }) => {
  const theme = useTheme()

  return (
    <CardContainer 
      className="report"
      style={{
        maxHeight: (origin === 'propertyDetails' || origin === 'repository') ? '100%' : '',
        minWidth: (origin === 'propertyDetails') ? '100%' : '70rem',
        boxShadow: (origin === 'propertyDetails') ? 'none' : `0 0 1.3rem ${theme.palette.common.grayText}`,
      }}
    >
      <ScrollWindow 
        className="report" 
        style={{
          margin: (origin === 'propertyDetails') ? 'auto' : '', 
          borderTop: (origin === 'propertyDetails') ? 'none' : `0.3rem solid ${theme.component.border.secondary}`, 
          borderBottom: (origin === 'propertyDetails') ? 'none' : `0.3rem solid ${theme.component.border.secondary}`,
        }}
      >
        <ComponentSectionRow className="column reportHead" style={{height: '27rem'}}>
          <FlexWrapper style={{position: 'relative'}}>
            <ImageElements src={WhiteLogo} style={{height: '22rem', width: 'auto', top: '3.1rem', left: '20.1rem', position: 'absolute', zIndex: '1', opacity: '0.3'}} />
          </FlexWrapper>
          <GridWrapper style={{gridTemplateRows: '6rem 4rem', gridTemplateColumns: '1fr 1fr 1fr'}}>
            <FlexWrapper className="column" style={{gridRow: '1/3', gridColumn: '1/3'}}>
              <Typography className="xxLarge">{propertyDetails.address.StreetAddressLine1.publicRecordValue}</Typography>
              <FlexWrapper className="alignEnd fitContentHeight">
                <Typography className="xxLarge">{propertyDetails.address.City.publicRecordValue}</Typography>
                <Typography className="large xxLargeHeight" style={{paddingTop: '0.4rem'}}>, {propertyDetails.address.StateOrProvince.publicRecordValue} {propertyDetails.address.PostalCode.publicRecordValue}</Typography>
              </FlexWrapper>
              <Typography className="xxLarge">
                {propertyDetails.lot.County.publicRecordValue}
                <Typography className="thin" style={{margin: '0 0 0.35rem 0.5rem'}}>County</Typography>
              </Typography>
            </FlexWrapper>
            <FlexWrapper className="justifyCenter" style={{gridRow: '2', gridColumn: '1/4', zIndex: '10'}}>
              {(((propertyDetails.owner1.Owner1IsCorporation.publicRecordValue.length > 0 && propertyDetails.owner1.Owner1IsCorporation.publicRecordValue === 'T') || (propertyDetails.owner1.CorporateIndicator.publicRecordValue === 'Y')) && (propertyDetails.owner2.Owner2IsCorporation.publicRecordValue.length > 0 && propertyDetails.owner2.Owner2IsCorporation.publicRecordValue === 'T')) ?
                <InfoBubble className="caution" style={{margin: 'auto 0.2rem', height: 'fit-content'}}>
                  Both Owners are Entities
                </InfoBubble>
              : (((propertyDetails.owner1.Owner1IsCorporation.publicRecordValue.length > 0 && propertyDetails.owner1.Owner1IsCorporation.publicRecordValue === 'T') || (propertyDetails.owner1.CorporateIndicator.publicRecordValue === 'Y')) || (propertyDetails.owner2.Owner2IsCorporation.publicRecordValue.length > 0 && propertyDetails.owner2.Owner2IsCorporation.publicRecordValue === 'T')) ?
                <InfoBubble className="caution" style={{margin: 'auto 0.2rem', height: 'fit-content'}}>
                  One owner is an Entity
                </InfoBubble>
              :
                <></>
              }
            </FlexWrapper>
            <FlexWrapper className="column" style={{gridColumn: '2/4', gridRow: '1/3'}}>
              <FlexWrapper className="justifyEnd alignCenter" style={{height: '2.5rem'}}>
                <Typography className="large" style={{padding: '0 0.4rem 0.15rem 0'}}>APN:</Typography>
                {propertyDetails.lot.ParcelNumber.publicRecordValue ?
                  <Typography className="xLarge bold tertiaryDark" style={{marginBottom: '0.2rem'}}>
                    {propertyDetails.lot.ParcelNumber.publicRecordValue}
                  </Typography>
                :
                  <Typography className="italics bold">-N/A-</Typography>
                }
              </FlexWrapper>
              <FlexWrapper className="justifyEnd alignCenter" style={{height: '2.5rem'}}>
                {propertyDetails.summary.PropertyType.publicRecordValue ?
                  propertyDetails.summary.PropertySubType.publicRecordValue ?
                    <Typography className="xLarge bold tertiaryDark">
                      {propertyDetails.summary.PropertyType.publicRecordValue.charAt(0) + propertyDetails.summary.PropertyType.publicRecordValue.substring(1).toLowerCase()}, {propertyDetails.summary.PropertySubType.publicRecordValue.charAt(0) + propertyDetails.summary.PropertySubType.publicRecordValue.substring(1).toLowerCase()}
                    </Typography>
                  :
                    <Typography className="xLarge bold tertiaryDark">
                      {propertyDetails.summary.PropertyType.publicRecordValue.charAt(0) + propertyDetails.summary.PropertyType.publicRecordValue.substring(1).toLowerCase()}
                    </Typography>
                : propertyDetails.lot.Zoning.publicRecordValue.length > 0 ?
                  propertyDetails.summary.PropertySubType.publicRecordValue ?
                    <Typography className="xLarge bold tertiaryDark">
                      {propertyDetails.lot.Zoning.publicRecordValue.charAt(0) + propertyDetails.lot.Zoning.publicRecordValue.substring(1).toLowerCase()}, {propertyDetails.summary.PropertySubType.publicRecordValue.charAt(0) + propertyDetails.summary.PropertySubType.publicRecordValue.substring(1).toLowerCase()}
                    </Typography>
                  :
                    <Typography className="xLarge bold tertiaryDark">
                      {propertyDetails.lot.Zoning.publicRecordValue.charAt(0) + propertyDetails.lot.Zoning.publicRecordValue.substring(1).toLowerCase()}
                    </Typography>
                :
                  <Typography className="italics bold">-N/A-</Typography>
                }
              </FlexWrapper>
              <FlexWrapper className="justifyEnd alignCenter" style={{height: '2.8rem'}}>
                {propertyDetails.building.NumberOfBuildings.publicRecordValue.length > 0 ?
                  <Typography className="xxLarge bold tertiaryDark" style={{marginBottom: '0.4rem'}}>
                    {propertyDetails.building.NumberOfBuildings.publicRecordValue}
                  </Typography>
                :
                  <Typography className="italics bold">-N/A-</Typography>
                }
                {propertyDetails.building.NumberOfBuildings.publicRecordValue.length > 1 ?
                  <Typography className="large" style={{padding: '0 0 0.15rem 0.4rem'}}>Buildings</Typography>
                : parseFloat(propertyDetails.building.NumberOfBuildings.publicRecordValue) === 1 ?
                  <Typography className="large" style={{padding: '0 0 0.15rem 0.4rem'}}>Building</Typography>
                :
                  <Typography className="large" style={{padding: '0 0 0.15rem 0.4rem'}}>Number of Buildings</Typography>
                }
              </FlexWrapper>
              <FlexWrapper className="justifyEnd alignCenter" style={{height: '2.8rem'}}>
                {propertyDetails.building.NumberOfUnitsTotal.publicRecordValue.length > 0 ?
                  <Typography className="xxLarge bold tertiaryDark" style={{marginBottom: '0.4rem'}}>
                    {propertyDetails.building.NumberOfUnitsTotal.publicRecordValue}
                  </Typography>
                :
                  <Typography className="italics bold">-N/A-</Typography>
                }
                {propertyDetails.building.NumberOfUnitsTotal.publicRecordValue.length > 1 ?
                  <Typography className="large" style={{padding: '0 0 0.15rem 0.4rem'}}>Units</Typography>
                : parseFloat(propertyDetails.building.NumberOfUnitsTotal.publicRecordValue) === 1 ?
                  <Typography className="large" style={{padding: '0 0 0.15rem 0.4rem'}}>Unit</Typography>
                :
                  <Typography className="large" style={{padding: '0 0 0.15rem 0.4rem'}}>Number of Units</Typography>
                }
              </FlexWrapper>
            </FlexWrapper>
          </GridWrapper>
          <GridWrapper style={{height: '15.5rem', gridTemplateRows: '8.5rem 1fr 4rem', gridTemplateColumns: '1fr 1fr 16rem'}}>
            <FlexWrapper className="column" style={{gridColumn: '1/3', gridRow: '1'}}>
              <FlexWrapper className="justifyStart alignCenter" style={{margin: '0 0 0.5rem 0.5rem'}}>
                {propertyDetails.owner1.Owner1FirstName.publicRecordValue && propertyDetails.owner1.Owner1LastName.publicRecordValue && propertyDetails.owner2.Owner2FirstName.publicRecordValue && propertyDetails.owner2.Owner2LastName.publicRecordValue ?
                  <>
                    <PeopleAltIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`, marginRight: '0.6rem'}} />
                    <Typography className="medium" style={{padding: '0 0.6rem 0.3rem 0'}}>Owners:</Typography>
                  </>
                : propertyDetails.owner1.Owner1FirstName.publicRecordValue.length > 0 && propertyDetails.owner1.Owner1LastName.publicRecordValue.length > 0 ?
                  <>
                    <PersonIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`, marginRight: '0.6rem'}} />
                    <Typography className="medium" style={{padding: '0 0.6rem 0.3rem 0'}}>Owner:</Typography>
                  </>
                :
                  <>
                    <PersonIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`, marginRight: '0.6rem'}} />
                    <Typography className="medium" style={{padding: '0 0.6rem 0.3rem 0'}}>Owner:</Typography>
                  </>
                }
                {propertyDetails.owner1.Owner1FullName.publicRecordValue.length > 0 && propertyDetails.owner2.Owner2FullName.publicRecordValue.length > 0 ?
                  <>
                    <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem', minWidth: '19.4rem', maxWidth: '19.4rem'}}>{propertyDetails.owner1.Owner1FullName.publicRecordValue}</Typography>
                    <Typography className="xLarge" style={{padding: '0 0.6rem 0.3rem 0.6rem'}}>&</Typography> 
                    <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem', width: '100%'}}>{propertyDetails.owner2.Owner2FullName.publicRecordValue}</Typography>
                  </>
                : propertyDetails.owner1.Owner1FirstName.publicRecordValue.length > 0 && propertyDetails.owner1.Owner1LastName.publicRecordValue.length > 0 && propertyDetails.owner2.Owner2FirstName.publicRecordValue.length > 0 && propertyDetails.owner2.Owner2LastName.publicRecordValue.length > 0 ?
                  <>
                    <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem', minWidth: '19.4rem', maxWidth: '19.4rem'}}>
                      {propertyDetails.owner1.Owner1FirstName.publicRecordValue} {propertyDetails.owner1.Owner1LastName.publicRecordValue}
                    </Typography>
                    <Typography className="xLarge" style={{padding: '0 0.6rem 0.3rem 0.6rem'}}>&</Typography> 
                    <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem', width: '100%'}}>
                      {propertyDetails.owner2.Owner2FirstName.publicRecordValue} {propertyDetails.owner2.Owner2LastName.publicRecordValue}
                    </Typography>
                  </>
                : propertyDetails.owner1.Owner1FullName.publicRecordValue.length > 0 && propertyDetails.owner2.Owner2FirstName.publicRecordValue.length > 0 && propertyDetails.owner2.Owner2LastName.publicRecordValue.length > 0 ?
                  <>
                    <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem', minWidth: '19.4rem', maxWidth: '19.4rem'}}>{propertyDetails.owner1.Owner1FullName.publicRecordValue}</Typography>
                    <Typography className="xLarge" style={{padding: '0 0.6rem 0.3rem 0.6rem'}}>&</Typography> 
                    <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem', width: '100%'}}>{propertyDetails.owner2.Owner2FirstName.publicRecordValue} {propertyDetails.owner2.Owner2LastName.publicRecordValue}</Typography>
                  </>
                : propertyDetails.owner1.Owner1FullName.publicRecordValue.length > 0 ?
                  <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem'}}>
                    {propertyDetails.owner1.Owner1FullName.publicRecordValue}
                  </Typography>
                : propertyDetails.owner1.Owner1FirstName.publicRecordValue.length > 0 && propertyDetails.owner1.Owner1LastName.publicRecordValue.length > 0 ?
                  <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem'}}>
                    {propertyDetails.owner1.Owner1FirstName.publicRecordValue} {propertyDetails.owner1.Owner1LastName.publicRecordValue}
                  </Typography>
                : propertyDetails.owner2.Owner2FullName.publicRecordValue.length > 0 ?
                  <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem'}}>{propertyDetails.owner2.Owner2FullName.publicRecordValue}</Typography>
                : propertyDetails.owner2.Owner2FirstName.length > 0 && propertyDetails.owner2.Owner2LastName.length > 0 ?
                  <Typography className="xLarge bold tertiaryDark" style={{paddingBottom: '0.3rem'}}>{propertyDetails.owner2.Owner2FirstName.publicRecordValue} {propertyDetails.owner2.Owner2LastName.publicRecordValue}</Typography>
                :
                  <FlexWrapper className="alignCenter" style={{paddingTop: '1rem', height: '3rem'}}>
                    <Typography className="italics bold tertiary" style={{height: '3rem', marginLeft: '0.5rem'}}>-N/A-</Typography>
                  </FlexWrapper>
                }
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper className="column justifyCenter" style={{width: '18rem', gridRow: '2', gridColumn: '1/3'}}>
              <FlexWrapper className="alignCenter" style={{height: "2.5rem"}}>
                <Typography style={{marginRight: '1rem', width: '10.5rem'}}>Owner Occupied</Typography>
                {propertyDetails.tax.OwnerOccupied.publicRecordValue ?
                  propertyDetails.tax.OwnerOccupied.publicRecordValue === 'Y' ?
                    <Typography className="medium bold">
                      Yes
                    </Typography>
                  : propertyDetails.tax.OwnerOccupied.publicRecordValue === 'N' ?
                    <Typography className="medium bold">
                      No
                    </Typography>
                  :
                    <Typography className="medium bold">
                      {propertyDetails.tax.OwnerOccupied.publicRecordValue}
                    </Typography>
                :
                  <Typography className="italics bold">-N/A-</Typography>
                }
              </FlexWrapper>
              <FlexWrapper className="alignCenter" style={{height: "2.5rem"}}>
                <Typography style={{marginRight: '1rem', width: '10.5rem'}}>Ownership Rights</Typography>
                {propertyDetails.owner1.Owner1OwnershipRights.length > 0 ?
                  <Typography className="large bold">
                    {propertyDetails.owner1.Owner1OwnershipRights}
                  </Typography>
                :
                  <Typography className="italics bold">-N/A-</Typography>
                }
              </FlexWrapper>
            </FlexWrapper>
            {(propertyDetails.tax.TaxExemptionHomestead.length > 0 || propertyDetails.tax.TaxExemptionVeteran.length > 0 || propertyDetails.tax.TaxExemptionDisabled.length > 0 || propertyDetails.tax.TaxExemptionWidow.length > 0 || propertyDetails.tax.TaxExemptionSenior.length > 0 || propertyDetails.tax.TaxExemptionSchoolCollege.length > 0 || propertyDetails.tax.TaxExemptionReligious.length > 0 || propertyDetails.tax.TaxExemptionWelfare.length > 0 || propertyDetails.tax.TaxExemptionPublicUtility.length > 0 || propertyDetails.tax.TaxExemptionCemetery.length > 0 || propertyDetails.tax.TaxExemptionHospital.length > 0 || propertyDetails.tax.TaxExemptionLibrary.length > 0) &&
              <FlexWrapper className="column fitContentHeight fitContentWidth" style={{gridRow: '1/4', gridColumn: '3', zIndex: '10', margin: 'auto'}}>
                <Typography className="bold" style={{margin: 'auto'}}>Tax Exemptions</Typography>
                <ActionIconWrapper className="wrapped success reportHeader" style={{marginLeft: '0.5rem', padding: '0.4rem 0.8rem', width: '15rem', flexFlow: 'wrap'}}>
                  {propertyDetails.tax.TaxExemptionHomestead.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Homestead
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionVeteran.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Veteran
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionDisabled.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Disabled
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionWidow.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Widow
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionSenior.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Senior
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionSchoolCollege.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Scool/College
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionReligious.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Religious
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionWelfare.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Welfare
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionPublicUtility.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Public Utility
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionCemetery.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Cemetary
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionHospital.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Hospital
                    </Typography>
                  }
                  {propertyDetails.tax.TaxExemptionLibrary.length > 0 &&
                    <Typography style={{margin: '0 0.2rem'}}>
                      Library
                    </Typography>
                  }
                </ActionIconWrapper>
              </FlexWrapper>
            }
            <FlexWrapper className="justifyCenter alignEnd" style={{width: '15.5rem', gridRow: '3', gridColumn: '1/3', margin: 'auto', zIndex: '10'}}>
              {(propertyDetails.summary.QuitClaimFlag.publicRecordValue && propertyDetails.summary.QuitClaimFlag.publicRecordValue === "True") &&
                <ActionIconWrapper className="wrapped error reportHeader" style={{height: '3rem', width: '16rem', margin: '0.2rem'}}>
                  <AssignmentLateIcon style={{width: '2rem', height: '2rem', marginRight: '0.3rem'}} />
                  Quit Claim discovered
                </ActionIconWrapper>
              }
              {(propertyDetails.summary.REOflag.publicRecordValue && propertyDetails.summary.REOflag.publicRecordValue === "True") &&
                <ActionIconWrapper className="wrapped error reportHeader" style={{height: '3rem', width: '16rem', margin: '0.2rem'}}>
                  <AccountBalanceIcon style={{width: '2rem', height: '2rem', marginRight: '0.3rem'}} />
                  Foreclosure discovered
                </ActionIconWrapper>
              }
              {(propertyDetails.summary.DistressYN.publicRecordValue && propertyDetails.summary.DistressYN.publicRecordValue === "Y") &&
                <ActionIconWrapper className="wrapped caution reportHeader" style={{height: '3rem', width: '13rem', margin: '0.2rem'}}>
                  <WarningIcon style={{width: '2rem', height: '2rem', marginRight: '0.3rem'}} />
                  Distress detected
                </ActionIconWrapper>
              }
            </FlexWrapper>
          </GridWrapper>
        </ComponentSectionRow>

        <FlexWrapper className="column" style={{width: '70rem', margin: '0 auto'}}>
          <FlexWrapper className="fitContentHeight" style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
            <FlexWrapper className="column quarterWidth">
              <FlexWrapper className="column fitContentWidth alignCenter center">
                {propertyDetails.assessment.AssessedValue.publicRecordValue > 0 ?
                  <Typography className="xxLarge alignCenter bold">
                    <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`}}>$</Typography>
                    {parseFloat(propertyDetails.assessment.AssessedValue.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                : 
                  <Typography className="italics alignCenter bold" style={{height: '3rem', paddingTop: '0.5rem'}}>-N/A-</Typography>
                }
                <Typography>Assessed Value</Typography>
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper className="column quarterWidth">
              <FlexWrapper className="column fitContentWidth alignCenter center">
                {propertyDetails.assessment.AssessedLandValue.publicRecordValue > 0 ?
                  <Typography className="xxLarge alignCenter bold">
                    <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`}}>$</Typography>
                    {parseFloat(propertyDetails.assessment.AssessedLandValue.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                : 
                  <Typography className="italics alignCenter bold" style={{height: '3rem', paddingTop: '0.5rem'}}>-N/A-</Typography>
                }
                <Typography>Land Value</Typography>
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper className="column quarterWidth">
              <FlexWrapper className="column fitContentWidth alignCenter center">
                {propertyDetails.assessment.AssessedImprovementValue.publicRecordValue > 0 ?
                  <Typography className="xxLarge alignCenter bold">
                    <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`}}>$</Typography>
                    {parseFloat(propertyDetails.assessment.AssessedImprovementValue.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                : 
                  <Typography className="italics alignCenter bold" style={{height: '3rem', paddingTop: '0.5rem'}}>-N/A-</Typography>
                }
                <Typography>Improvement Value</Typography>
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper className="column quarterWidth">
              <FlexWrapper className="column alignCenter fitContentWidth center">
                {propertyDetails.assessment.AssessedYear.publicRecordValue.length > 0 ?
                  <Typography className="xxLarge alignCenter bold">
                    {propertyDetails.assessment.AssessedYear.publicRecordValue}
                  </Typography>
                : 
                  <Typography className="italics alignCenter bold" style={{height: '3rem', paddingTop: '0.5rem'}}>-N/A-</Typography>
                }
                <Typography>Year Assessed</Typography>
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>

          <Divider className="gray full" style={{width: '95%', margin: '1rem auto'}} />

          <FlexWrapper className="alignCenter fitContentHeight" style={{padding: '0 13rem'}}>
            <FlexWrapper>
              <Typography className="alignCenter" style={{minWidth: '6rem'}}>Lot Size</Typography>
              <FlexWrapper className="column justifyCenter">
                {propertyDetails.lot.LotSizeAcres.publicRecordValue > 0 &&
                  <Typography className="medium bold">
                    {propertyDetails.lot.LotSizeAcres.publicRecordValue}
                    <Typography className="thin" style={{margin: '0 0 0.1rem 0.5rem'}}>acres</Typography>
                  </Typography>
                }
                {propertyDetails.lot.LotSizeSquareFeet.publicRecordValue > 0 &&
                  <Typography className="medium bold">
                    {parseFloat(propertyDetails.lot.LotSizeSquareFeet.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                    <Typography className="thin" style={{margin: '0 0 0.1rem 0.5rem'}}>sq ft</Typography>
                  </Typography>
                }
                {(propertyDetails.lot.LotSizeAcres.publicRecordValue === 0 && propertyDetails.lot.LotSizeSquareFeet.publicRecordValue === 0) &&
                  <Typography className="italics bold">-N/A-</Typography>
                }
              </FlexWrapper>
            </FlexWrapper>
            <FlexWrapper className="column">
              <FlexWrapper className="alignCenter justifyEnd">
                <Typography style={{marginRight: '0.5rem'}}>Annual Tax Amount</Typography>
                {propertyDetails.tax.TaxAnnualAmount.publicRecordValue.length > 0 ?
                  <Typography className="xxLarge alignCenter bold">
                    <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`}}>$</Typography>
                      {parseFloat(propertyDetails.tax.TaxAnnualAmount.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                : 
                  <Typography className="italics bold alignCenter">-N/A-</Typography>
                }
              </FlexWrapper>
              <FlexWrapper className="alignCenter justifyEnd">
                <Typography style={{marginRight: '0.5rem'}}>Tax Year</Typography>
                {propertyDetails.tax.TaxYear.publicRecordValue ?
                  <Typography className="medium bold">
                    {propertyDetails.tax.TaxYear.publicRecordValue}
                  </Typography>
                :
                  <Typography className="italics">not available</Typography>
                }
              </FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>

          <FlexWrapper className="column fitContentHeight" style={{padding: '4rem 3rem 0 3rem'}}>
            <FlexWrapper className="alignCenter"  style={{marginTop: '0.3rem'}}>
              <HistoryEduIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`}} />
              <Typography className="large" style={{minWidth: '18rem', marginLeft: '0.5rem'}}>Current Mortgage:</Typography>
              <FlexWrapper style={{margin: 'auto 0'}}>
                {propertyDetails.primaryMortgage.PrimaryMortgageAmount.publicRecordValue ?
                  <Typography className="xxLarge alignCenter bold">
                    <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`}}>$</Typography>
                    {parseFloat(propertyDetails.primaryMortgage.PrimaryMortgageAmount.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                  </Typography>
                :
                  <></>
                }
              </FlexWrapper>
              <FlexWrapper style={{margin: 'auto 0'}}>
                {propertyDetails.primaryMortgage.PrimaryMortgageInterestRate.publicRecordValue ?
                  <Typography className="xxLarge alignCenter bold">
                    {propertyDetails.primaryMortgage.PrimaryMortgageInterestRate.publicRecordValue}
                    <Typography className="Large" style={{color: `${theme.accent.success.primary}`}}>%</Typography>
                  </Typography>
                :
                  <></>
                }
              </FlexWrapper>
              <FlexWrapper className="alignCenter" style={{margin: "auto 0.5rem auto 0"}}>
                {propertyDetails.primaryMortgage.PrimaryLoanType.publicRecordValue ?
                  <Typography className="large bold">
                    {propertyDetails.primaryMortgage.PrimaryLoanType.publicRecordValue}
                  </Typography>
                :
                  <Typography className="italics" style={{paddingTop: '0.5rem'}}>loan type not found</Typography>
                }
              </FlexWrapper>
              <FlexWrapper className="alignCenter" style={{margin: "auto 0"}}>
                {propertyDetails.primaryMortgage.PrimaryMortgageTerm.publicRecordValue ?
                  <Typography className="large bold">
                    {propertyDetails.primaryMortgage.PrimaryMortgageTerm.publicRecordValue}
                    <Typography className="thin" style={{margin: '0 0 0.3rem 0.5rem'}}>years</Typography>
                  </Typography>
                :
                  <></>
                }
              </FlexWrapper>
            </FlexWrapper>

            <Divider className="gray full" style={{margin: '0.5rem auto 1rem auto', height: '0.2rem'}} />

            <FlexWrapper style={{padding: '0 3.6rem'}}>
              <FlexWrapper className="column" style={{marginTop: '0.3rem'}}>
                <FlexWrapper className="column justifyCenter">
                  <Typography>Lender</Typography>
                  {propertyDetails.primaryMortgage.PrimaryLenderName.publicRecordValue ?
                    <Typography className="medium bold">
                      {propertyDetails.primaryMortgage.PrimaryLenderName.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics alignCenter bold" style={{height: '2rem'}}>-N/A-</Typography>
                  }
                </FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{marginTop: '0.5rem'}}>
                  <Typography>Title Company</Typography>
                  {propertyDetails.primaryMortgage.TitleCompany.publicRecordValue && propertyDetails.primaryMortgage.TitleCompany.publicRecordValue.trim() !== 'NONE AVAILABLE' ?
                    <Typography className="medium bold">
                      {propertyDetails.primaryMortgage.TitleCompany.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics alignCenter bold" style={{height: '2rem'}}>-N/A-</Typography>
                  }
                </FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{marginTop: '0.5rem'}}>
                  <Typography>Mortgage Transaction Type</Typography>
                  {propertyDetails.primaryMortgage.MortgageTransactionDescription.publicRecordValue ?
                    <Typography className="medium bold">
                      {propertyDetails.primaryMortgage.MortgageTransactionDescription.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics alignCenter bold" style={{height: '2rem'}}>-N/A-</Typography>
                  }
                </FlexWrapper>
                <FlexWrapper className="column justifyCenter" style={{marginTop: '0.5rem'}}>
                  <Typography>Deed Category</Typography>
                  {propertyDetails.primaryMortgage.DeedCategoryDescriprion.publicRecordValue ?
                    <Typography className="medium bold">
                      {propertyDetails.primaryMortgage.DeedCategoryDescriprion.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics alignCenter bold" style={{height: '2rem'}}>-N/A-</Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
              <FlexWrapper className="column">
                <FlexWrapper className="fitContentHeight" style={{padding: '0.5rem 0 0.5rem 0'}}>
                  {propertyDetails.primaryMortgage.PrimaryMortgageStartDate.publicRecordValue ?
                    <Typography className="large bold autoMarginHorizontal">
                      <Typography className="thin" style={{margin: '0 0.8rem 0.3rem 0.3rem'}}>originated</Typography>
                      {propertyDetails.primaryMortgage.PrimaryMortgageStartDate.publicRecordValue}
                    </Typography>
                  :
                    <></>
                  }
                </FlexWrapper>
                <FlexWrapper className="autoMarginHorizontal fitContentWidth alignCenter" style={{height: '3rem'}}>
                  <Typography>Borrower Relationship</Typography>
                  {propertyDetails.primaryMortgage.BorrowerRelationship.publicRecordValue ?
                    <Typography className="medium bold" style={{padding: '0 0 0 0.5rem'}}>
                      {propertyDetails.primaryMortgage.BorrowerRelationship.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics alignCenter bold" style={{padding: '0.1rem 0 0 0.5rem'}}>-N/A-</Typography>
                  }
                </FlexWrapper>
                <FlexWrapper className="autoMarginHorizontal fitContentWidth alignCenter" style={{height: '3rem'}}>
                  <Typography>Ownership Rights</Typography>
                  {propertyDetails.primaryMortgage.OwnershipRights.publicRecordValue ?
                    <Typography className="medium bold" style={{padding: '0 0 0 0.5rem'}}>
                      {propertyDetails.primaryMortgage.OwnershipRights.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics alignCenter bold" style={{padding: '0.1rem 0 0 0.5rem'}}>-N/A-</Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>

            <FlexWrapper className="column" style={{marginTop: '4rem'}}>
              <FlexWrapper>
                <SellIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`}} />
                <FlexWrapper>
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Last Sale:</Typography>
                  <FlexWrapper className="fitContentWidth" style={{height: '3rem'}}>
                    {propertyDetails.sale.LastSaleDate.publicRecordValue.length > 0 &&
                      <FlexWrapper style={{height: '3rem', margin: '0.2rem 0 0 1rem'}}>
                        <Typography className="large bold">
                          {propertyDetails.sale.LastSaleDate.publicRecordValue}
                        </Typography>
                      </FlexWrapper>
                    }
                    {propertyDetails.sale.ClosePrice.publicRecordValue > 0 ?
                      <Typography className="xxLarge alignCenter bold" style={{margin: '0.7rem 0 0 3rem'}}>
                        <Typography className="xLarge" style={{color: `${theme.accent.success.primary}`}}>$</Typography>
                        {parseFloat(propertyDetails.sale.ClosePrice.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                      </Typography>
                    :
                      <FlexWrapper className="alignCenter" style={{margin: '0.7rem 0 0 3rem', height: '3rem'}}>
                        <Typography>Price</Typography>
                        <Typography className="italics bold alignCenter" style={{height: '3rem', marginLeft: '0.5rem'}}>-N/A-</Typography>
                      </FlexWrapper>
                    }
                  {propertyDetails.sale.LastSaleDate.publicRecordValue.length === 0 &&
                    <FlexWrapper style={{height: '3rem', margin: '0.7rem 0 0 3rem'}}>
                      <Typography className="italics autoMarginVertical alignCenter" style={{height: '3rem'}}>
                        sale recording data not available
                      </Typography>
                    </FlexWrapper>
                  }
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>

              <Divider className="gray full" style={{margin: '0.5rem auto 1rem auto'}} />

              <FlexWrapper style={{padding: '0 3.6rem'}}>
                <FlexWrapper className="column">
                  <FlexWrapper className="column">
                    <Typography>Document Type</Typography>
                    {propertyDetails.sale.LastSaleDocumentType.publicRecordValue ?
                      <Typography className="medium bold">
                        {propertyDetails.sale.LastSaleDocumentType.publicRecordValue}
                      </Typography>
                    : propertyDetails.sale.SaleDocType.publicRecordValue ?
                      <Typography className="medium bold">
                        {propertyDetails.sale.SaleDocType.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics alignCenter bold" style={{height: '2rem'}}>-N/A-</Typography>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="column">
                    <Typography>Ownership Interest</Typography>
                    {propertyDetails.sale.LeaseholdOrFeeSimple.publicRecordValue == 'FEESIMPLE' ?
                      <Typography className="medium bold">Fee Simple</Typography>
                    : propertyDetails.sale.LeaseholdOrFeeSimple.publicRecordValue === 'LEASEHOLD' ?
                      <Typography className="medium bold">Leased Fee</Typography>
                    :
                      <Typography className="italics alignCenter bold" style={{height: '2rem'}}>-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>

                <FlexWrapper className="column">
                  <FlexWrapper className="column">
                    {propertyDetails.sale.LastSaleSeller1FullName.publicRecordValue.length > 0 && propertyDetails.sale.LastSaleSeller2FullName.publicRecordValue.length > 0 ?
                      <FlexWrapper className="column">
                        <Typography>Selling Parties</Typography>
                        <FlexWrapper>
                          <Typography className="xLarge bold" style={{paddingBottom: '0.3rem'}}>
                            {propertyDetails.sale.LastSaleSeller1FullName.publicRecordValue}
                          </Typography>
                          <Typography className="xLarge" style={{padding: '0 0.6rem 0.3rem 0.6rem'}}>&</Typography> 
                          <Typography className="xLarge bold" style={{paddingBottom: '0.3rem'}}>
                            {propertyDetails.sale.LastSaleSeller2FullName.publicRecordValue}
                          </Typography>
                        </FlexWrapper>
                      </FlexWrapper>
                    : propertyDetails.sale.LastSaleSeller1FullName.publicRecordValue.length > 0 ?
                      <FlexWrapper className="column">
                        <Typography>Selling Party</Typography>
                        <Typography className="xLarge bold" style={{paddingBottom: '0.3rem'}}>
                          {propertyDetails.sale.LastSaleSeller1FullName.publicRecordValue}
                        </Typography>
                      </FlexWrapper>
                    : propertyDetails.sale.LastSaleSeller2FullName.publicRecordValue.length > 0 ?
                      <FlexWrapper className="column">
                        <Typography>Selling Party</Typography>
                        <Typography className="xLarge bold" style={{paddingBottom: '0.3rem'}}>
                          {propertyDetails.sale.LastSaleSeller2FullName.publicRecordValue}
                        </Typography>
                      </FlexWrapper>
                    :
                      <FlexWrapper className="column">
                        <Typography>Selling Party</Typography>
                        <Typography className="italics alignCenter bold" style={{height: '2rem'}}>-N/A-</Typography>
                      </FlexWrapper>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="column">
                    {propertyDetails.sale.LastSaleBuyerName.publicRecordValue.length > 0 ?
                      <FlexWrapper className="column">
                        <Typography>Buying Parties</Typography>
                        <Typography className="xLarge bold" style={{paddingBottom: '0.3rem'}}>
                          {propertyDetails.sale.LastSaleBuyerName.publicRecordValue}
                        </Typography>
                      </FlexWrapper>
                    :
                      <></>
                    }
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>


            {propertyDetails.secondaryMortgage.SecondaryMortgageRecordingDate.publicRecordValue &&
              <>
                <FlexWrapper className="alignEnd" style={{marginTop: '5rem'}}>
                  <NewReleasesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`}} />
                  <DetailText style={{paddingBottom: '0.3rem'}}>
                    Second Mortgage
                  </DetailText>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                    <StatusAndLabelWrapper>
                      <Label>Lender</Label>
                    </StatusAndLabelWrapper>
                    <DetailText>
                      {propertyDetails.secondaryMortgage.SecondaryLenderName.publicRecordValue ?
                        `${propertyDetails.secondaryMortgage.SecondaryLenderName.publicRecordValue }`
                      :
                        <Typography className="italics bold alignCenter">-N/A-</Typography>
                      }
                    </DetailText>
                  </InputWrapper>
                  <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                    <StatusAndLabelWrapper>
                      <Label>Document Type</Label>
                    </StatusAndLabelWrapper>
                    <DetailText>
                      {propertyDetails.secondaryMortgage.SecondaryLoanType.publicRecordValue ?
                        `${propertyDetails.secondaryMortgage.SecondaryLoanType.publicRecordValue }`
                      :
                        <Typography className="italics bold alignCenter">-N/A-</Typography>
                      }
                    </DetailText>
                  </InputWrapper>
                </ComponentSectionRow>

                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                    <StatusAndLabelWrapper>
                      <Label>Mortgage Recording Date</Label>
                    </StatusAndLabelWrapper>
                    <DetailText>
                      {propertyDetails.secondaryMortgage.SecondaryMortgageRecordingDate.publicRecordValue ?
                        `${propertyDetails.secondaryMortgage.SecondaryMortgageRecordingDate.publicRecordValue }`
                      :
                        <Typography className="italics bold alignCenter">-N/A-</Typography>
                      }
                    </DetailText>
                  </InputWrapper>
                  <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                    <StatusAndLabelWrapper>
                      <Label>Amount</Label>
                    </StatusAndLabelWrapper>
                    <DetailText>
                      {propertyDetails.secondaryMortgage.SecondaryMortgageAmount.publicRecordValue ?
                        `${propertyDetails.secondaryMortgage.SecondaryMortgageAmount.publicRecordValue }`
                      :
                        <Typography className="italics bold alignCenter">-N/A-</Typography>
                      }
                    </DetailText>
                  </InputWrapper>
                  <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                    <StatusAndLabelWrapper>
                      <Label>SecondaryMortgageTerm</Label>
                    </StatusAndLabelWrapper>
                    <DetailText>
                      {propertyDetails.secondaryMortgage.SecondaryMortgageTerm.publicRecordValue ?
                        `${propertyDetails.secondaryMortgage.SecondaryMortgageTerm.publicRecordValue }`
                      :
                        <Typography className="italics bold alignCenter">-N/A-</Typography>
                      }
                    </DetailText>
                  </InputWrapper>
                </ComponentSectionRow>
              </>
            }

            <FlexWrapper className="column" style={{marginTop: '5rem'}}>
              <FlexWrapper className="alignEnd">
                <HouseIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`}} />
                <FlexWrapper className="column">
                  <FlexWrapper>
                    <Typography className="large" style={{margin: '0 1rem 0 0.5rem'}}>Living:</Typography>
                    <FlexWrapper className="alignCenter" style={{paddingTop: '2rem', height: "2.5rem"}}>
                      <Typography style={{marginRight: '1rem'}}>Year Built</Typography>
                      {propertyDetails.building.YearBuilt.publicRecordValue ?
                        <Typography className="large bold">
                          {propertyDetails.building.YearBuilt.publicRecordValue}
                        </Typography>
                      : propertyDetails.building.YearBuiltEffective.publicRecordValue ?
                        <Typography className="large bold">
                          {propertyDetails.building.YearBuiltEffective.publicRecordValue}
                        </Typography>
                      :
                        <Typography className="italics">
                          sale recording data not available
                        </Typography>
                      }
                    </FlexWrapper>
                    <FlexWrapper className="alignCenter" style={{paddingTop: '2rem', height: "2.5rem"}}>
                      <Typography style={{marginRight: '1rem'}}>Stories</Typography>
                      {propertyDetails.living.Stories.publicRecordValue ?
                        <Typography className="medium bold">
                          {propertyDetails.living.Stories.publicRecordValue}
                        </Typography>
                      :
                        <Typography className="italics bold alignCenter">-N/A-</Typography>
                      }
                    </FlexWrapper>
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>

              <Divider className="gray full" style={{width: '100%', margin: '0.5rem auto'}} />

              <ComponentSectionRow className="justifyCenter" style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                <FlexWrapper style={{width: 'fit-content'}}>
                  {parseFloat(propertyDetails.living.FireplacesTotal.publicRecordValue) === 1 ?
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <FireplaceIcon style={{height: '3rem', width: '3rem', color: `${theme.accent.caution.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="medium bold alignCenter">{propertyDetails.living.FireplacesTotal.publicRecordValue} Fireplace</Typography>
                    </FlexWrapper>
                  : parseFloat(propertyDetails.living.FireplacesTotal.publicRecordValue) >1 ?
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <FireplaceIcon style={{height: '3rem', width: '3rem', color: `${theme.accent.caution.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="medium bold alignCenter">{propertyDetails.living.FireplacesTotal.publicRecordValue} Fireplaces</Typography>
                    </FlexWrapper>
                  :
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <FireplaceIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="italics alignCenter">no fireplace</Typography>
                    </FlexWrapper>
                  }
                  {propertyDetails.living.Heating.publicRecordValue && propertyDetails.living.Heating.publicRecordValue !== "NONE" ?
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <LocalFireDepartmentIcon style={{height: '3rem', width: '3rem', color: `${theme.accent.caution.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="medium bold alignCenter">{propertyDetails.living.Heating.publicRecordValue.charAt(0).toUpperCase() + propertyDetails.living.Heating.publicRecordValue.slice(1).toLowerCase() }</Typography>
                    </FlexWrapper>
                  :
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <LocalFireDepartmentIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="italics alignCenter">no heating</Typography>
                    </FlexWrapper>
                  }
                  {propertyDetails.living.Cooling.publicRecordValue === 'Yes' ?
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <AcUnitIcon style={{height: '3rem', width: '3rem', color: `${theme.accent.tertiary.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="medium bold alignCenter">Cooling</Typography>
                    </FlexWrapper>
                  : propertyDetails.living.Cooling.publicRecordValue ?
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <AcUnitIcon style={{height: '3rem', width: '3rem', color: `${theme.accent.tertiary.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="medium bold alignCenter">{propertyDetails.living.Cooling.publicRecordValue}</Typography>
                    </FlexWrapper>
                  :
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <AcUnitIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="italics alignCenter">no cooling</Typography>
                    </FlexWrapper>
                  }
                  {propertyDetails.living.PoolType.publicRecordValue && propertyDetails.living.PoolType.publicRecordValue !== "NO POOL" ?
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <PoolIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="medium bold alignCenter">{propertyDetails.living.PoolType.publicRecordValue}</Typography>
                    </FlexWrapper>
                  :
                    <FlexWrapper className="column alignCenter" style={{margin: '0 1.5rem'}}>
                      <PoolIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                      <Typography style={{height: '2.5rem'}} className="italics alignCenter">no pool</Typography>
                    </FlexWrapper>
                  }
                </FlexWrapper>
              </ComponentSectionRow>

              <Divider className="gray full" style={{width: '100%', margin: '0 auto 1rem auto'}} />

              <FlexWrapper style={{margin: '0', padding: '0 2.5rem'}}>
                <FlexWrapper className="column" style={{marginBottom: 'auto'}}>
                  <FlexWrapper style={{height: "2.5rem"}}>
                    <Typography className="alignCenter" style={{marginRight: '1rem'}}>Building</Typography>
                    {propertyDetails.living.BuildingAreaTotal.publicRecordValue > 0 ?
                      <Typography className="xLarge bold">
                        {parseFloat(propertyDetails.living.BuildingAreaTotal.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        <Typography className="thin" style={{margin: '0 0 0.35rem 0.5rem'}}>sq ft</Typography>
                      </Typography>
                    : propertyDetails.living.GrossArea.publicRecordValue > 0 ?
                      <Typography className="xLarge bold">
                        {parseFloat(propertyDetails.living.GrossArea.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        <Typography className="thin" style={{margin: '0 0 0.35rem 0.5rem'}}>sq ft</Typography>
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                  <FlexWrapper style={{height: "2.5rem"}}>
                    <Typography className="alignCenter" style={{marginRight: '1rem'}}>Living</Typography>
                    {propertyDetails.living.LivingArea.publicRecordValue > 0 ?
                      <Typography className="xLarge bold">
                        {parseFloat(propertyDetails.living.LivingArea.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        <Typography className="thin" style={{margin: '0 0 0.35rem 0.5rem'}}>sq ft</Typography>
                      </Typography>
                    : propertyDetails.living.LivingArea.publicRecordValue > 0 ?
                      <Typography className="xLarge bold">
                        {parseFloat(propertyDetails.living.LivingArea.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        <Typography className="thin" style={{margin: '0 0 0.35rem 0.5rem'}}>sq ft</Typography>
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>

                <FlexWrapper className="column">
                  <FlexWrapper className="justifyCenter" style={{height: "2.5rem"}}>
                    <Typography className="alignCenter" style={{marginRight: '1rem'}}>Bedrooms</Typography>
                    {propertyDetails.living.BedroomsTotal.publicRecordValue && propertyDetails.living.BedroomsTotal.publicRecordValue > 0 ?
                      <Typography className="xLarge bold">
                        {propertyDetails.living.BedroomsTotal.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="justifyCenter" style={{height: "2.5rem"}}>
                    <Typography className="alignCenter" style={{marginRight: '1rem'}}>Bathrooms</Typography>
                    {propertyDetails.living.BathroomsTotalInteger.publicRecordValue && propertyDetails.living.BathroomsTotalInteger.publicRecordValue > 0 ?
                      <Typography className="xLarge bold">
                        {propertyDetails.living.BathroomsTotalInteger.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="justifyCenter" style={{height: "2.5rem"}}>
                    <Typography className="alignCenter" style={{marginRight: '1rem'}}>Total Rooms</Typography>
                    {propertyDetails.living.RoomsTotal.publicRecordValue && propertyDetails.living.RoomsTotal.publicRecordValue > 0 ?
                      <Typography className="xLarge bold">
                        {propertyDetails.living.RoomsTotal.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>

                <FlexWrapper className="column">
                  <FlexWrapper className="column alignEnd">
                    <Typography className="alignCenter">Car Storage Type</Typography>
                    {propertyDetails.building.CarStorageType.publicRecordValue ?
                      <Typography className="xLarge bold">
                        {propertyDetails.building.CarStorageType.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter" style={{marginRight: '3rem'}}>-N/A-</Typography>
                    } 
                  </FlexWrapper>

                  <FlexWrapper className="justifyEnd" style={{height: "2.5rem"}}>
                    <Typography className="alignCenter" style={{marginRight: '1rem'}}>Garage Area</Typography>
                    {propertyDetails.building.GarageArea.publicRecordValue ?
                      <Typography className="xLarge bold">
                        {parseFloat(propertyDetails.building.GarageArea.publicRecordValue).toLocaleString("en-US", {maximumFractionDigits: 0, minimumFractionDigits: 0})}
                        <Typography className="thin" style={{margin: '0 0 0.35rem 0.5rem'}}>sq ft</Typography>
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    } 
                  </FlexWrapper>

                  <FlexWrapper className="justifyEnd" style={{height: "2.5rem"}}>
                    {propertyDetails.building.GarageSpaces.publicRecordValue ?
                      <Typography className="alignCenter" style={{marginRight: '1rem'}}>Garage Spaces</Typography>
                    :
                      <Typography className="alignCenter" style={{marginRight: '1rem'}}>Parking Spaces</Typography>
                    }
                    {propertyDetails.building.GarageSpaces.publicRecordValue > propertyDetails.building.ParkingSpaces.publicRecordValue ?
                      <Typography className="xLarge bold">
                        {propertyDetails.building.GarageSpaces.publicRecordValue}
                      </Typography>
                    : propertyDetails.building.GarageSpaces.publicRecordValue < propertyDetails.building.ParkingSpaces.publicRecordValue ?
                      <Typography className="xLarge bold">
                        {propertyDetails.building.ParkingSpaces.publicRecordValue}
                      </Typography>
                    : propertyDetails.building.ParkingSpaces.publicRecordValue ?
                      <Typography className="xLarge bold">
                        {propertyDetails.building.ParkingSpaces.publicRecordValue}
                      </Typography>
                    : propertyDetails.building.GarageSpaces.publicRecordValue ?
                      <Typography className="xLarge bold">
                        {propertyDetails.building.GarageSpaces.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>

            <FlexWrapper className="column" style={{marginTop: '4rem'}}>
              <FlexWrapper>
                <FoundationIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`}} />
                <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Building:</Typography>
              </FlexWrapper>
              <Divider className="gray full" style={{margin: '0.5rem 0 0 0'}} />
              <FlexWrapper>
                <FlexWrapper className="column" style={{width: 'fit-content'}}>
                  {(propertyDetails.building.BuildingQualityScore.publicRecordValue.length > 0) &&
                    <FlexWrapper className="column alignCenter" style={{paddingBottom: '0.5rem', width: '20rem'}}>
                      <Typography className="bold" style={{fontSize: '5rem'}}>{propertyDetails.building.BuildingQualityScore.publicRecordValue}</Typography>
                      <Typography>Quality Score</Typography>
                    </FlexWrapper>
                  }
                  {(propertyDetails.building.BuildingCondition.publicRecordValue.length > 0) &&
                    <FlexWrapper className="justifyCenter alignCenter">
                      <Typography>Condition</Typography>
                      <Typography className="medium bold" style={{paddingLeft: '0.5rem', width: '20rem'}}>{propertyDetails.building.BuildingCondition.publicRecordValue}</Typography>
                    </FlexWrapper>
                  }
                  {(propertyDetails.summary.WaterSource.publicRecordValue) &&
                    <FlexWrapper className="justifyCenter alignCenter">
                      <Typography>Water Source</Typography>
                      <Typography className="medium bold" style={{paddingLeft: '0.5rem', width: '20rem'}}>{propertyDetails.summary.WaterSource.publicRecordValue}</Typography>
                    </FlexWrapper>
                  }
                  {(propertyDetails.summary.SewerType.publicRecordValue) &&
                    <FlexWrapper className="justifyCenter alignCenter">
                      <Typography>Sewer Type</Typography>
                      <Typography className="medium bold" style={{paddingLeft: '0.5rem', width: '20rem'}}>{propertyDetails.summary.SewerType.publicRecordValue}</Typography>
                    </FlexWrapper>
                  }
                </FlexWrapper>

                <FlexWrapper className="column fitContentWidth" style={{margin: '1.5rem auto 0 auto'}}>
                  <FlexWrapper className="alignCenter" style={{height: "2.5rem"}}>
                    <Typography style={{marginRight: '1rem'}}>Construction Type</Typography>
                    {propertyDetails.building.ConstructionType.publicRecordValue ?
                      <Typography className="medium bold">
                        {propertyDetails.building.ConstructionType.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="alignCenter" style={{height: "2.5rem"}}>
                    <Typography style={{marginRight: '1rem'}}>Frame Type</Typography>
                    {propertyDetails.building.FrameType.publicRecordValue ?
                      <Typography className="medium bold">
                        {propertyDetails.building.FrameType.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="alignCenter" style={{height: "2.5rem"}}>
                    <Typography style={{marginRight: '1rem'}}>Exterior Walls</Typography>
                    {propertyDetails.building.ExteriorWallsType.publicRecordValue ?
                      <Typography className="medium bold">
                        {propertyDetails.building.ExteriorWallsType.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="alignCenter" style={{height: "2.5rem"}}>
                    <Typography style={{marginRight: '1rem'}}>Interior Walls</Typography>
                    {propertyDetails.building.InteriorWallsType.publicRecordValue ?
                      <Typography className="medium bold">
                        {propertyDetails.building.InteriorWallsType.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                  <FlexWrapper className="alignCenter" style={{height: "2.5rem"}}>
                    <Typography style={{marginRight: '1rem'}}>Roof Type</Typography>
                    {propertyDetails.building.RoofCoverType.length > 0 ?
                      <Typography className="medium bold">
                        {propertyDetails.building.RoofCoverType.publicRecordValue}
                      </Typography>
                    : propertyDetails.building.RoofType.length > 0 ?
                      <Typography className="medium bold">
                        {propertyDetails.building.RoofType.publicRecordValue}
                      </Typography>
                    :
                      <Typography className="italics bold alignCenter">-N/A-</Typography>
                    }
                  </FlexWrapper>
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>

            <FlexWrapper style={{marginTop: '4rem'}}>
              <BorderOuterIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.tertiary.primary}`}} />
              <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Lot:</Typography>
            </FlexWrapper>
            <Divider className="gray full" style={{margin: '0.5rem 0 1rem 0'}} />
            <FlexWrapper style={{marginBottom: '4rem'}}>
              <FlexWrapper className="column">
                <FlexWrapper className="column">
                  <Typography className="alignCenter" style={{marginRight: '1rem'}}>Legal Subdivision</Typography>
                  {propertyDetails.lot.LegalSubdivisionName.publicRecordValue ?
                    <Typography className="xLarge bold">
                      {propertyDetails.lot.LegalSubdivisionName.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics bold alignCenter">-N/A-</Typography>
                  }
                </FlexWrapper>
                <FlexWrapper className="column">
                  <Typography className="alignCenter" style={{marginRight: '1rem'}}>School District</Typography>
                  {propertyDetails.summary.SchoolDistrict.publicRecordValue ?
                    <Typography className="xLarge bold">
                      {propertyDetails.summary.SchoolDistrict.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics bold alignCenter">-N/A-</Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
              <FlexWrapper className="column">
                <FlexWrapper className="column" >
                  <Typography className="alignCenter" style={{marginRight: '1rem'}}>Tax Block</Typography>
                  {propertyDetails.lot.TaxBlock.publicRecordValue ?
                    <Typography className="xLarge bold">
                      {propertyDetails.lot.TaxBlock.publicRecordValue}
                    </Typography>
                  :
                    <Typography className="italics bold alignCenter">-N/A-</Typography>
                  }
                </FlexWrapper>
              </FlexWrapper>
            </FlexWrapper>

            {showAll &&
              <>
                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Address:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>StreetAddressLine2</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.StreetAddressLine2.publicRecordValue}
                      </DetailText>
                    </InputWrapper>  
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>StreetAddressOneLine</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.StreetAddressOneLine.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>PostalCodePlus4</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.PostalCodePlus4.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>StreetDirPrefix</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.StreetDirPrefix.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>StreetDirSuffix</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.StreetDirSuffix.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>StreetName</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.StreetName.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>StreetNumber</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.StreetNumber.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>StreetSuffix</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.StreetSuffix.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>UnitNumber</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.UnitNumber.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>StreetName</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.address.UnitPrefix.UnitPrefix}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Assessment:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>AssessorsMapReference</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.assessment.AssessorsMapReference.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Building:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Architectural Style</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.building.ArchitecturalStyle.publicRecordValue ?
                          `${propertyDetails.building.ArchitecturalStyle.publicRecordValue}`
                        :
                          <Typography className="italics bold alignCenter">-N/A-</Typography>
                        }
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Idnetifiers:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{gridColumn: '2', width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>AttomId</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.identifiers.AttomId.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{gridColumn: '3', width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>CoreLogicClip</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.identifiers.CoreLogicClip.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{gridColumn: '4', width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>FIPS</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.identifiers.FIPS.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{gridColumn: '1', width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>PMXPropertyId</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.identifiers.PMXPropertyId.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Living:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>BathroomsFull</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.living.BathroomsFull.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Lot:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Municipality</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.Municipality.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Tax Lot</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.TaxLot.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Land Use Code</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.LandUseCode.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>County Land Use Code</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.CountyLandUseCode.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Latitude</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.Latitude.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Longitude</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.Longitude.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Census Tract ID</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.CensusTractId.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Tax Code Area</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.TaxCodeArea.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>County Use Code</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.CountyUseCode.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Below Grade Total Area</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.BelowGradeTotalArea.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Below Grade Finished Area</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.lot.BelowGradeFinishedArea.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Owner2:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>Owner2FullName</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.owner2.Owner2FullName.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Primary Mortgage:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>PrimaryMortgageDueDate</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.primaryMortgage.PrimaryMortgageDueDate.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Sale:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>LastSaleTransactionId</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.sale.LastSaleTransactionId.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>LastSaleRecordingDocumentId</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.sale.LastSaleRecordingDocumentId.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>LastSaleType</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.sale.LastSaleType.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>ClosePriceDescription</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.sale.ClosePriceDescription.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>LastSaleContractDate</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.sale.LastSaleContractDate.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>LastSaleRecordingDate</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.sale.LastSaleRecordingDate.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>LastSalePrice</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.sale.LastSalePrice.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>

                <FlexWrapper>
                  <MiscellaneousServicesIcon style={{height: '3rem', width: '3rem', color: `${theme.icon.neutral.primary}`}} />
                  <Typography className="large" style={{minWidth: '11rem', marginLeft: '0.5rem'}}>Secondary Mortgage:</Typography>
                </FlexWrapper>
                <Divider className="gray full" />
                <ComponentSectionRow style={{margin: '0.5rem 0', padding: '0 2.5rem'}}>
                  <GridWrapper className="fourColumn">
                    <InputWrapper className="alignStart noGap" style={{width: 'fit-content'}}>
                      <StatusAndLabelWrapper>
                        <Label>SecondaryMortgageDueDate</Label>
                      </StatusAndLabelWrapper>
                      <DetailText>
                        {propertyDetails.secondaryMortgage.SecondaryMortgageDueDate.publicRecordValue}
                      </DetailText>
                    </InputWrapper>
                  </GridWrapper>
                </ComponentSectionRow>
              </>
            }
          </FlexWrapper>
        </FlexWrapper>
      </ScrollWindow>
    </CardContainer>
  )
}

export default SearchResults;