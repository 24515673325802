import React from "react";
import PendingIcon from '@mui/icons-material/Pending';
import { useTheme } from "@mui/styles";
import { Detail, DetailContainer, DetailInfoContainer, DetailListContainer, FlexWrapper } from "../../../StyledComponents";

const ContinueAwaitingUpdates = ({milestone}) => {
  const theme = useTheme()

  return (
    <DetailContainer className="fitContent">
      <Detail className="timelineHeader">{milestone.milestone}{milestone.notify ? <span style={{color: `${theme.palette.primary.main}`, margin: '0.25rem 0 0 0.5rem', fontWeight: '400'}}>new</span> : <></>}</Detail>
      <FlexWrapper>
        <PendingIcon style={{height: '3rem', width: '3rem', color: `${theme.palette.common.grayText}`, margin: '0 auto 0 2rem'}} />
        <DetailListContainer className='timeline'>
          <DetailInfoContainer className="item timeline">
            <Detail className="timelineDetail" style={{minWidth: '7rem'}}>initiated by:</Detail>
            <Detail className="timelineDetail" style={{minWidth: '7rem'}}>date:</Detail>
          </DetailInfoContainer>
          <DetailInfoContainer className="space">
            <Detail className="timelineItem">{milestone.contributor}</Detail>
            <Detail className="timelineItem">{milestone.date}</Detail>
          </DetailInfoContainer>
        </DetailListContainer>
      </FlexWrapper>
    </DetailContainer>
  )
}

export default ContinueAwaitingUpdates