import React, { useState } from "react";
import Badge from '@mui/material/Badge';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';
import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from "@mui/styles";
import SettingsIcon from '@mui/icons-material/Settings';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { useNavigate } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { selectFirstTeamReport } from "../../../../../slices/teamReportsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useRemoveReportNotifyUserMutation } from "../../../../../slices/api/apiSlice";
import { selectAppLoaded, selectNavSelection, setNavSelection } from "../../../../../slices/sessionDataSlice";
import { NavigationContainer, NavIndex, SideBarNavHeader, Typography, ListItemNav, SubListItemNav } from "../../../../../StyledComponents";
import { selectNewReportRemoved, selectUserNewInAppNotifications, selectUserNewLeadNotifications, selectUserNewReports, setNewReportRemoved } from "../../../../../slices/userNotificationsSlice";

const Navigation = ({ userId }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()  

  const appLoaded = useSelector(selectAppLoaded)
  const newReports = useSelector(selectUserNewReports)
  const firstReport = useSelector(selectFirstTeamReport)
  const navSelection = useSelector(selectNavSelection)
  const newReportRemoved = useSelector(selectNewReportRemoved)
  const leadNotifications = useSelector(selectUserNewLeadNotifications)
  const newInAppNotifications = useSelector(selectUserNewInAppNotifications)

  const [removeUserNotification] = useRemoveReportNotifyUserMutation() 
  
  const [lastOverview, setLastOverview] = useState('leadGeneration');
  const [reportsSelected, setReportsSelected] = useState(false);

  const changeSelection = (remember, content) => {
    if (remember) {
      if (content === 'refinancesOverview') {
        setLastOverview('refinance')
      } else if (content === 'renegotiationsOverview') {
        setLastOverview('renegotiation')
      } else if (content === 'leadGeneration') {
        setLastOverview('leadGeneration')
      }
    }
    navigate(`/dashboard/${content}`)
    dispatch( setNavSelection(content) )
    if (reportsSelected) {
      if (firstReport) {
        let notifyUser = firstReport.notifyUser
        if (notifyUser) {
          if (!newReportRemoved) {
            removeUserNotification({
              userId: userId,
              reportId: firstReport._id
            })
            dispatch( setNewReportRemoved(true) )
          }
        }
        setReportsSelected(false)
      }
    }
    if (content === 'reports') {
      setReportsSelected(true)
    }
  }

  const handleShowOverview = () => {
    if (lastOverview === 'refinance') {
      changeSelection(true, 'refinancesOverview')
    } else if (lastOverview === 'renegotiation') {
      changeSelection(true, 'renegotiationsOverview')
    } else {
      changeSelection(true, 'leadGeneration')
    }
  }

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: `${theme.palette.common.white}`,
      backgroundColor: `${theme.palette.common.error}`,
      fontWeight: '800',
      fontSize: "1rem",
      height: "1.4rem",
      padding: "0 0.5rem",
      borderRadius: "1rem",
      minWidth: "1.2rem"
    }
  }

  return (
    <NavigationContainer>
      <SideBarNavHeader>Navigation</SideBarNavHeader>
      <ListItemNav 
        className={navSelection === 'activeLeads' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'activeLeads' || !appLoaded) ? null : () => changeSelection(false, 'activeLeads')}
      >
        <NavIndex>
          {leadNotifications !== undefined ?
            leadNotifications > 0 ?
              <Badge badgeContent={leadNotifications} color="error" sx={badgeStyle}>
                <FlagCircleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
              </Badge>
            :
              <FlagCircleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
          :
            <FlagCircleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
          }
        </NavIndex>
        <Typography className="primary">Active Investigations</Typography>
      </ListItemNav>
      <ListItemNav 
        className={(navSelection === 'refinancesOverview' || navSelection === 'renegotiationsOverview' || navSelection === 'leadGeneration') ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'refinancesOverview' || navSelection === 'renegotiationsOverview' || navSelection === 'leadGeneration') ? null : () => handleShowOverview()}
      >
        <NavIndex>
          <QueryStatsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Team Performance</Typography>
      </ListItemNav>
      <SubListItemNav 
        className={navSelection === 'leadGeneration' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'leadGeneration' || !appLoaded) ? null : () => changeSelection(true, 'leadGeneration')}
      >
        <NavIndex className={navSelection === 'leadGeneration' ? 'active subNav' : 'subNav'}>
          i.
        </NavIndex>
        Lead Generation
      </SubListItemNav>
      <SubListItemNav 
        className={navSelection === 'renegotiationsOverview' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'renegotiationsOverview' || !appLoaded) ? null : () => changeSelection(true, 'renegotiationsOverview')}
      >
        <NavIndex className={navSelection === 'renegotiationsOverview' ? 'active subNav' : 'subNav'}>
          ii.
        </NavIndex>
        Renegotiations
      </SubListItemNav>
      <SubListItemNav 
        className={navSelection === 'refinancesOverview' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'refinancesOverview' || !appLoaded) ? null : () => changeSelection(true, 'refinancesOverview')}
      >
        <NavIndex className={navSelection === 'refinancesOverview' ? 'active subNav' : 'subNav'}>
          iii.
        </NavIndex>
        Refinances
      </SubListItemNav>
      <ListItemNav 
        className={navSelection === 'memberPerformance' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'memberPerformance' || !appLoaded) ? null : () => changeSelection(true, 'memberPerformance')}
      >
        <NavIndex>
          <GroupsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Member Performance</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'mortgageRepository' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'mortgageRepository' || !appLoaded) ? null : () => changeSelection(true, 'mortgageRepository')}
      >
        <NavIndex>
          <ListIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Mortgage Repository</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'configuration' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'configuration' || !appLoaded) ? null : () => changeSelection(true, 'configuration')}
      >
        <NavIndex>
          <BuildCircleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">System Configuration</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'propertySearch' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'propertySearch' || !appLoaded) ? null : () => changeSelection(true, 'propertySearch')}
      >
        <NavIndex>
          <SearchIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Property Search</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'reports' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'reports' || !appLoaded) ? null : () => changeSelection(true, 'reports')}
      >
        <NavIndex>
          <Badge badgeContent={newReports} color="error" sx={badgeStyle}>
            <ArticleIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
          </Badge>
        </NavIndex>
        <Typography className="primary">Closure Reports</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'notifications' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'notifications' || !appLoaded) ? null : () => changeSelection(true, 'notifications')}
      >
        <NavIndex>
          <Badge badgeContent={newInAppNotifications !== undefined ? newInAppNotifications : null} color="error" sx={badgeStyle}>
            <NotificationsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
          </Badge>
        </NavIndex>
        <Typography className="primary">Notifications</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'settings' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'settings' || !appLoaded) ? null : () => changeSelection(true, 'settings')}
      >
        <NavIndex>
          <SettingsIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Team Settings</Typography>
      </ListItemNav>
      <ListItemNav 
        className={navSelection === 'guide' ? 'mainActive' : 'mainInactive'}
        onClick={(navSelection === 'guide' || !appLoaded) ? null : () => changeSelection(true, 'guide')}
      >
        <NavIndex>
          <AutoStoriesIcon fontSize="medium" sx={{color: `${theme.palette.primary.main}`}} />
        </NavIndex>
        <Typography className="primary">Quick Guide</Typography>
      </ListItemNav>
    </NavigationContainer>
  )
}

export default Navigation